import { Picker } from 'emoji-mart-vue';

export default {
	name: 'EmojiPicker',
	components: { Picker },
	data() {
		return {};
	},
	methods: {
		addEmoji(data) {
			this.$emit('on-add-emoji', data.native);
		},
	},
};
