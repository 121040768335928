var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"coCreatePdf",attrs:{"id":"pdf-cocreate"}},[_c('div',{staticClass:"coCreatePdf__wrapper"},[_c('div',{staticClass:"coCreatePdf__container"},[_c('div',{staticClass:"coCreatePdf__header"},[_c('div',{staticClass:"coCreatePdf__title"},[_c('p',{staticClass:"coCreatePdf__mainTitle"},[_vm._v(_vm._s(_vm.file_name))]),_c('p',{staticClass:"coCreatePdf__saveTime"},[_vm._v(_vm._s(_vm.day))])]),_c('hr',{staticClass:"coCreatePdf__hr"}),_vm._m(0)]),_c('div',{staticClass:"coCreatePdf__body"},[_c('div',{staticClass:"coCreatePdf__contentBlock"},[_c('div',{staticClass:"coCreatePdf__contentContainer"},[_c('div',{staticClass:"coCreatePdf__content-item"},[_c('div',{staticClass:"coCreatePdf__content-itemTitle"},[_vm._v("制作物")]),_c('div',{staticClass:"coCreatePdf__content-itemTitle"},[_vm._v(" "+_vm._s(_vm.content.productKeyword)+" ")])]),_c('div',{staticClass:"coCreatePdf__content-item"},[_c('div',{staticClass:"coCreatePdf__content-itemTitle"},[_vm._v("業種")]),_c('div',{staticClass:"coCreatePdf__content-itemTitle"},[_vm._v(" "+_vm._s(_vm.content.jobKeyword)+" ")])])])]),_c('div',{staticClass:"coCreatePdf__contentBlock"},[_vm._m(1),_c('div',{staticClass:"coCreatePdf__keyWordList"},_vm._l((_vm.content.keyWordList),function(item){return _c('div',{key:item,staticClass:"coCreatePdf__keyWord"},[_vm._v(" "+_vm._s(item)+" ")])}),0)]),_c('div',{staticClass:"coCreatePdf__contentBlock"},[_vm._m(2),_c('div',{staticClass:"coCreatePdf__hearingContent",attrs:{"id":"hearing-content"}},[_vm._v(" "+_vm._s(_vm.content.coCreationContent)+" ")])]),_c('div',{staticClass:"coCreatePdf__contentBlock"},[_c('div',{staticClass:"coCreatePdf__imageList-icon"},[_vm._v("〇")]),_c('div',{staticClass:"coCreatePdf__imageList"},_vm._l((_vm.content.roundImages),function(item,index){return _c('div',{key:index,staticClass:"coCreatePdf__imageList-item"},[_c('div',{staticClass:"coCreatePdf__imageList-frame"},[_c('img',{class:{
										__empty: !item['url'],
									},attrs:{"src":item['url']}})]),_c('div',{staticClass:"coCreatePdf__imageList-comment"},[_vm._v(" "+_vm._s(item.comment)+" ")])])}),0)]),_c('div',{staticClass:"coCreatePdf__contentBlock"},[_c('div',{staticClass:"coCreatePdf__imageList-icon"},[_vm._v("✕")]),_c('div',{staticClass:"coCreatePdf__imageList"},_vm._l((_vm.content.crossImages),function(item,index){return _c('div',{key:index,staticClass:"coCreatePdf__imageList-item"},[_c('div',{staticClass:"coCreatePdf__imageList-frame"},[_c('img',{class:{
										__empty: !item['url'],
									},attrs:{"src":item['url']}})]),_c('div',{staticClass:"coCreatePdf__imageList-comment"},[_vm._v(" "+_vm._s(item.comment)+" ")])])}),0)])]),_vm._m(3)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"coCreatePdf__screenInfo"},[_c('div',{staticClass:"coCreatePdf__screenIcon"},[_c('img',{staticStyle:{"height":"15px","width":"15px"},attrs:{"src":require("./screen_icon.png")}})]),_c('div',{staticClass:"coCreatePdf__screenName"},[_vm._v("参考画像")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"coCreatePdf__content-item"},[_c('div',{staticClass:"coCreatePdf__content-itemTitle"},[_vm._v("キーワード")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"coCreatePdf__content-item"},[_c('div',{staticClass:"coCreatePdf__content-itemTitle"},[_vm._v("内容")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"coCreatePdf__appLogo"},[_c('img',{staticStyle:{"height":"8px","width":"45px","display":"none"},attrs:{"id":"comode-logo","src":require("./ComodeIcon.png")}})])
}]

export { render, staticRenderFns }