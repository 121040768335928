import SvgIcon from '@/components/SvgIcon/SvgIcon.vue';
import PreviewModal from '@/components/PreviewModal/PreviewModal.vue';
import EmojiPicker from '@/components/EmojiPicker/EmojiPicker.vue';
import ReactionPicker from '@/components/ReactionPicker/ReactionPicker.vue';

import TransferService from '../../services/API/transfer.service';
import MessageService from '@/services/API/message_service';

import { isImage, getIcon, downloadFile } from '../../store/modules/Image.js';
import { filterMessage } from '@/store/modules/FilterMessage.js';

import { Picker } from 'emoji-mart-vue';
import { mapState } from 'vuex';
import moment from 'moment';

const EXPIRATION = 31536000;
const INPUT_PLACE_HOLDER = 'ここにメッセージ内容を入力［Shift + Enterキーで送信］';
const INPUT_PLACE_HOLDER_MOBILE = 'ここにメッセージ内容を入力';
var MAX_FILES = 10;

export default {
	name: 'MessageInput',
	components: { SvgIcon, EmojiPicker, PreviewModal, Picker, ReactionPicker },
	props: {
		quoteMessage: Object,
		editMessage: Object,
		mode: String,
		mobileView: Boolean,
	},
	data: () => {
		return {
			inputContent: '',
			files: [],
			isEmojiPickerShow: false,
			isUserEnterToSendMessage: false,
			selectiveModalId: 'preview-selective-modal',
			selectiveContents: 'Are you sure to delete message ?',
			isSaveButtonClicked: false,
			previewItems: [],
			selectedItem: {},
			isQuotePreviewShow: false,
			editedMessage: '',
			inputMode: '',
			EDIT_MODE: 'edit',
			NORMAL_MODE: 'normal',
			isEmojiFileShow: false,
			isLoaded: true,
			inputPlaceholder: '',
		};
	},
	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'creatorAvatar',
			'clientAvatar',
		]),
	},
	watch: {
		editMessage: function (editMessage) {
			this.editedMessage = editMessage.message;
		},
		mode: function (mode) {
			this.inputMode = mode;
		},
		files: {
			handler(files) {
				this.isLoaded = files.every((file) => file.isLoaded);
			},
			deep: true,
		},
		// isEmojiFileShow(isEmojiFileShow) {
		// 	if (isEmojiFileShow) {
		// 		window.addEventListener('mouseup', this.handlerMouseUp);
		// 	} else {
		// 		window.removeEventListener('mouseup', this.handlerMouseUp);
		// 	}
		// },
	},
	filters: {
		shortFileName: function (value) {
			let arr = value.split('.');
			let fileExt = arr.pop();

			let fileName = arr.join('.');

			if (fileName.length > 10) {
				return fileName.slice(0, 8) + '…' + '.' + fileExt;
			}
			return value;
		},
	},
	methods: {
		filterMessage,
		getAllMessages: async function () {
			return await MessageService.getAll(this.projectId).then((res) => res.data);
		},
		async onDownload() {
			this.$store.commit('setIsAppProcessing', true);

			let messages = await this.getAllMessages();
			console.log(messages);
			let mapWithUserInfo = this.toMapWithUserId(messages);
			let groupMessagesByDay = this.toGroupMessagesByDay(mapWithUserInfo);
			let template = this.toMessagesTemplate(groupMessagesByDay);
			// console.log({
			// 	// mapWithUserInfo,
			// 	groupMessagesByDay,
			// 	template,
			// });
			this.toFile(template);

			this.$store.commit('setIsAppProcessing', false);
		},
		toFile(content) {
			let file = new Blob([content], { type: 'data:text/plain;charset=utf-8' });
			let url = URL.createObjectURL(file);
			let a = document.createElement('a');
			a.href = url;
			a.download = `チャット記録_${moment(new Date()).format('YYYYMMDD_hhmmss')}.txt`;
			document.body.appendChild(a);
			a.click();
			setTimeout(function () {
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			}, 0);
		},
		/* eslint-disable no-mixed-spaces-and-tabs */
		toMessagesTemplate: function (messages) {
			return Object.keys(messages)
				.map((_key) => {
					return [
						moment(_key).format('YYYY/MM/DD') + '\n',
						...messages[_key].map((message) => {
							return `${moment(message.date_created).format('A hh:mm:ss')} ${
								message.author + (message.quote ? 'さんが引用：\n' : 'さん:')
							}${
								message.quote
									? ' 引用内容: ' +
									  (message.quote.message ||
											(message.quote.attachments &&
												message.quote.attachments
													.map((attachment) => attachment.file_name)
													.join(' '))) +
									  '\n' +
									  'メッセージ内容:'
									: ''
							} ${
								message.message ||
								(message.attachments &&
									message.attachments
										.map((attachment) => attachment.file_name)
										.join(' '))
							}\n`;
						}),
						''.padEnd(40, '-') + '\n',
					];
				})
				.flatMap((_) => _)
				.join('');
		},
		/* eslint-disable no-mixed-spaces-and-tabs */
		toGroupMessagesByDay: function (messages) {
			return messages.reduce((acc, message) => {
				let date = new Date(message.date_created);
				let dateString = date.toLocaleDateString();
				if (!acc[dateString]) {
					acc[dateString] = [];
				}
				acc[dateString].push(message);

				acc[dateString].sort((a, b) => a.date_created - b.date_created);
				return acc;
			}, {});
		},
		toMapWithUserId: function (messages) {
			return messages.map((message) => {
				return {
					...message,
					author:
						message.user_id === this.clientInfo['id']
							? this.clientInfo['client_name']
							: this.creatorInfo['register_name'],
				};
			});
		},
		onEmojiPicker() {
			console.log('emoji click');
			this.isEmojiPickerShow = !this.isEmojiPickerShow;
		},

		addEmoji(data) {
			if (this.inputMode == this.EDIT_MODE) {
				this.editedMessage += data;
				return;
			}
			this.inputContent += data;
		},
		showModalPreview: function (selectedItem) {
			this.selectedItem = selectedItem;
			console.log({ selectedItem: this.selectedItem });
			this.$bvModal.show(`${this.selectiveModalId}`);
		},
		onSelectedItemChange: function (newValue) {
			this.selectedItem = newValue;
		},
		hideEmojiPicker() {
			console.log('emoji hide');

			if (this.isEmojiPickerShow === true) {
				this.isEmojiPickerShow = false;
			}
		},

		onShowQuote() {
			console.log('show quote');
			this.isQuotePreviewShow = true;
		},

		onCloseQuote() {
			console.log('close quote');
			this.isQuotePreviewShow = false;
		},

		sendMsg: function (e, isSendClick) {
			if (!this.isLoaded) {
				console.log('file loading');
				return;
			}
			if (this.isUserEnterToSendMessage || isSendClick) {
				e.preventDefault();
				let listFile = this.files.map((file) => {
					return {
						key: file.key,
						file_name: file.file_name,
						file_type: file.type,
						data: file.data,
					};
				});

				if (!this.inputContent.trim().length && listFile.length < 1) return;

				let addOnData = {
					active: false,
					optionOpen: false,
					reactionOpen: false,
					reactions: [],
					type: 'send',
				};
				let newMsg = {
					user_id: this.userId,
					project_id: this.projectId,
					message: this.inputContent.trim(),
					attachments: listFile,
					...addOnData,
					date_created: new Date(),
				};

				if (this.isQuotePreviewShow) {
					newMsg.quote = {
						id: this.quoteMessage.id,
						message: this.quoteMessage.message.trim(),
						user_id: this.quoteMessage.user_id,
						attachments: this.quoteMessage.attachments,
						date_created: this.quoteMessage.date_created,
						date_modified: this.quoteMessage.date_modified,
					};
				}

				this.inputContent = '';
				this.files = [];
				console.log({ newMsg });
				this.$emit('send-message', newMsg);
				// Hide quote preview in input message
				this.isQuotePreviewShow = false;
			}
			this.removeInputValue();
		},

		uploadFile: async function (event) {
			console.log(
				'uploadFile',
				{ 'event.target.files': event.target.files },
				{ files: this.files }
			);

			// map to array
			var uploadFiles = [...event.target.files].slice(0, MAX_FILES);
			uploadFiles.forEach((file, index) => {
				let now = Date.now();
				let newFile = {
					id: now + index,
					isLoaded: false,
					url: '',
					file_name: file.name,
					key: '',
					showButton: false,
					type: file.type,
					isImage: this.isImage(file.type),
					icon: this.getIcon(file.type, file.name),
				};

				if (this.files.length < MAX_FILES) {
					this.files.push(newFile);
					console.log({ newFile });
					console.log({ files: this.files });
					if (newFile.isImage) {
						this.previewItems.push(newFile);
					}
					console.log({ fileslength: this.files.length });
					var reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = async () => {
						var key = await TransferService.postImg({
							content: reader.result,
							file_name: file.name,
							project_id: this.projectId,
							user_id: this.userId,
						})
							.then((result) => {
								return result.data.key;
							})
							.catch(console.log);

						var linkPreview = await TransferService.get(key, EXPIRATION)
							.then((result) => {
								return result.data.link;
							})
							.catch(console.log);
						console.log('linkPreview', linkPreview);

						Object.assign(newFile, {
							data: URL.createObjectURL(file),
							isLoaded: true,
							file_name: file.name,
							showButton: false,
							type: file.type,
							isImage: this.isImage(file.type),
							icon: this.getIcon(file.type, file.name),
							url: linkPreview,
							key: key,
						});
					};
				} else {
					console.log('max files');
					// TransferService.delete(newFile.key).then(console.log).catch(console.log);
				}
			});

			this.isEmojiFileShow = false;
			console.log({ files: this.files }, { previewItems: this.previewItems });
		},
		dragFiles: async function (event) {
			console.log(
				'dragFiles',
				{ 'event.dataTransfer.files': event.dataTransfer.files },
				{ files: this.files }
			);

			// map to array
			var uploadFiles = [...event.dataTransfer.files].slice(0, MAX_FILES);
			uploadFiles.forEach((file, index) => {
				console.log(file);
				let now = Date.now();
				let newFile = {
					id: now + index,
					isLoaded: false,
					url: '',
					file_name: file.name,
					key: '',
					showButton: false,
					type: file.type,
					isImage: this.isImage(file.type),
					icon: this.getIcon(file.type, file.name),
				};

				if (this.files.length < MAX_FILES) {
					this.files.push(newFile);
					console.log({ newFile });
					console.log({ files: this.files });
					if (newFile.isImage) {
						this.previewItems.push(newFile);
					}
					console.log({ fileslength: this.files.length });
					var reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = async () => {
						var key = await TransferService.postImg({
							content: reader.result,
							file_name: file.name,
							project_id: this.projectId,
							user_id: this.userId,
						})
							.then((result) => {
								return result.data.key;
							})
							.catch(console.log);

						var linkPreview = await TransferService.get(key, EXPIRATION)
							.then((result) => {
								return result.data.link;
							})
							.catch(console.log);
						console.log('linkPreview', linkPreview);

						Object.assign(newFile, {
							data: URL.createObjectURL(file),
							isLoaded: true,
							file_name: file.name,
							showButton: false,
							type: file.type,
							isImage: this.isImage(file.type),
							icon: this.getIcon(file.type, file.name),
							url: linkPreview,
							key: key,
						});
					};
				} else {
					console.log('max files');
					// TransferService.delete(newFile.key).then(console.log).catch(console.log);
				}
			});

			this.isEmojiFileShow = false;
			console.log({ files: this.files }, { previewItems: this.previewItems });
		},

		removeFile: function (key, isLoaded) {
			if (!isLoaded) {
				return;
			}
			TransferService.delete(key).then(console.log).catch(console.log);
			this.files = this.files.filter((file) => file.key != key);
			this.previewItems = this.previewItems.filter((file) => file.key != key);
			console.log('remove file', this.files);
			this.removeInputValue();
		},

		onCheckUseEnter: function () {
			this.isUserEnterToSendMessage = !this.isUserEnterToSendMessage;
		},

		onEditedMessage: function (e, isSendClick) {
			if (this.isUserEnterToSendMessage || isSendClick) {
				e.preventDefault();
				if (!this.editedMessage.trim().length) return;
				let editedMessage = {
					...this.editMessage,
					message: this.editedMessage.trim(),
				};
				this.$emit('edit-message-done', editedMessage, this.NORMAL_MODE);
			}
		},
		onCancelEdit: function () {
			this.$emit('edit-message-cancel', this.NORMAL_MODE);
		},
		onLoadImageError: function (file) {
			file.data = file.url;
			setTimeout(() => {
				file.id += 0.0001;
			}, 100);
		},
		downloadFile,
		isImage,
		getIcon,
		handlerMouseUp({ target }) {
			let container = document.querySelector('.mediaContainer__wrapper');
			let addOptions = document.querySelector('.mediaContainer__showBtn');
			if (!container.contains(target) && !addOptions.contains(target)) {
				this.isEmojiFileShow = false;
			}
		},

		removeInputValue() {
			let inputFile = document.getElementById('uploadfile');
			console.log({ inputValue: inputFile.value });
			inputFile.value = '';
		},
	},

	created() {
		this.inputPlaceholder = this.mobileView
			? INPUT_PLACE_HOLDER_MOBILE
			: INPUT_PLACE_HOLDER;
	},
};
