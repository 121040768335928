// External packages
// Reference https://tiptap.dev/
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Placeholder from '@tiptap/extension-placeholder';
import VSwatches from 'vue-swatches';
import { FontSize } from 'tiptap-extension-font-size';
import 'vue-swatches/dist/vue-swatches.css';
import MemoPdfExporter from '../MemoPdfExporter/MemoPdfExporter.vue';

// Internal packages
import { isImage } from '@/store/modules/Image';
import { mapState } from 'vuex';
import TransferService from '@/services/API/transfer.service';
import AddOnService from '@/services/API/add_on.service';

import SaveButtonArea from '../SaveButtonArea/SaveButtonArea.vue';

// Declare const
const IMAGE_SELECTED = 0;
const HEADING_OPTIONS = [
	{ text: 'Normal', value: 0 },
	{ text: 'Heading 1', value: 1 },
	{ text: 'Heading 2', value: 2 },
	{ text: 'Heading 3', value: 3 },
	{ text: 'Heading 4', value: 4 },
	{ text: 'Heading 5', value: 5 },
	{ text: 'Heading 6', value: 6 },
];
const FONT_SIZE_OPTIONS = [8, 10, 12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72];
const EXTENSIONS = [
	StarterKit,
	Underline,
	FontSize,
	TextStyle,
	Color,
	Link,
	TextAlign.configure({
		types: ['heading', 'paragraph'],
	}),
	Highlight.configure({ multicolor: true }),
	Image.configure({
		allowBase64: true,
		inline: true,
		HTMLAttributes: {
			class: 'text-editor-image',
		},
	}),
	Placeholder.configure({
		placeholder: '入力した文字はここからスタートします。',
	}),
];

const TOOL_TIP = {
	bold: 'Bold',
	italic: 'Italic',
	underline: 'Underline',
	strikethrough: 'Strikethrough',
	alignLeft: 'Align Left',
	alignCenter: 'Align Center',
	alignRight: 'Align Right',
	alignJustify: 'Align Justify',
	orderList: 'Order List',
	bulletList: 'Bullet List',
	textColor: 'Text Color',
	textFillColor: 'Text Fill Color',
	link: 'Link',
	image: 'Image',
	save: 'Save',
	showToolBar: 'Show ToolBar',
	close: 'Close',
	expand: 'Expand',
	minimize: 'Minimize',
};

export default {
	name: 'TextEditor',
	components: {
		EditorContent,
		VSwatches,
		MemoPdfExporter,
		SaveButtonArea,
	},
	props: {
		initContent: {
			type: String,
		},
		isMinimunMode: {
			type: Boolean,
		},
	},

	data() {
		return {
			/**
			 * @type {Object}
			 */
			editor: null,
			tooltip: TOOL_TIP,

			/**
			 * @type {Array}
			 */
			headingOptions: HEADING_OPTIONS,
			fontSizeOptions: FONT_SIZE_OPTIONS,
			/**
			 * @type {String}
			 */
			textColor: '',
			highLightColor: '',
			localContent: '',
			file_name: '',

			showToolBar: true,
			showFontSizeList: false,
			showHeadingList: false,
			headingLevel: 0,
			// /** Check the first time focus to end of the editor*/
			// editorContentFocusTimes: 0,
			// handle time render memo content
			isShowContent: false,
		};
	},
	computed: {
		// Get projectId, from store
		...mapState(['projectId', 'userId']),
	},
	watch: {
		initContent(newContent) {
			this.editor.commands.setContent(newContent);
			this.localContent = newContent;
		},

		textColor(newTextColor) {
			this.editor.chain().focus(false).setColor(newTextColor).run();
		},

		highLightColor(newHighLightColor) {
			this.editor.chain().focus(false).setHighlight({ color: newHighLightColor }).run();
		},

		isMinimunMode(newVal) {
			if (newVal === true) {
				this.showToolBar = false;
			}
		},
	},

	methods: {
		minimumButtonClick() {
			this.$emit('on-minimun-click');
		},

		onCloseButonClick() {
			console.log('onCloseButonClick');
			this.$emit('on-close-button-click');
		},

		onExpandButtonClick() {
			this.$emit('on-expand-click');
		},

		changeHeader(level) {
			this.showHeadingList = false;
			this.headingLevel = level;
			this.editor.chain().focus(false).unsetFontSize().run();
			this.editor.chain().focus(false).toggleHeading({ level: level }).run();
		},
		removeHeader() {
			if(this.headingLevel == 0) return
			this.editor.chain().focus(false).unsetFontSize().run();
			this.editor.chain().focus(false).toggleHeading({ level: this.headingLevel }).run();
		},
		changeFontSize(fontSize) {
			console.log('changeFontSize', fontSize);
			this.showFontSizeList = false;
			this.editor
				.chain()
				.focus(false)
				.setFontSize(fontSize + 'pt')
				.run();
		},
		toggleToolBar() {
			this.showToolBar = !this.showToolBar;

			if (this.showToolBar) {
				this.setToolBarPosition();
			}
		},
		async onContentFocus(event) {
			if(event.target.className)
				this.editor.commands.focus('end');
		},

		onTogglelink() {
			let previousUrl = this.editor.getAttributes('link').href;
			if (!previousUrl) {
				let url = window.prompt('URL', previousUrl);

				// cancelled
				if (!url) {
					return;
				}
				// toggle link
				this.editor
					.chain()
					.focus(false)
					.toggleLink({ href: url, target: '_blank' })
					.run();
			}
			// toggle link
			else
				this.editor
					.chain()
					.focus(false)
					.unsetLink()
					.run();
		},

		addImage() {
			this.$refs.addImageInput.click();
		},

		handleImage(event) {
			let files = event.target.files;
			if (files.length === 0) {
				return;
			}

			let reader = new FileReader();
			reader.onload = (file) => {
				let type = files[IMAGE_SELECTED].type;
				if (!isImage(type)) {
					return;
				}

				let image = file.target.result;

				if (image) {
					this.editor.chain().focus(false).setImage({ src: image }).run();
				}
				this.$refs.addImageInput.value = '';
			};
			reader.readAsDataURL(files[IMAGE_SELECTED]);
		},

		setToolBarPosition() {
			const el = document.getElementsByClassName('editor-toolbar__minimum-mode')[0];

			const spaceSize = 3;

			let elHeight = el.offsetHeight;
			let elTop = -(elHeight + spaceSize);

			el.style.top = elTop + 'px';
			el.style.width = '100%';
		},

		selectImage() {
			let imageElements = document.querySelectorAll('.text-editor-image');
			// console.log({ imageElements });
			if (imageElements.length > 0) {
				imageElements.forEach((image) => console.log(image.src));
			}
		},
		onSaveButtonClick() {
			this.$emit('on-save-button-click');
		},
		/**
		 * Render Estimate Template displayed in EstimateScreen
		 */
		async savePdf() {
			console.log('On render Memo');
			let files = await AddOnService.get(this.projectId);
			let memo_files = files.data.filter((file) => file.screen_name === 'memo'
			&& file.user_id == this.userId);
			let index = memo_files.length + 1;
			this.file_name = '名称未設定 ' + index;
			let pdf = await this.$refs.pdfSave.getRenderedPDFTemplate();
			let pdfFileParams = {
				content: pdf,
				project_id: this.projectId,
				file_name: this.file_name + "_" + this.userId + '.pdf',
			};
			let pdfFileKey = await this._uploadPdf(pdfFileParams);
			this.$emit('on-transfer-uploaded-file', { pdfFileKey }, this.file_name + '.pdf');
		},

		async _uploadPdf(params) {
			try {
				let response = await TransferService.postPdf(params);
				if (!response || response.status !== 200) {
					throw 'Upload PDF failed!';
				}

				console.log('%c Upload PDF successfully!', 'color: red');
				let key = response.data.key;
				return key;
			} catch (error) {
				console.log(error);
			}
		},
	},

	created() {
		if (this.isMinimunMode) {
			this.showToolBar = false;
		}

		this.editor = new Editor({
			extensions: EXTENSIONS,
			content: '',
			onUpdate: () => {
				let newContent = this.editor.getHTML();
				this.localContent = newContent;
				this.$emit('on-update-memo', newContent);
			},
			autofocus: true,
			editable: true,
		});
	},

	mounted() {
		setTimeout(() => {
			this.isShowContent = true;
		}, 100);
	},

	beforeUnmount() {
		this.editor.destroy();
	},

	updated() {},
};
