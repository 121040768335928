var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"component menu"},_vm._l((_vm.toolNames),function(toolName,index){return _c('div',{key:index},[_c('div',{staticClass:"menu-item",class:{
				selected: _vm.toolSelected === toolName,
				dropdown_tool: toolName === _vm.tools.Stroke || toolName === _vm.tools.Select,
				Factor: index === 16,
			},style:({
				'display': _vm.isMobile && (toolName === _vm.tools.ShowFactor
				|| toolName === _vm.tools.ZoomIn || toolName === _vm.tools.ZoomOut) ? 'none' : ''
			}),on:{"click":function($event){return _vm.clickedTool(toolName)}}},[_c('div',{staticClass:"menu-icon",class:[
					toolName,
					{
						selected: _vm.toolSelected === toolName,
					},
				]},[(toolName === _vm.tools.StrokeColor)?_c('div',{staticClass:"color-picker stroke"}):_vm._e(),(toolName === _vm.tools.FillColor)?_c('div',{staticClass:"color-picker fill"}):_vm._e(),(toolName === _vm.tools.Select)?_c('div',[_c('img',{staticClass:"stroke-line",attrs:{"src":require("../../../assets/WhiteBoard/select.svg")}})]):_vm._e(),(toolName === _vm.tools.Stroke)?_c('div',[_c('img',{staticClass:"stroke-line",attrs:{"src":require("../../../assets/WhiteBoard/stroke-line.svg")}})]):_vm._e(),(toolName === _vm.tools.Stroke || toolName === _vm.tools.Select)?_c('div',[_c('img',{class:{
							pointer_active: _vm.toolSelected === toolName,
							pointer: _vm.toolSelected !== toolName,
						},attrs:{"src":require("../../../assets/WhiteBoard/pointer.svg")}})]):_vm._e(),(toolName === _vm.tools.ShowFactor)?_c('div',{staticClass:"ShowFactor"},[_vm._v(" "+_vm._s(_vm.displayFactor)+" ")]):_vm._e(),(toolName === _vm.tools.Image)?_c('div',[_c('input',{staticStyle:{"display":"none"},attrs:{"type":"file","id":"imgLoader"}})]):_vm._e()]),_c('div',{key:index,staticClass:"submenu",class:[
					toolName,
					{
						open:
							_vm.selectorOpen &&
							_vm.toolSelected === toolName &&
							!(toolName === _vm.tools.Math && _vm.isMobile),
					},
				],on:{"click":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)}}},[(_vm.toolsWithDropdown.includes(toolName))?_c('div',{staticClass:"submenu-content",class:[
						toolName,
						{
							open: _vm.selectorOpen && _vm.toolSelected === toolName,
						},
					]},[(toolName === _vm.tools.StrokeColor || toolName === _vm.tools.FillColor)?[_c('color-picker',{attrs:{"value":_vm.colors},on:{"input":_vm.updateColor}})]:(toolName === _vm.tools.Stroke)?_vm._l((_vm.strokeWidths),function(size,index){return _c('div',{key:index},[_c('div',{staticClass:"menu-item",on:{"click":function($event){return _vm.changeStrokeWidth(size)}}},[_c('div',{staticClass:"stroke-line-select",style:(`height: ${size * 1.2}px;`)})])])}):(toolName === _vm.tools.Select)?_vm._l((_vm.clipToolNames),function(clipToolName,index){return [_c('div',{key:index,staticClass:"menu-item"},[_c('div',{staticClass:"menu-icon",class:clipToolName,on:{"click":function($event){return _vm.clickedClipTool(clipToolName)}}})])]}):(toolName === _vm.tools.Math && !_vm.isMobile)?[_c('a',{staticClass:"menu-text math-instructions",attrs:{"href":"https://mathlive.io/reference_shortcuts.html","target":"_blank"}},[_vm._v(" How to use math typing ")])]:(toolName === _vm.tools.Polygon)?[_c('p',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.isMobile ? 'タップしてポイントを追加し、長押しして終了します' : 'クリックしてポイントを追加し、ダブルクリックして終了します')+" ")])]:_vm._e()],2):_vm._e()])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }