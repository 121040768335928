import ReactionPicker from '@/components/ReactionPicker/ReactionPicker.vue';
import Reactions from '@/components/Reactions/Reactions.vue';
import Modal from '../Modal/Modal.vue';
import ComfirmModal from '../ComfirmModal/ComfirmModal.vue';
import PreviewModal from '@/components/PreviewModal/PreviewModal.vue';
import SkeletonLoadingImage from '@/components/SkeletonLoadingImage/SkeletonLoadingImage.vue';

import { isImage, getIcon, downloadFile } from '../../store/modules/Image.js';
import { truncateLongText } from '../../store/modules/Text.js';
import { filterMessage } from '../../store/modules/FilterMessage.js';

import moment from 'moment';
import { mapState } from 'vuex';

const FIST_ITEM_INDEX = 0;

// Max distance from top of element to chat box edge that reactionsPicker, more-option-area are not overflow
const MAX_DISTANCE_TO_EDGE = 10;

export default {
	name: 'Message',
	components: {
		ReactionPicker,
		Reactions,
		Modal,
		ComfirmModal,
		PreviewModal,
		SkeletonLoadingImage,
	},
	props: {
		messages: Array,
		editMessage: Object,
		isEditMessageDone: Boolean,
		mobileView: Boolean,
	},

	data() {
		return {
			comfirmModalId: 'message-delete-selective-modal',
			previewModalId: 'message-preview-modal',
			selectiveContents: '',
			selectiveTitle: 'メッセージを削除します\n削除後は元に戻すことができません',
			deletedMessageText: 'がメッセージの送信を取り消しました。',
			isSaveButtonClicked: false,
			selectedMessageId: '',
			selectedMessage: {},
			showByKey: '',
			selectedItem: {},
			previewItems: [],
			displayedMessages: [],
			selectedMessageUserId: undefined,
		};
	},

	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'creatorAvatar',
			'clientAvatar',
		]),
	},

	watch: {
		messages(messages) {
			if (messages.length === 0) {
				this.displayedMessages = [];
				return;
			}

			// Set target scroll position
			messages[FIST_ITEM_INDEX].isTargetScrollMessage = true;
			this.$set(messages[messages.length - 1], 'baseIdx', messages.length - 1);
			// messages[FIST_ITEM_INDEX].showMessageDate = true;

			this.filterLongMessage(messages);

			this.splitMessageByDate(messages);
		},

		isEditMessageDone(done) {
			if (done) {
				this.selectedMessageId = '';
				this.selectedMessage = {};
			}
		},
	},
	filters: {
		messageTime: function (value) {
			if (value) {
				return moment(value).format('HH:mm');
			}
		},
		messageDate: function (value) {
			const daysOfWeekJP = ['日', '月', '火', '水', '木', '金', '土'];

			if (value) {
				return (
					moment(value).format('MM/DD') +
					' ' +
					'(' +
					daysOfWeekJP[moment(value).day()] +
					')'
				);
			}
		},
		shortFileName: function (value) {
			let arr = value.split('.');
			let fileExt = arr.pop();

			let fileName = arr.join('.');

			if (fileName.length > 10) {
				return fileName.slice(0, 8) + '…' + '.' + fileExt;
			}
			return value;
		},

		shortQuoteFileName: function (value) {
			let arr = value.split('.');
			let fileExt = arr.pop();

			let fileName = arr.join('.');

			if (fileName.length > 15) {
				return fileName.slice(0, 13) + '…' + '.' + fileExt;
			}
			return value;
		},
		messageDateModified: function (value) {
			let dateModified = moment(value);

			if (dateModified.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
				return '(編集済み)' + ' ' + '今日' + ' ' + dateModified.format('HH:mm');
			}

			return '(編集済み)' + ' ' + dateModified.format('MM月DD日 HH:mm');
		},

		truncateLongMessage(message) {
			return truncateLongText(message, 1000);
		},
	},
	methods: {
		isImage,
		getIcon,
		downloadFile,
		truncateLongText,
		filterMessage,
		onConfirmDelete(isConfirmRequire) {
			if (isConfirmRequire) {
				this.$emit('delete-message', this.selectedMessage);
			}
		},

		splitMessageByDate(messages) {
			let displayedMessages = [];
			let beginIdx = 0;

			for (let i = 0; i < messages.length - 1; i++) {
				// Use baseIdx for work with item index of props messages
				// Avoid conflict old logic
				this.$set(messages[i], 'baseIdx', i);

				if (
					messages[i + 1].date_created &&
					this.$options.filters.messageDate(messages[i].date_created) !==
						this.$options.filters.messageDate(messages[i + 1].date_created)
				) {
					// Split message array to childrens with same date_created
					displayedMessages.push({
						date: messages[i]['date_created'],
						messageContent: messages.slice(beginIdx, i + 1),
					});

					beginIdx = i + 1;
				}
			}

			displayedMessages.push({
				date: messages[beginIdx]['date_created'],
				messageContent: messages.slice(beginIdx, messages.length),
			});

			this.displayedMessages = displayedMessages;
		},

		filterLongMessage(messages) {
			for (let element of messages) {
				this.$set(
					element,
					'isMessageOverLimit',
					element.message?.length > 1000 ? true : false
				);
				this.$set(element, 'isMessageExpand', false);

				this.$set(
					element,
					'isQuoteOverLimit',
					element.quote?.message?.length > 1000 ? true : false
				);
				this.$set(element, 'isQuoteExpand', false);
			}
		},

		onExpandMessage(baseIdx) {
			this.messages[baseIdx].isMessageExpand = !this.messages[baseIdx].isMessageExpand;
		},

		onExpandQuote(baseIdx) {
			this.messages[baseIdx].isQuoteExpand = !this.messages[baseIdx].isQuoteExpand;
		},

		scrollToElement(elementId, isExpanded) {
			console.log('scrollToElement');
			const messageBaseIdx = parseInt(elementId.split('-').pop());
			const isMessage = elementId.includes('message');
			const element = document.getElementById(elementId);
			const chatBoxElement = document.getElementById('chatBox');
			const isLastMessageCollapse =
				isMessage &&
				messageBaseIdx === this.messages[this.messages.length - 1]?.baseIdx;

			// Calculate blockPosition
			const elementPosision =
				element.getBoundingClientRect().top -
				chatBoxElement.getBoundingClientRect().top;
			const chatBoxHeight = chatBoxElement.clientHeight;
			let blockPosision;
			if (elementPosision > 0.7 * chatBoxHeight) {
				blockPosision = 'end';
			} else if (elementPosision < 0.3 * chatBoxHeight) {
				blockPosision = 'start';
			} else {
				blockPosision = 'center';
			}

			// Wait until DOM is full rendered
			this.$nextTick(() => {
				const chatBoxContainer = document.getElementById('chatBoxContainer');
				const overflowY = isExpanded ? 'unset' : 'hidden';

				chatBoxContainer.style.overflowY = overflowY;
				if (!isExpanded && !isLastMessageCollapse)
					element.scrollIntoView({ block: blockPosision });
			});
		},

		onDeleteMessage: function (message) {
			console.log('delete message', message);
			this.selectedMessage = message;
			this.selectedMessageId = message.id;
			this.selectiveContents = message.message;

			let attachmentsLength = message.attachments.length;
			if (attachmentsLength > 0) {
				this.selectiveContents = message.attachments.reduce(
					(accumulator, currentValue, idx) => {
						return idx < attachmentsLength - 1
							? accumulator + currentValue['file_name'] + ', '
							: accumulator + currentValue['file_name'];
					},
					''
				);
			}

			this.$bvModal.show(`${this.comfirmModalId}`);
		},

		onDecline() {
			this.selectedMessage = '';
			this.selectedMessageId = '';
		},

		onEdit: function (editMessage) {
			if (editMessage.attachments.length > 0) {
				console.log('attachments message');
				return;
			}
			if (editMessage.user_id != this.userId) {
				console.log('Cannot edit a message authored by another user');
				return;
			}
			this.selectedMessageId = editMessage.id;
			console.log('edit message', editMessage);
			this.$emit('edit-message', {
				...editMessage,
				optionOpen: false,
				active: false,
			});
		},
		onEmojiPick(emoji, idx) {
			console.log(emoji, idx);
			this.$emit('on-reactions-pick', { content: emoji, idx: idx });
		},
		onCancel: function (event, message) {
			event.target.innerText = message.message;
			console.log(message.message.toString());
		},
		onSelectedItemChange: function (newValue) {
			this.selectedItem = newValue;
		},

		onQuoteButton(message) {
			console.log('Quote button clicked');
			this.$emit('on-quote-button', message);
		},

		previewAttrachments: function (
			selectedAtrachment,
			attachments,
			selectedMessageUserId
		) {
			this.previewItems = this.getImageItem(attachments);
			this.selectedItem = selectedAtrachment;
			this.selectedMessageUserId = selectedMessageUserId;
			this.$bvModal.show(`${this.previewModalId}`);
		},
		getImageItem: function (array) {
			if (!array || !(array instanceof Array)) {
				return [];
			}
			return array.filter((item) => this.isImage(item.file_type) === true);
		},

		/**
		 * Revert element position when hiding
		 * @param {Integer} baseIdx index of element in messages array
		 * @param {String} refPrefix
		 */
		revertPosition(baseIdx, refPrefix) {
			console.log('On Reactoin picker leave', refPrefix);
			const messageRef = 'message' + baseIdx;
			const elementRef = refPrefix + baseIdx;

			let msgElement = this.$refs[messageRef][FIST_ITEM_INDEX];
			let element = this.$refs[elementRef][FIST_ITEM_INDEX];

			let elementTop;
			if (refPrefix === 'reactionPicker') {
				elementTop = msgElement.offsetHeight / 2 - 205;
			} else if (refPrefix === 'moreOption') {
				elementTop = msgElement.offsetHeight / 2 - 50;
			}
			element.style.top = `${elementTop}px`;
		},

		/**
		 * Handle reactionsPicker position when overflow
		 * @param {Integer} baseIdx index of element in messages array
		 * @param {String} refPrefix
		 * @returns
		 */
		handleElementPosition(baseIdx, refPrefix) {
			const messageRef = 'message' + baseIdx;
			const elementRef = refPrefix + baseIdx;

			let msgElement = this.$refs[messageRef][FIST_ITEM_INDEX];
			let element = this.$refs[elementRef][FIST_ITEM_INDEX];

			let elementRect = element.getBoundingClientRect();
			let chatboxRect = document.getElementById('chatBox').getBoundingClientRect();

			let distanceToTopEdge = elementRect.top - chatboxRect.top;
			let distanceToRightEdge = chatboxRect.right - elementRect.right;
			let distanceToLeftEdge = elementRect.left - chatboxRect.left;

			let elementTop;
			if (refPrefix === 'reactionPicker') {
				// computed reactionsPicker element top
				// See:  .reactionsPicker in Message.scss
				// value: `top: calc(50% - 205px);`
				elementTop = msgElement.offsetHeight / 2 - 205;
			} else if (refPrefix === 'moreOption') {
				// computed moreOptionPicker element top
				// See: .messageOptionsContainer__content in Message.scss
				// value: `top: calc(50% - 50px);`
				elementTop = msgElement.offsetHeight / 2 - 50;
			}

			if (distanceToTopEdge < 0) {
				let newReactionElementTop =
					elementTop - distanceToTopEdge + MAX_DISTANCE_TO_EDGE;
				// Change element position
				element.style.top = `${newReactionElementTop}px`;
			}

			if (distanceToRightEdge < 0) {
				element.style.left = `${distanceToRightEdge - MAX_DISTANCE_TO_EDGE}px`;
			}

			if (distanceToLeftEdge < 0) {
				element.style.right = `${distanceToLeftEdge - MAX_DISTANCE_TO_EDGE}px`;
			}
		},

		onLoadImageError: function (file) {
			file.data = file.url;
		},
		onAttachmentClick: function (message, file) {
			return this.isImage(file.file_type)
				? this.previewAttrachments(file, message.attachments, message.user_id)
				: this.downloadFile(file.url);
		},

		/**
		 *
		 * @param {Integer} userId userId of message
		 * @returns userName
		 */
		getUserName(userId) {
			if (this.creatorInfo?.id && this.clientInfo?.id) {
				return this.creatorInfo.id === userId
					? this.creatorInfo.register_name
					: this.clientInfo.client_name;
			}
		},
	},
	mounted() {
		let messageContainer = document.getElementsByClassName('messageContainer');
		console.log({ messageContainer });
	},
	created() {
		document.addEventListener('touchend', (event) => {
			if (!event.target.matches('.reactionsPicker')) {
				for (const element of this.messages) {
					if (element.reactionOpen) {
						setTimeout(() => {
							element.reactionOpen = false;
							element.optionOpen = false;
						}, 50);
					}
				}
			}

			if (!event.target.matches('.optionItem__edit')) {
				for (const element of this.messages) {
					if (element.optionOpen) {
						setTimeout(() => {
							element.optionOpen = false;
						}, 50);
					}
				}
			}
		});
	},
};
