import ApiService from './api.service';

const serviceURL = 'search_img';
const SearchImage = {
	/**
	 * Search images
	 * @param {Object} params image key word
	 * @returns image_url & image_id
	 *
	 */
	search(params) {
		return ApiService.query(`${serviceURL}/`, { params: params });
	},
};

export default SearchImage;
