

module.exports = {
  /**
   * 
   * @param {String} text text to truncate
   * @param {Integer} length max length of expected string
   * @returns {String} truncated string
   */
	truncateLongText(text, length) {
    if (text.length > length) {
      return text.substring(0, length)
    }
    else return text
    
  }
};
