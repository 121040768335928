import { getPieChart } from '../../store/modules/Image';
import CommonConst from '@/constants/CommonConst';
import RedirectModal from '@/components/RedirectModal/RedirectModal.vue';

import { mapState } from 'vuex';
export default {
	name: 'SideBarLeft',
	components: {
		RedirectModal,
	},
	props: {
		menuItems: {
			type: Array,
			required: true,
		},
		showNavBar: {
			type: Boolean,
		},
		settlementItem: {
			type: Object,
		},
		documentItem: {
			type: Object,
		},
		screenFinishedItem: {
			type: Object,
		},
		projectDeadline: {
			type: String,
		},
		status: {
			type: Number,
		},
		showQuestionNotification: {
			type: Boolean,
		},
		showCreativeNotification: {
			type: Boolean,
		},
		mobileView: {
			type: Boolean,
		},
	},
	data() {
		return {
			isHover: false,
			pieChart: '', // pieChart src
			showPieChart: false, // pieChart show or not
			// estimateScreenId: CommonConst.SCREEN_ID.ESTIMATE,
			decisionScreenId: CommonConst.SCREEN_ID.DECISION,
			// creativeScreenId: CommonConst.SCREEN_ID.CREATIVE,
			// addCostScreenId: CommonConst.SCREEN_ID.ADDCOST,
			nftScreenId: CommonConst.SCREEN_ID.NFT,
			redirectRoute: '',
			confirmModalId: 'confirm-modal-id',
		};
	},
	computed: {
		...mapState([
			'userId',
			'clientInfo',
			'creatorInfo',
			'projectInfo',
			'redDotIndexs',
			'clientMode',
		]),
		isSettlementDisabled() {
			if (this?.userId === this.creatorInfo?.id) {
				return !this.menuItems[this.nftScreenId].isConfirm;
			} else {
				return !this.projectInfo?.['creator_finished'];
			}
		},
	},
	watch: {
		status: {
			handler(newStatus) {
				if (!newStatus) return;

				let [showPieChart, pieChart] = getPieChart(newStatus);
				this.pieChart = pieChart;
				this.showPieChart = showPieChart;
			},
			immediate: true,
		},
	},
	methods: {
		getPieChart,
		onSelectScreen() {
			this.$emit('on-select-left-menu');
		},
		onChangeScreen(route) {
			let currRoute = this.$route.path.split('/').at(-1);
			if (currRoute === route) {
				this.$emit('on-select-left-menu');
				return;
			}
			// If settlement or download -> Redirect
			if (
				currRoute == 'download' ||
				!this.isSettlementDisabled ||
				this.$route.path.includes('clientApp')
			) {
				this.redirectToRoute(route);
				return;
			}

			// If old screen redirect to old screen -> Redirect
			if (CommonConst.SCREEN_ID[currRoute.toUpperCase()] != this.projectInfo.status) {
				this.redirectToRoute(route);
				return;
			}
			this.route = route;
			this.$bvModal.show(this.confirmModalId);
		},
		redirectToRoute(route) {
			this.$router.push(route);
			this.$emit('on-select-left-menu');
		},
		onConfirmChangeScreen() {
			this.$router.push(this.route);
			this.$emit('on-select-left-menu');
		},
	},
};
