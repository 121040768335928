import ApiService from './api.service';

const serviceURL = 'delivery';
const DeliveryService = {
	/**
	 * Acquire delivery by projectID
	 * @param {Integer} projectId
	 * @returns {Object} Schedule details
	 */
	get(projectId) {
		return ApiService.get(`${serviceURL}`, `${projectId}`);
	},

	/**
	 * Create new delivery record
	 * @param {Object} body {doc_content: {delivery Object}}
	 * @returns {Object} include success message and record ID
	 */
	post(body) {
		return ApiService.post(`${serviceURL}/`, body);
	},

	/**
	 * Update delivery content
	 * @param {Integer} deliveryId: delivery ID
	 * @param {Object} contents:
	 * @returns
	 */
	update(deliveryId, contents) {
		let body = {
			doc_content: contents,
		};
		console.log(body);
		return ApiService.put(`${serviceURL}/${deliveryId}`, body);
	},

	/**
	 * Update bulk delivery records
	 * @param {Array} body [deliverys Array]
	 * @returns {Object} Success message
	 */
	updateBulk(contents) {
		let body = {
			doc_content: contents,
		};
		return ApiService.put(`${serviceURL}/bulk`, body);
	},

	/**
	 * confirm delivery content
	 * @param {Integer} projectId: Project ID
	 * @returns
	 */
	confirm(projectId) {
		return ApiService.put(`${serviceURL}/${projectId}/confirm`);
	},

	/**
	 * Delete delivery record
	 * @returns {Object} Success message
	 */
	delete(deliveryId) {
		return ApiService.delete(`${serviceURL}/${deliveryId}`);
	},
};

export default DeliveryService;
