var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sidebarRight"},[_c('div',{staticClass:"sidebarRight__container"},[_c('div',{staticClass:"sidebarRight__head"},[_c('div',{staticClass:"avatar__container"},[_c('div',{staticClass:"avatar__figure"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.userImage != ''),expression:"userImage != ''"}],attrs:{"src":_vm.avatar}})]),_c('label',{staticClass:"avatar__name--top"},[_vm._v(_vm._s(_vm.userName)+" ")])])]),(
				!_vm.mobileAndTabletCheck &&
				!_vm.isChromium &&
				_vm.isShowShareScreen &&
				_vm.isShareScreenHiding
			)?_c('ShareControllerSafari',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mobileView),expression:"!mobileView"}],attrs:{"menuBarItems":_vm.shareScreenMenuBarItems},on:{"on-show-share-screen":_vm.onShowShareScreenController,"on-minimum-bar-item-click":_vm.onMinimumBarItemClickShareScreenController}}):_vm._e(),_c('div',{staticClass:"sidebarRight__body",class:{ 'sidebarRight__body-clientMode': _vm.clientMode }},[_c('div',{staticClass:"launchpad"},[_c('div',{staticClass:"launchpad__container"},[_c('div',{staticClass:"launchpad__list"},_vm._l((_vm.menuItems),function(item,index){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",value:({
								customClass: 'launchpad__toolTip',
								delay: { show: 500 },
								placement: _vm.mobileView ? 'top' : 'left',
							}),expression:"{\n\t\t\t\t\t\t\t\tcustomClass: 'launchpad__toolTip',\n\t\t\t\t\t\t\t\tdelay: { show: 500 },\n\t\t\t\t\t\t\t\tplacement: mobileView ? 'top' : 'left',\n\t\t\t\t\t\t\t}",modifiers:{"hover":true,"v-secondary":true}}],key:index,staticClass:"launchpad__listItem",attrs:{"title":item.tooltipText}},[_c('button',{staticClass:"launchpad__button",class:{
									active: _vm.activeItem == item.name,
									launchpad__disabled: item.disabled,
								},attrs:{"disabled":_vm.disabledButton(item.name, item.disabled)},on:{"click":function($event){return _vm.toggle(item.name)}}},[(index == 0)?_c('div',[(_vm.notifications)?_c('div',{staticClass:"notification"},[_c('span',[_vm._v(" "+_vm._s(_vm.notifications)+" ")])]):_vm._e()]):_vm._e(),_c('div',[_c('img',{staticClass:"launchpad__icon",class:{
											'--lgIcon':
												item.iconName == 'CoCreate.svg' ||
												item.iconName == 'DownloadActive.svg' ||
												item.iconName == 'Download.svg' ||
												item.iconName == 'ComodeAi.svg',
										},attrs:{"src":require(`../../assets/RightSideBarIcon/${item.iconName}`)}})])])])}),0)])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }