import CommonConst from '@/constants/CommonConst';

/**
 * detect and generate emoji and link
 *
 * @param {String} rawMessage message
 * @returns message after detect emoji and link
 */
export function filterMessage(rawMessage) {
	/**
	 * Generate html link
	 * @param {String} message
	 * @returns
	 */
	const generateHtmlLink = (message) => {
		const urlRegex =
			/(https|http)?:\/\/([a-zA-Z0-9.-]+(?:\/[a-zA-Z0-9.%:_()+=-]*)*(?:\?[a-zA-Z0-9.%:_+&/()=-]*)?(?:#[a-zA-Z0-9.%:()_+=-]*)?)|((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%/&=?.]+\.[a-z]{2,4}\/?([^\s<>#%",{}\\|\\^[]`]+)?)/g;

		let text = message;
		let match = text.match(urlRegex);
		let matchUnique = [...new Set(match)];

		if (!matchUnique) return message;

		for (let element of matchUnique) {
			let linkHtml;

			if (element.includes('https') || element.includes('http')) {
				linkHtml = `\n<a href="${element}" target="_blank">${element}</a>\n`;
			} else
				linkHtml = `\n<a href="https://${element}" target="_blank">${element}</a>\n`;

			text = text.replaceAll(element, linkHtml);
		}

		return text;
	};

	/**
	 * Adjust Emoji size to 20px
	 * @param {String} message
	 * @returns
	 */
	const adjustEmojiSize = (message) => {
		const emojiRegex =
			/(\u00a9|\u00ae|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;

		let text = message;
		let emojis = text.match(emojiRegex);
		let uniqueEmojis = [...new Set(emojis)];

		if (!uniqueEmojis) return message;

		for (let emoji of uniqueEmojis) {
			let bigEmoji = `<span style="font-size: ${CommonConst.EMOJI_SIZE}px">${emoji}</span>`;

			text = text.replaceAll(emoji, bigEmoji);
		}

		return text;
	};

	const emoji = adjustEmojiSize(rawMessage);
	const link = generateHtmlLink(emoji);
	return link;
}
