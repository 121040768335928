const MemoConst = {
	// Update time in milliseconds (1 second)
	DELAY_TIME: 1000,
	// Limit size in byte (25MB)
	LIMIT_SIZE: 26214400,

	MINIMUM_SIZE_WIDTH: '245px',

	MINIMUM_SIZE_HEIGHT: '190px',
};

export default MemoConst;
