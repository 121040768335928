import ApiService from './api.service';

const serviceURL = 'zoom';
const ZoomService = {
	createSession(body) {
		return ApiService.post(`${serviceURL}/session`, {
			doc_content: body,
		});
	},
	updateDuration(projectId, body) {
		return ApiService.post(`${serviceURL}/duration/${projectId}`, {
			doc_content: body,
		});
	}
};

export default ZoomService;
