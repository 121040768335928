// import dragElement from './NotificationDragFunc';
import { AllowDrag } from '../../directives/HxAllowDrag/allowDrag.directive';
import { filterMessage } from '@/store/modules/FilterMessage.js';

import { mapState } from 'vuex';
export default {
	name: 'ComfirmModal',
	directives: {
		AllowDrag,
	},
	props: {
		selectiveContents: {
			type: String,
		},
		selectiveTitle: {
			type: String,
		},
		isSelective: {
			type: Boolean,
		},
		selectiveModalId: {
			type: String,
		},
	},
	data() {
		return {
			isConfirmRequire: false,
			inputContent: '',
		};
	},
	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'creatorAvatar',
			'clientAvatar',
		]),
	},
	methods: {
		filterMessage,
		onSelectYesClick() {
			this.isConfirmRequire = true;
			this.$emit('on-confirm-require', this.isConfirmRequire);
			this.$bvModal.hide(`${this.selectiveModalId}`);
		},
		onSelectNoClick() {
			this.$bvModal.hide(`${this.selectiveModalId}`);
			this.$bvModal.hide('error-modal');
			this.$emit('on-decline');
		},
	},
};
