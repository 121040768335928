<template>
	<b-modal
		:id="imageSelectModalId"
		hide-footer
		hide-header
		centered
		body-class="previewImageModal__body"
		content-class="previewImageModal__content"
		dialog-class="previewImageModal__dialog"
	>
		<div class="previewImageModal__imageArea">
			<SkeletonLoadingImage
				class="previewImageModal__skeletonLoading"
				:imageSrc="selectImageUrl"
				:imageContain="true"
			/>
		</div>
		<div class="previewImageModal__buttonContainer">
			<img
				class="previewImageModal__buttonCross"
				src="../../../assets/CoCreation/cross_btn.svg"
				@click="onClick(selectImageId, crossImageId)"
			/>
			<div class="previewImageModal__hr" />
			<div
				class="previewImageModal__buttonRound"
				@click="onClick(selectImageId, roundImageId)"
			/>
		</div>
	</b-modal>
</template>

<script>
import SkeletonLoadingImage from '@/components/SkeletonLoadingImage/SkeletonLoadingImage.vue';

export default {
	name: 'CoCreateImageSelectModal',
	components: { SkeletonLoadingImage },
	props: {
		imageSelectModalId: {
			type: String,
		},
		crossImageId: {
			type: Number,
		},
		roundImageId: {
			type: Number,
		},
		selectImageUrl: {
			type: String,
		},
		selectImageId: {
			type: Number,
		},
	},
	methods: {
		onClick(imageId, imageListId) {
			const imageInfo = {
				id: imageId,
				listId: imageListId,
			};
			this.$emit('on-select-image', imageInfo);
			this.$bvModal.hide(this.imageSelectModalId);
		},
	},
};
</script>

<style src="./ImageSelectModal.scss" lang="scss"></style>
