var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sidebarLeft"},[_c('div',{staticClass:"sidebarLeft__container",class:{ sidebarLeft__disable: _vm.clientMode }},[_c('div',{staticClass:"sidebarLeft__head"},[_vm._m(0),_c('div',{staticClass:"piechart"},[_c('div',{staticClass:"piechart__container"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPieChart),expression:"showPieChart"}],staticClass:"piechart__chart",attrs:{"src":_vm.pieChart}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.projectDeadline),expression:"projectDeadline"}],staticClass:"piechart__label",attrs:{"for":""}},[_vm._v("納品日: "+_vm._s(_vm._f("formatToDateString")(_vm.projectDeadline)))])])])]),_c('div',{staticClass:"sidebarLeft__body"},[_c('div',{staticClass:"mainMenu"},[_c('div',{staticClass:"mainMenu__container"},[_c('div',{staticClass:"mainMenu__list"},_vm._l((_vm.menuItems),function(item,index){return _c('router-link',{directives:[{name:"show",rawName:"v-show",value:(!item.isHidden),expression:"!item.isHidden"}],key:index,staticClass:"mainMenu__listItem",class:{ activeClass: item.inProcessing },attrs:{"event":"","to":item.route,"active-class":"active","tag":"button","exact":"","disabled":item.disabled},nativeOn:{"click":function($event){$event.preventDefault();return _vm.onChangeScreen(item.route)}}},[_c('div',{staticClass:"screen-icon-wrapper"},[(_vm.redDotIndexs.includes(index))?_c('div',{staticClass:"notificationRedDot notificationRedDot__sideBarLeft"}):_vm._e(),(item.icon)?_c('img',{class:[
										'icon icon' + index,
										{ 'is-confirm': item.isConfirm },
										{ 'is-disabled': item.disabled },
									],attrs:{"src":require(`@/assets/LeftSideBarIcon/${item.icon}`)}}):_vm._e()]),_c('span',{staticClass:"mainMenu__linkText",class:{
									'is-confirm': item.isConfirm,
									'sub-item': item.isSubItem,
									'is-disabled': item.disabled,
									activeClass__text: item.inProcessing,
								}},[_vm._v(" "+_vm._s(item.name)+" ")])])}),1)])])]),_c('div',{staticClass:"sidebarLeft-bottom"},[_c('div',{staticClass:"documentMenu"},[_c('div',{staticClass:"documentMenu__container"},[_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(
							_vm.projectInfo['creator_finished'] === 1 ||
							_vm.projectInfo['client_finished'] === 1
						),expression:"\n\t\t\t\t\t\t\tprojectInfo['creator_finished'] === 1 ||\n\t\t\t\t\t\t\tprojectInfo['client_finished'] === 1\n\t\t\t\t\t\t"}],staticClass:"documentMenu__finishBlock",class:{ 'is-disabled': _vm.screenFinishedItem.disabled },attrs:{"to":_vm.screenFinishedItem.route,"active-class":"documentMenu__finishBlock--active","tag":"button","exact":"","disabled":_vm.screenFinishedItem.disabled},nativeOn:{"click":function($event){return _vm.onSelectScreen.apply(null, arguments)}}},[_c('img',{staticClass:"documentMenu__finishBlock--icon"}),_c('div',{staticClass:"documentMenu__finishBlock--text"},[_vm._v("納品完了画面")])])],1)])])]),_c('RedirectModal',{attrs:{"selectiveModalId":_vm.confirmModalId,"isSelective":false},on:{"on-confirm-require":_vm.onConfirmChangeScreen}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"logo"},[_c('img')])
}]

export { render, staticRenderFns }