import ApiService from './api.service';

const WhiteBoardUrl = 'whiteboard';
const WhiteBoardService = {
	/**
	 * Get whiteboard by project id
	 * @param {Integer} projectId
	 * @returns {Object} Promise  
	 */
	get(projectId) {
		return ApiService.get(`${WhiteBoardUrl}`, `${projectId}`);
	},
	/**
	 * Post whiteboard by project id
	 * @param {Object} data includes whiteboard data and project id
	 * @returns {Object} Promise  
	 */
	post(data) {
		return ApiService.post(`${WhiteBoardUrl}/`, {
			doc_content: {
				...data,
			},
		});
	},
	/**
	 * Put whiteboard by project id
	 * @param {Object} data includes whiteboard data and project id
	 * @returns {Object} Promise 
	 */
	put(projectId, data) {
		return ApiService.put(`${WhiteBoardUrl}/${projectId}`, {
			doc_content: {
				...data,
			},
		});
	},
	pushImage(projectId, data) { 
		return ApiService.put(`${WhiteBoardUrl}/images/push/${projectId}`, {
			doc_content: {
				...data,
			},
		});
	}
};

export default WhiteBoardService;
