import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import { mapState } from 'vuex';
import { AllowDrag } from '@/directives/HxAllowDrag/allowDrag.directive';
import MemoService from '@/services/API/memo.service';
import TextEditor from '@/components/TextEditor/TextEditor.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';

import ConstValue from '@/constants/MemoConst.js';
import { checkMobileScreen } from '@/store/modules/ScreenWidth';

import AddOnService from '@/services/API/add_on.service';
import socketClient from '@/services/SOCKET';

export default {
	name: 'MemoScreen',
	components: {
		ScreenHeader,
		TextEditor,
		NotificationModal,
	},
	props: {
		isMemoScreenMinimum: Boolean,
	},
	directives: {
		AllowDrag,
	},
	data() {
		return {
			/**
			 * @type {Integer}
			 */
			memoId: undefined,
			/**
			 * setTimeout object
			 * @type {Object}
			 */
			saveTimeout: undefined,
			screenIcon: 'memo-icon',
			screenName: '自分メモ',
			updatedContent: '',
			initContent: '',
			isCreatingMemo: false,

			//NotificationModal Save Content
			saveDecisionContentModalId: 'save-memo-confirm-modal',
			saveDecisionContentModalContents: checkMobileScreen()
				? '編集内容を「 File 」 に\n保存しますか？'
				: '編集内容を「 File 」 に保存しますか？',

			plainModalId: 'memo-save-pdf-modal',
			plainContents: '保存しました',
		};
	},

	computed: {
		// Get projectId, userId from store
		...mapState(['projectId', 'userId', 'projectInfo']),
	},

	watch: {
		async userId(newUserId) {
			await this._getMemo(this.projectId, newUserId);
		},
	},

	methods: {
		getMemoByteSize(content) {
			return new Blob([content]).size;
		},
		onMinimumButtonClick() {
			this.$emit('on-switch-minimun-mode', {
				isMemoScreenMinimum: true,
			});
		},

		onCloseButonClick() {
			this.$emit('on-close-memno');
		},

		async onUpdateMemo(content) {
			this.updatedContent = content;

			if (this.isCreatingMemo) return;

			if (content && !this.memoId) {
				this.isCreatingMemo = true;
				await this._createMemo(content);
				return;
			}

			// Clear previous timeOut variable
			clearTimeout(this.saveTimeout);

			// Start new timeOut
			this.saveTimeout = setTimeout(async () => {
				// save text after 1 second
				let memoByteSize = this.getMemoByteSize(content);
				// Check limit content size.
				if (memoByteSize > ConstValue.LIMIT_SIZE) {
					//TODO: Notify here
					return;
				}
				if (this.memoId) await this._updateMemo(this.memoId, content);
			}, ConstValue.DELAY_TIME);
		},

		/**
		 * Get Memo data
		 * @param {Integer} projectId Project ID
		 * @param {Integer} userId Creator ID
		 */
		async _getMemo(projectId, userId) {
			try {
				let response = await MemoService.search({
					params: {
						project_id: projectId,
						user_id: userId,
					},
				});

				if (!response || response.status !== 200) {
					throw 'Get Memo failed';
				}
				console.log('%c Get Memo successfully', 'color: green');
				this.initContent = this.updatedContent = response.data['content'];
				this.memoId = response.data['id'];
			} catch (error) {
				console.log(`%c Get Memo failed: ${error}`, 'color: red');
			}
		},

		/**
		 * Create new Memo
		 * @param {String} content Memo content
		 */
		async _createMemo(content) {
			try {
				let response = await MemoService.post({
					doc_content: {
						content: content,
						project_id: this.projectId,
						user_id: this.userId,
					},
				});

				if (!response || response.status !== 200) {
					throw 'Create Memo failed';
				}

				console.log('%c Create Memo successfully', 'color: green');
				this.memoId = response.data['id'];
				this.isCreatingMemo = false;
			} catch (error) {
				console.log(`%c Create Memo failed: ${error}`, 'color: red');
			}
		},

		/**
		 * Update Memo
		 * @param {Integer} memoId Memo ID
		 * @param {String} content Memo content
		 */
		async _updateMemo(memoId, content) {
			try {
				let response = await MemoService.update(memoId, {
					doc_content: {
						content: content,
					},
				});

				if (!response || response.status !== 200) {
					throw 'Update memo failed';
				}

				console.log('%c Update memo successfully', 'color: green');
			} catch (error) {
				console.log(`%c Update memo failed: ${error}`, 'color: red');
			}
		},
		async onTransferUploadedFile(key, file_name) {
			this.$store.commit('setIsAppProcessing', true);

			this.pdfFileKey = key.pdfFileKey;

			let body = {
				doc_content: {
					project_id: this.projectId,
					user_id: this.userId,
					default_name: file_name,
					image_key: this.pdfFileKey,
					screen_name: 'memo',
					name_by_client: '',
					name_by_creator: '',
					delete_flag: 0,
				},
			};
			try {
				let response = await AddOnService.post(body);
				if (response.status == 200) {
					console.log(`Done upload PDF ${file_name}`);
					this.$bvModal.show(`${this.plainModalId}`);
				}
				this.sendDataTransfer();
			} catch (error) {
				console.log(`Upload PDF failed: ${error}`, 'color: red');
			}

			this.$store.commit('setIsAppProcessing', false);
		},

		onSaveButtonClick() {
			this.$bvModal.show(this.saveDecisionContentModalId);
		},
		async onSaveMemoContent() {
			this.$store.commit('setIsAppProcessing', true);

			await this.$refs.textEditor.savePdf();

			this.$store.commit('setIsAppProcessing', false);
		},

		sendDataTransfer() {
			let event_name =
				this.userId === this.projectInfo['responsible_user']
					? 'creator-memo-upload-file'
					: 'client-memo-upload-file';

			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: event_name,
				},
				room: this.projectId,
			});
		},
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		if (this.userId) await this._getMemo(this.projectId, this.userId);

		this.$store.commit('setIsAppProcessing', false);

		// Save text before unload page
		window.addEventListener('beforeunload', async () => {
			this.$store.commit('setIsAppProcessing', true);

			await this._updateMemo(this.memoId, this.updatedContent);

			this.$store.commit('setIsAppProcessing', false);
		});
	},

	async beforeDestroy() {
		this.$store.commit('setIsAppProcessing', true);

		if (this.memoId) await this._updateMemo(this.memoId, this.updatedContent);

		this.$store.commit('setIsAppProcessing', false);
	},
};
