import { fabric } from 'fabric-with-gestures';
// have error when using cryptoRandomString from 'crypto-random-string'
import { Tools, ClipTools } from '../ToolTypes';
import Menu from '../WhiteBoardMenu/WhiteBoardMenu.vue';
import WhiteBoardService from '../../../services/API/whiteboard.service';
import { mapState } from 'vuex';
import socketClient from '../../../services/SOCKET';
import Constants from '../../../constants';
import TransferService from '@/services/API/transfer.service';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import AddOnService from '@/services/API/add_on.service';

const SCALE_VALUE = [0.1, 0.25, 0.5, 0.75, 1, 1.25, 1.5];
export default {
	name: 'Whiteboard',
	components: {
		'action-menu': Menu,
		NotificationModal,
	},
	data() {
		return {
			socket: {},
			socketLoaded: false,
			canvas: null,
			whiteboard: null,
			roomName: '',
			toolSelected: '',
			tools: Tools,
			selectorOpen: false,
			shapeStarted: false,
			shapeBeingAdded: null,
			lastPosX: null,
			lastPosY: null,
			shapeStartX: 0,
			shapeStartY: 0,
			snapshotHistory: [],
			currentSnapshotIndex: -1,
			canRedo: false,
			fillColor: '#000000',
			strokeColor: '#000000',
			strokeWidth: 3,
			isTypingMath: false,
			polygonPoints: [],
			clipboard: {},
			nMathFields: 0,
			currentMathfield: null,
			mathCoords: [],
			isMobile: false,
			shouldSelectTextBox: false,
			maxNewImageHeight: 500,
			maxNewImageWidth: 360,
			screenName: Constants.screen.liveScreen.screenName,
			onMultiTouch: false,
			modalContent: '',
			deleteObjects: [],
			selectiveModalId: 'whiteboard-selective-modal',
			scaleFactor: 1,

			factorIdx: 4,
			ctrlDown: false,

			pendingClick: false,

			xClickCords: 0,
			yClickCords: 0,
		};
	},
	computed: {
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'managementMasterInfo',
			'schedule',
			'preview',
		]),
		isProduction: function () {
			return process.env.VUE_APP_MODE === 'PRODUCTION';
		},
		urlForSocket() {
			const protocol = this.isProduction ? 'https://' : 'http://';
			const hostName = this.isProduction ? window.location.hostname : 'localhost';
			const hostPort = this.isProduction ? 443 : 8000;
			return protocol + hostName + `:${hostPort}`;
		},
		positionStringsForTool() {
			switch (this.toolSelected) {
				case Tools.Ellipse:
					return { x: 'rx', y: 'ry' };
				case Tools.Line:
				case Tools.Arrow:
					return { x: 'x2', y: 'y2' };
				default:
					return { x: 'width', y: 'height' };
			}
		},
	},
	// created() {
	// 	window.addEventListener('resize', this.onWindowResize);
	// },
	// destroyed() {
	// 	window.removeEventListener('resize', this.onWindowResize);
	// },
	watch: {
		toolSelected: function (newTool, oldTool) {
			console.log('toolSelected', newTool, oldTool);
			switch (newTool) {
				case Tools.Pan:
					this.changeCursor({ defaultCursor: 'grab' });
					break;
				case Tools.Save:
					this.onSaveImageCaptureClick();
					this.toolSelected = oldTool;
					break;
				default:
					this.changeCursor({ defaultCursor: 'default' });
					if (
						this.preview &&
						newTool !== Tools.Pan &&
						newTool !== Tools.ZoomIn &&
						newTool !== Tools.ZoomOut
					) {
						this.toolSelected = 'None';
						return;
					}
					break;
			}
		},
	},
	methods: {
		onKeyDown(event) {
			if (event.keyCode == 17 || event.keyCode == 91) {
				this.ctrlDown = true;
			}
			if (event.keyCode == 67 && this.ctrlDown) {
				this.copyToClipboard(false);
			}
			if (event.keyCode == 86 && this.ctrlDown) {
				this.paste();
			}
			if (event.keyCode == 90 && this.ctrlDown) {
				if (this.currentSnapshotIndex !== 0) {
					this.currentSnapshotIndex -= 1;
					this.canvas.loadFromJSON(
						JSON.parse(this.snapshotHistory[this.currentSnapshotIndex][1]),
						this.canvas.renderAll.bind(this.canvas)
					);
					this.sendCanvas();
					this.canRedo = true;
				}
			}
		},
		onKeyUp(event) {
			if (event.keyCode == 17 || event.keyCode == 91) {
				this.ctrlDown = false;
			}
		},
		onClose() {
			this.$emit('on-close-white-board');
		},
		changeCursor({ defaultCursor, hoverCursor, moveCursor }) {
			try {
				this.canvas.defaultCursor = defaultCursor ?? this.canvas.defaultCursor;
				this.canvas.hoverCursor = hoverCursor ?? this.canvas.hoverCursor;
				this.canvas.moveCursor = moveCursor ?? this.canvas.moveCursor;
			} catch (e) {
				console.log(e);
			}
		},
		listenToCanvasEvents() {
			this.listenToMouseDown();
			this.listenToMouseMove();
			this.listenToMouseUp();
			this.listenToMouseClick();
			this.listenToLongClick();
			this.listenToTouchGesture();
			this.listenToObjectModified();
			this.canvas.on({
				'selection:created': () => {
					console.log('selection:created');
					if (this.preview) {
						this.canvas.discardActiveObject();
					}
					if (this.toolSelected != this.tools.Select) {
						this.toolSelected = 'None';
					}
				},
				'selection:updated': () => {
					console.log('selection:updated');
				},
			});
		},
		listenToMouseDown() {
			this.canvas.on('mouse:down', (event) => {
				if (this.preview && this.toolSelected !== Tools.Pan) {
					return;
				}
				const evt = event.e;
				const mouse = this.canvas.getPointer(evt);
				switch (this.toolSelected) {
					case Tools.Pan:
						if (evt.type.includes('touch') && !this.isMobile) {
							return;
						}
						console.log({
							clientX: this.isMobile ? evt.touches[0].clientX : evt.clientX,
							clientY: this.isMobile ? evt.touches[0].clientY : evt.clientY,
						});
						this.changeCursor({ defaultCursor: 'grabbing' });
						this.lastPosX = this.isMobile ? evt.touches[0].clientX : evt.clientX;
						this.lastPosY = this.isMobile ? evt.touches[0].clientY : evt.clientY;
						return;
					case Tools.Rectangle:
					case Tools.Ellipse:
					case Tools.Line:
					case Tools.Arrow:
						{
							this.shapeStarted = true;
							this.shapeStartX = mouse.x;
							this.shapeStartY = mouse.y;
							const options = {
								width: 0,
								height: 0,
								left: this.shapeStartX,
								top: this.shapeStartY,
								fill: this.fillColor,
								stroke: this.strokeColor,
								strokeWidth: this.strokeWidth,
							};
							if (this.toolSelected !== Tools.Arrow) {
								const shape = this.createNewShape(options);
								this.addShape(shape);
								this.canvas.renderAll();
								this.shapeBeingAdded = shape;
							} else {
								const shape = [
									new fabric.Line(
										[
											this.shapeStartX,
											this.shapeStartY,
											this.shapeStartX,
											this.shapeStartY,
										],
										options
									),
									new fabric.Triangle({
										...options,
										fill: this.strokeColor,
										width: this.strokeWidth * 4,
										height: this.strokeWidth * 4,
										originX: 'center',
										originY: 'center',
										id: 'arrow-pointer',
									}),
								];
								this.addShape(shape[0]);
								this.addShape(shape[1]);
								this.canvas.renderAll();
								this.shapeBeingAdded = shape;
							}
						}
						return;
					default:
						return;
				}
			});
		},
		listenToMouseMove() {
			this.canvas.on('mouse:move', (event) => {
				if (this.preview && this.toolSelected !== Tools.Pan) {
					return;
				}
				const evt = event.e;
				const mouse = this.canvas.getPointer(evt);
				if (
					this.toolSelected === Tools.Pan &&
					!!this.lastPosX &&
					!!this.lastPosY
					// && evt.type.includes('touch')
				) {
					console.log('in pan');
					let clientX = this.isMobile ? evt.touches[0].clientX : evt.clientX;
					let clientY = this.isMobile ? evt.touches[0].clientY : evt.clientY;
					this.canvas.viewportTransform[4] += clientX - this.lastPosX;
					this.canvas.viewportTransform[5] += clientY - this.lastPosY;
					this.canvas.requestRenderAll();
					this.lastPosX = clientX;
					this.lastPosY = clientY;
				}
				if (this.shapeStarted) {
					if (this.toolSelected === Tools.Arrow) {
						this.dragOutShape(this.shapeBeingAdded[0], mouse);
						this.dragOutShape(this.shapeBeingAdded[1], mouse);
					} else {
						this.dragOutShape(this.shapeBeingAdded, mouse);
					}
					this.canvas.renderAll();
				}
			});
		},
		listenToMouseUp() {
			this.canvas.on('mouse:up', async (event) => {
				if (this.preview && this.toolSelected !== Tools.Pan) {
					return;
				}
				const mouse = this.canvas.getPointer(event.e);
				if (this.shouldSelectTextBox) {
					this.shouldSelectTextBox = false;
					this.clickedTool(Tools.Select);
					this.canvas.setActiveObject(this.shapeBeingAdded);
					this.shapeBeingAdded = {};
					this.canvas.renderAll();
					this.pushSnapshot();
					this.sendCanvas();
					return;
				}
				switch (this.toolSelected) {
					case Tools.Pan:
						if (event.e.type.includes('touch')) {
							return;
						}
						this.lastPosX = null;
						this.lastPosY = null;
						this.changeCursor({ defaultCursor: 'grab' });
						return;
					case Tools.Rectangle:
					case Tools.Ellipse:
					case Tools.Line:
					case Tools.Arrow:
					case Tools.Pen:
						if (this.toolSelected === Tools.Arrow) {
							const arrowShape = new fabric.Group(this.shapeBeingAdded);
							this.addShape(arrowShape);
							this.shapeBeingAdded.forEach((shape) => {
								this.canvas.remove(shape);
							});
						}
						this.canvas.renderAll();
						this.pushSnapshot();
						this.sendCanvas();
						this.shapeStarted = false;
						this.shapeBeingAdded = {};
						return;
					case Tools.Text:
						this.addText(mouse);
						return;
					default:
						return;
				}
			});
		},
		listenToMouseClick() {
			this.canvas.on('mouse:down', (event) => {
				this.xClickCords = event.e.clientX - this.canvas._offset.left;
				this.yClickCords = event.e.clientY - this.canvas._offset.top;
				if (this.toolSelected != Tools.Polygon) return;
				const mouse = this.canvas.getPointer(event.e);
				if (this.pendingClick) {
					clearTimeout(this.pendingClick);
					this.pendingClick = 0;
				}
				switch (event.e.detail) {
					case 1:
						this.pendingClick = setTimeout(() => {
							this.addPolygonPoint(mouse);
						}, 500);
						break;
					case 2:
						if (this.preview && this.toolSelected !== Tools.Pan) {
							break;
						}
						this.finishPolygon();
						break;
					default:
						break;
				}
			});
		},
		listenToLongClick() {
			this.canvas.on('touch:longpress', async () => {
				if (this.preview && this.toolSelected !== Tools.Pan) {
					return;
				}
				// const mouse = this.canvas.getPointer(event.e);
				// this.addPolygonPoint(mouse);
				this.finishPolygon();
			});
		},
		listenToTouchGesture() {
			document.getElementById('canvas-container').addEventListener(
				'touchstart',
				(e) => {
					if (this.preview && this.toolSelected !== Tools.Pan) {
						return;
					}
					if (this.toolSelected === Tools.Pan) {
						this.lastPosX = e.touches[0].clientX;
						this.lastPosY = e.touches[0].clientY;
						this.canvas.selection = false;
					}
				},
				false
			);
			document.getElementById('canvas-container').addEventListener(
				'touchend',
				() => {
					if (this.preview && this.toolSelected !== Tools.Pan) {
						return;
					}
					if (this.toolSelected === Tools.Pan) {
						this.lastPosX = null;
						this.lastPosY = null;
					}
				},
				false
			);
			this.canvas.on('touch:drag', (event) => {
				if (this.preview && this.toolSelected !== Tools.Pan) {
					return;
				}
				if (event.e.touches && event.e.touches[0]) {
					if (this.toolSelected === Tools.Pan && !!this.lastPosX && !!this.lastPosY) {
						this.canvas.viewportTransform[4] +=
							event.e.touches[0].clientX - this.lastPosX;
						this.canvas.viewportTransform[5] +=
							event.e.touches[0].clientY - this.lastPosY;
						this.canvas.requestRenderAll();
						this.lastPosX = event.e.touches[0].clientX;
						this.lastPosY = event.e.touches[0].clientY;
					}
				}
			});
		},
		listenToObjectModified() {
			this.canvas.on('object:modified', () => {
				if (this.preview && this.toolSelected !== Tools.Pan) {
					return;
				}
				this.pushSnapshot();
				this.sendCanvas();
			});
		},
		createNewShape(options) {
			if (this.preview && this.toolSelected !== Tools.Pan) {
				return;
			}
			switch (this.toolSelected) {
				case Tools.Rectangle:
					return new fabric.Rect(options);
				case Tools.Ellipse: {
					const ellipseOpts = {
						originX: 'left',
						originY: 'top',
						rx: 5,
						ry: 1,
						...options,
					};
					return new fabric.Ellipse(ellipseOpts);
				}
				case Tools.Text:
					return new fabric.IText('入力してください', options);
				case Tools.Line:
					return new fabric.Line(
						[this.shapeStartX, this.shapeStartY, this.shapeStartX, this.shapeStartY],
						options
					);
				default:
					return {};
			}
		},
		dragOutShape(shape, mouse) {
			if (this.preview && this.toolSelected !== Tools.Pan) {
				return;
			}
			if (this.toolSelected === Tools.Line || this.toolSelected === Tools.Arrow) {
				if (this.toolSelected === Tools.Arrow && shape.id === 'arrow-pointer') {
					const angle = Math.atan2(
						mouse.y - this.shapeStartY,
						mouse.x - this.shapeStartX
					);
					shape.rotate(fabric.util.radiansToDegrees(angle) + 90);
					shape.set('left', mouse.x + 1).set('top', mouse.y + 2);
				} else {
					shape
						.set(this.positionStringsForTool.x, mouse.x)
						.set(this.positionStringsForTool.y, mouse.y);
				}
			} else {
				shape.set('left', Math.min(mouse.x, this.shapeStartX));
				shape.set('top', Math.min(mouse.y, this.shapeStartY));
				const sizeMultiplier = this.toolSelected === Tools.Ellipse ? 0.5 : 1;
				shape
					.set(
						this.positionStringsForTool.x,
						Math.abs(mouse.x - this.shapeStartX) * sizeMultiplier
					)
					.set(
						this.positionStringsForTool.y,
						Math.abs(mouse.y - this.shapeStartY) * sizeMultiplier
					);
			}
			shape.setCoords();
		},
		addText(mouse) {
			if (this.preview && this.toolSelected !== Tools.Pan) {
				return;
			}
			const options = {
				left: mouse.x,
				top: mouse.y,
				fill: this.strokeColor,
			};
			const shape = this.createNewShape(options);
			// default shape change to (shape as any) for disable ts(2339)
			shape.on('editing:exited', () => {
				if (!shape.text.trim()) {
					this.canvas.remove(shape);
				} else {
					this.shouldSelectTextBox = true;
					this.shapeBeingAdded = shape;
				}
			});
			this.addShape(shape);
			this.canvas.setActiveObject(shape);
			this.canvas.renderAll();
			shape.enterEditing();
			shape.selectAll();
		},
		addPolygonPoint(mouse) {
			if (this.preview && this.toolSelected !== Tools.Pan) {
				return;
			}
			const pt = { x: mouse.x, y: mouse.y };
			this.polygonPoints.push(pt);
			if (this.polygonPoints.length === 1) {
				this.polygonPoints.push({ x: mouse.x + 1, y: mouse.y + 1 });
				const shape = new fabric.Polygon(this.polygonPoints, {
					perPixelTargetFind: true,
					top: mouse.y,
					left: mouse.x,
					fill: this.fillColor,
					stroke: this.strokeColor,
					strokeWidth: this.strokeWidth,
				});
				this.addShape(shape);
				this.canvas.renderAll();
				this.shapeBeingAdded = shape;
			} else {
				const newPts = this.polygonPoints;
				const activePolygons = this.canvas.getObjects().filter((obj) => {
					return obj.type === 'polygon';
				});
				const newObject = activePolygons[0].toObject();
				this.canvas.remove(...activePolygons);
				delete newObject.points;
				delete newObject.top;
				delete newObject.left;
				const shape = new fabric.Polygon(newPts, newObject);
				this.shapeBeingAdded = shape;
				this.addShape(shape);
				this.canvas.renderAll();
			}
		},
		finishPolygon() {
			if (this.toolSelected === Tools.Polygon && this.polygonPoints.length) {
				this.pushSnapshot();
				this.sendCanvas();
				this.shapeStarted = false;
				this.shapeBeingAdded = {};
				this.polygonPoints = [];
				this.clickedTool(Tools.Select);
			}
		},
		imageUploaded(img) {
			const image = new fabric.Image(img);
			// Scale down to smallest iphone size
			if (image.height > this.maxNewImageHeight) {
				image.scaleToHeight(this.maxNewImageHeight);
			}
			if (image.width * image.scaleX > this.maxNewImageWidth) {
				image.scaleToWidth(this.maxNewImageWidth);
			}
			this.canvas.centerObject(image);
			this.addShape(image);
			this.canvas.setActiveObject(image);
			this.canvas.renderAll();
			this.clickedTool(Tools.Select);
			this.sendCanvas();
		},
		addShape(shape) {
			shape.evented = false;
			shape.selectable = false;
			this.canvas.add(shape);
		},
		clickedTool(tool) {
			console.log('clickedTool', tool);
			if (
				this.preview &&
				tool !== Tools.Pan &&
				tool !== Tools.ZoomIn &&
				tool !== Tools.ZoomOut
			) {
				console.log('preview', tool);
				return;
			}
			this.canvas.selection = tool === Tools.Select;
			if (
				tool !== Tools.Stroke &&
				tool !== Tools.FillColor &&
				tool !== Tools.StrokeColor
			) {
				this.toggleObjectsSelectable(tool === Tools.Select);
			}
			this.canvas.isDrawingMode = tool === Tools.Pen;
			if (!Object.values(ClipTools).includes(tool)) {
				if (this.toolSelected !== tool) {
					this.toolSelected = tool;
					this.selectorOpen = true;
				} else {
					this.selectorOpen = !this.selectorOpen;
				}
			}
			switch (tool) {
				case Tools.Pen:
					this.canvas.freeDrawingBrush.color = this.strokeColor;
					this.canvas.freeDrawingBrush.width = this.strokeWidth;
					return;
				case Tools.Clear:
					this.modalContent = '全部記入した分を削除してよろしいでしょうか';
					console.log('clear', this.canvas.getObjects());
					this.deleteObjects = this.canvas.getObjects();
					this.$bvModal.show(`${this.selectiveModalId}`);
					// this.canvas.remove(...this.canvas.getObjects());
					// this.pushSnapshot();
					// this.sendCanvas();
					return;
				case Tools.Undo:
					if (this.currentSnapshotIndex !== 0) {
						this.currentSnapshotIndex -= 1;
						this.canvas.loadFromJSON(
							JSON.parse(this.snapshotHistory[this.currentSnapshotIndex][1]),
							this.canvas.renderAll.bind(this.canvas)
						);
						this.sendCanvas();
						this.canRedo = true;
					}
					return;
				case Tools.Redo:
					if (
						this.currentSnapshotIndex !== this.snapshotHistory.length - 1 &&
						this.canRedo
					) {
						this.currentSnapshotIndex += 1;
						this.canvas.loadFromJSON(
							JSON.parse(this.snapshotHistory[this.currentSnapshotIndex][1]),
							this.canvas.renderAll.bind(this.canvas)
						);
						this.sendCanvas();
					}
					return;
				case Tools.ZoomIn:
					if (this.factorIdx < SCALE_VALUE.length - 1) {
						this.factorIdx++;
						if (this.factorIdx != 4) {
							this.zoomIt(
								SCALE_VALUE[this.factorIdx] / SCALE_VALUE[this.factorIdx - 1],
								true
							);
						} else {
							this.zoomIt(1.25, true);
						}
					}
					this.toolSelected = Tools.Pan;
					return;
				case Tools.ZoomOut:
					if (this.factorIdx > 0) {
						this.factorIdx--;
						if (this.factorIdx != 4) {
							this.zoomIt(
								SCALE_VALUE[this.factorIdx] / SCALE_VALUE[this.factorIdx + 1],
								true
							);
						} else {
							this.zoomIt(0.8, true);
						}
					}
					this.toolSelected = Tools.Pan;
					return;
				default:
					return;
			}
		},
		clickedClipTool(tool) {
			if (this.preview && this.toolSelected !== Tools.Pan) {
				return;
			}
			switch (tool) {
				case ClipTools.Delete:
					if (this.canvas.getActiveObjects()) {
						// this.canvas.remove(...this.canvas.getActiveObjects());
						let objects = this.canvas.getActiveObjects();
						console.log('delete', objects);
						if (objects.length >= 1) {
							this.modalContent =
								'選択した物を削除してよろしいでしょうかよろしでしょうか';
							this.deleteObjects = objects;
							this.$bvModal.show(`${this.selectiveModalId}`);
						}
					}
					return;
				case ClipTools.Cut:
					this.copyToClipboard(true);
					return;
				case ClipTools.Copy:
					this.copyToClipboard(false);
					return;
				case ClipTools.Paste:
					this.paste();
					return;
				default:
					return;
			}
		},
		toggleObjectsSelectable(selectable) {
			if (this.preview && this.toolSelected !== Tools.Pan) {
				return;
			}
			this.canvas.discardActiveObject();
			this.canvas.forEachObject((obj) => {
				obj.selectable = selectable;
				obj.evented = selectable;
			});
			this.canvas.renderAll();
		},
		updateColorValue(value) {
			if (this.toolSelected === Tools.StrokeColor) {
				this.strokeColor = value.hex;
				if (this.canvas.getActiveObject()) {
					this.canvas.getActiveObject().set('stroke', this.strokeColor);
					this.canvas.renderAll();
				}
			} else {
				this.fillColor = value.hex;
				if (this.canvas.getActiveObject()) {
					this.canvas.getActiveObject().set('fill', this.fillColor);
					this.canvas.renderAll();
				}
			}
			if (this.canvas.isDrawingMode) {
				this.canvas.freeDrawingBrush.color = this.strokeColor;
			}
		},
		updateStrokeWidth(value) {
			this.strokeWidth = value;
			if (this.canvas.getActiveObject()) {
				this.canvas.getActiveObject().set('strokeWidth', this.strokeWidth);
				this.canvas.renderAll();
			}
		},
		copyToClipboard(shouldCut) {
			// source !!this.canvas.getActiveObject() change to fix  Redundant double negation
			if (this.canvas.getActiveObject()) {
				this.canvas.getActiveObject().clone((cloned) => {
					this.clipboard = cloned;
					if (shouldCut) {
						this.canvas.remove(this.canvas.getActiveObject());
						this.canvas.discardActiveObject();
					}
				});
				this.xClickCords = 0;
				this.yClickCords = 0;
				this.pushSnapshot();
				this.sendCanvas();
			}
		},
		paste() {
			// source !!this.canvas.getActiveObject() change to fix  Redundant double negation
			if (this.preview && this.toolSelected !== Tools.Pan) {
				return;
			}
			if (Object.keys(this.clipboard).length) {
				this.clipboard.clone((clonedObj) => {
					this.canvas.discardActiveObject();
					clonedObj.set({
						left: !this.xClickCords ? clonedObj.left + 10 : this.xClickCords,
						top: !this.yClickCords ? clonedObj.top + 10 : this.yClickCords,
						evented: true,
					});
					if (clonedObj.type === 'activeSelection') {
						clonedObj.canvas = this.canvas;
						clonedObj.forEachObject((obj) => {
							this.canvas.add(obj);
						});
						clonedObj.setCoords();
					} else {
						this.canvas.add(clonedObj);
					}
					this.clipboard.top = !this.xClickCords
						? clonedObj.top + 10
						: this.yClickCords + 10;
					this.clipboard.left = !this.yClickCords
						? clonedObj.left + 10
						: this.xClickCords + 10;
					this.xClickCords = 0;
					this.yClickCords = 0;
					this.canvas.setActiveObject(clonedObj);
					this.canvas.renderAll();
				});
				this.pushSnapshot();
				this.sendCanvas();
			}
		},
		pushSnapshot() {
			this.currentSnapshotIndex += 1;
			if (this.canRedo) {
				this.snapshotHistory = this.snapshotHistory.slice(0, this.currentSnapshotIndex);
				this.canRedo = false;
			}
			const boardState = JSON.stringify(this.canvas.toJSON());
			this.snapshotHistory.push([Date.now(), boardState]);
		},
		sendCanvas() {
			if (this.preview) {
				return;
			}
			const canvasAsJSON = this.canvas.toJSON();
			socketClient.send('data_transfer', {
				data: {
					canvas_data: canvasAsJSON,
					user_id: this.userId,
					event_name: 'send_canvas',
				},
				room: this.projectId,
			});
			this.saveCanvas();
		},
		receiveCanvas(data) {
			this.canvas.loadFromJSON(data, this.canvas.renderAll.bind(this.canvas));
			// localStorage.setItem('canvas', JSON.stringify(data));
		},
		async getWhiteBoard(projectId) {
			return await WhiteBoardService.get(projectId)
				.then((res) => {
					return res.data;
				})
				.catch((err) => {
					throw err;
				});
		},
		async createWhiteBoard(data) {
			return await WhiteBoardService.post(data)
				.then((res) => {
					return res.data;
				})
				.catch((err) => {
					throw err;
				});
		},
		async updateWhiteBoard(projectId, data) {
			return await WhiteBoardService.put(projectId, data)
				.then((res) => {
					return res.data;
				})
				.catch((err) => {
					throw err;
				});
		},
		async pushWhiteBoardImages(projectId, data) {
			return await WhiteBoardService.pushImage(projectId, data)
				.then((res) => {
					return res.data;
				})
				.catch((err) => {
					throw err;
				});
		},
		async saveCanvas() {
			this.$store.commit('setIsAppProcessing', true);

			console.log('save whiteboard', this.canvas.toJSON());
			console.log('%c save whiteboard', 'color: green');
			await this.updateWhiteBoard(this.projectId, {
				canvas: this.canvas.toJSON(),
			});

			this.$store.commit('setIsAppProcessing', false);
		},
		setCanvasSize(width, height) {
			this.canvas.setWidth(width);
			this.canvas.setHeight(height);
		},
		connectSocket() {
			socketClient.listen('new_data_transfer', async (data) => {
				console.log('new_data_transfer', data);
				switch (data?.event_name) {
					case 'send_canvas':
						this.handleCanvasTransfer(data);
						break;

					default:
						break;
				}
			});
		},
		handleCanvasTransfer(data) {
			console.log('data_transfer', data);
			this.receiveCanvas(data?.canvas_data);
		},
		zoomIt(factor, pressedBtn = false) {
			if (pressedBtn) {
				this.scaleFactor = SCALE_VALUE[this.factorIdx];
			} else {
				this.scaleFactor *= factor;
			}
			console.log('zoomIt', factor);
			this.canvas.setHeight(this.canvas.getHeight() * factor);
			this.canvas.setWidth(this.canvas.getWidth() * factor);
			if (this.canvas.backgroundImage) {
				// Need to scale background images as well
				var bi = this.canvas.backgroundImage;
				bi.width = bi.width * factor;
				bi.height = bi.height * factor;
			}
			var objects = this.canvas.getObjects();
			for (var i in objects) {
				var scaleX = objects[i].scaleX;
				var scaleY = objects[i].scaleY;
				var left = objects[i].left;
				var top = objects[i].top;

				var tempScaleX = scaleX * factor;
				var tempScaleY = scaleY * factor;
				var tempLeft = left * factor;
				var tempTop = top * factor;

				objects[i].scaleX = tempScaleX;
				objects[i].scaleY = tempScaleY;
				objects[i].left = tempLeft;
				objects[i].top = tempTop;

				objects[i].setCoords();
			}
			this.canvas.renderAll();
			this.canvas.calcOffset();
			this.updateCanvasSize();
		},
		zoom(event) {
			if (typeof event.preventDefault === 'function') {
				event.preventDefault();
			}
			console.log('zoom', event);
			let { deltaY, self } = event;

			let scale = deltaY > 0 ? Math.min(0.75) : Math.min(1.25);
			console.log({
				deltaY,
			});
			// Restrict scale
			this.zoomIt(deltaY ? scale : self.scale);
			// Apply scale transform
			// el.style.transform = `scale(${scale})`;
		},
		updateCanvasSize() {
			let canvasContainer = document.getElementById('canvas-container');
			console.log(
				'canvasContainer',
				canvasContainer.offsetWidth,
				canvasContainer.offsetHeight
			);
			this.setCanvasSize(canvasContainer.offsetWidth, canvasContainer.offsetHeight);
		},
		onSaveImageCaptureClick() {
			this.$bvModal.show('modal-save-image-capture');
		},
		async onSaveImageCapture() {
			this.$store.commit('setIsAppProcessing', true);

			let now = Date.now();
			console.log(now);
			var canvas = this.$refs.canvas;
			let files = await AddOnService.get(this.projectId);
			let memo_files = files.data.filter((file) => file.screen_name === 'white_board');
			let index = memo_files.length + 1;
			let file_name = ('名称未設定 ' + (index > 1 ? index : '') + '.png').trim();
			await this._uploadAddon({
				doc_content: {
					project_id: this.projectId,
					user_id: this.userId,
					default_name: file_name,
					image_key: await this._uploadImage({
						id: now,
						file_name,
						project_id: this.projectId,
						role: 'add_on',
						content: canvas.toDataURL(),
					}),
					screen_name: 'white_board',
					name_by_client: '',
					name_by_creator: '',
					delete_flag: 0,
				},
			});
			// this.pushWhiteBoardImages(this.projectId, {
			// 	images_capture: [newImageCapture],
			// });

			// console.log(await this._getPreviewImgUrl(newImageCapture.key, 555555));
			this.$bvModal.show('whiteboard-plain-modal-id');

			this.$store.commit('setIsAppProcessing', false);
		},
		async _uploadAddon(body) {
			try {
				await AddOnService.post(body);
				console.log(`Upload Done`);
			} catch (error) {
				console.log(`Upload PDF failed: ${error}`, 'color: red');
			}
		},
		/**
		 * Upload image
		 * @param {Object} params request objects{ url, key, file }
		 * @returns uploaded file key
		 */
		async _uploadImage(params) {
			try {
				let response = await TransferService.post(params);
				if (!response || response.status !== 200) {
					throw 'Upload Image failed!';
				}

				console.log('%c Upload image successfully!', 'color: green');
				let key = response.data.key;
				return key;
			} catch (error) {
				console.log(error);
			}
		},
		async _getPreviewImgUrl(imgKey, expiration) {
			try {
				let response = await TransferService.get(imgKey, expiration);
				if (!response || response.status !== 200) {
					throw 'Get image url failed!';
				}

				console.log('%c Get image url successfully!', 'color: red');
				let imgUrl = response.data.link;
				return imgUrl;
			} catch (error) {
				console.log(error);
			}
		},
		handleTouchStart(event) {
			console.log('handleTouchStart', { event });
			if (event.touches.length > 1) {
				console.log('multi touch');
				this.onMultiTouch = true;
				this.toolSelected = Tools.Pan;
			}
		},
		handleTouchMove(event) {
			console.log('handleTouchMove', { event });
			if (event.touches.length <= 1) {
				this.onMultiTouch = false;
			}
		},
		handleTouchGesture(event) {
			console.log('handleTouchGesture', { event });
			this.zoom(event);
		},
		addEvents() {
			let canvasContainer = document.getElementById('canvas-container');
			// canvasContainer.addEventListener('wheel', this.zoom);
			canvasContainer.addEventListener('touchstart', this.handleTouchStart);
			canvasContainer.addEventListener('touchmove', this.handleTouchMove);
		},
		removeEvents() {
			let canvasContainer = document.getElementById('canvas-container');
			// canvasContainer.removeEventListener('wheel', this.zoom);
			canvasContainer.removeEventListener('touchstart', this.handleTouchStart);
			canvasContainer.removeEventListener('touchmove', this.handleTouchMove);
			window.removeEventListener('resize', this.updateCanvasSize);
			document.removeEventListener('keydown', this.onKeyDown);
			document.removeEventListener('keyup', this.onKeyUp);
		},
		onConfirmRequired() {
			this.canvas.remove(...this.deleteObjects);
			this.canvas.discardActiveObject();
			this.deleteObjects = [];
			this.pushSnapshot();
			this.sendCanvas();
		},
		onClearWhiteBoardClick() {
			this.modalContent = '全部記入した分を削除してよろしいでしょうか';
			console.log('clear', this.canvas.getObjects());
			this.deleteObjects = this.canvas.getObjects();
			this.$bvModal.show(`${this.selectiveModalId}`);
		},
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		document.addEventListener('keydown', this.onKeyDown);
		document.addEventListener('keyup', this.onKeyUp);

		// this.setRoomName();
		if (/Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
			this.isMobile = true;
		}
		// Set up Fabric.js canvas
		this.whiteboard = await this.getWhiteBoard(this.projectId);
		console.log('whiteboard', this.whiteboard);
		if (!this.whiteboard?.canvas) {
			console.log('create whiteboard', this.projectId);
			this.whiteboard = await this.createWhiteBoard({
				project_id: this.projectId,
				canvas: {},
				images_capture: [],
			});
		}

		var canvas = this.$refs.canvas;
		this.canvas = new fabric.Canvas(canvas);

		if (this.preview) {
			this.toolSelected = 'None';
		} else {
			this.clickedTool(Tools.Pen);
		}
		let canvasContainer = document.getElementById('canvas-container');
		this.canvas.setWidth(canvasContainer.offsetWidth);
		this.canvas.setHeight(canvasContainer.offsetHeight);

		window.addEventListener('resize', this.updateCanvasSize());
		this.canvas.selectionFullyContained = false;
		if (
			this.whiteboard &&
			this.whiteboard.canvas &&
			Object.keys(this.whiteboard.canvas).length != 0
		) {
			console.log('load whiteboard canvas');
			this.receiveCanvas(this.whiteboard.canvas);
			this.canvas.loadFromJSON(
				this.whiteboard.canvas,
				this.canvas.renderAll.bind(this.canvas)
			);
		}
		this.pushSnapshot();
		this.listenToCanvasEvents();
		this.canvas.on('touch:gesture', this.handleTouchGesture);
		this.connectSocket();
		this.addEvents();
		window.addEventListener('beforeunload', () => {
			this.removeEvents();
			this.$destroy();
		});

		this.$store.commit('setIsAppProcessing', false);
	},
	beforeDestroy() {
		console.log('beforeDetroy');
		this.removeEvents();
	},
};
