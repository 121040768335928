const SHARE_SCREEN_NAME = 'ShareScreen';
const LIVE_SCREEN_NAME = 'LiveScreen';
const CHAT_SCREEN_NAME = 'ChatTool';
const MEMO_SCREEN_NAME = 'Memo';
const LIVE_DESIGN_SCREEN_NAME = 'LiveDesign';
const CO_CREATE_SCREEN_NAME = 'CoCreate';
const ADD_ON_FOLDER_SCREEN_NAME = 'AddOnFolder';
const COMODE_AI_SCREEN_NAME = 'ComodeAi';
const SETTLEMENT_SCREEN_NAME = 'settlement';

export default {
	SHARE_SCREEN_NAME,
	LIVE_SCREEN_NAME,
	CHAT_SCREEN_NAME,
	MEMO_SCREEN_NAME,
	ADD_ON_FOLDER_SCREEN_NAME,
	LIVE_DESIGN_SCREEN_NAME,
	CO_CREATE_SCREEN_NAME,
	COMODE_AI_SCREEN_NAME,
	SETTLEMENT_SCREEN_NAME,
};
