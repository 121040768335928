import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import RecordConfirmModal from '@/components/RecordConfirmModal/RecordConfirmModal.vue';
import LoopAlertModal from '@/components/Modal/ShareScreenAlert/ShareScreenAlert.vue';

import ZoomService from '@/services/API/zoom.service';
import socketClient from '@/services/SOCKET';
import { mapState } from 'vuex';
import ZoomVideo from '@zoom/videosdk';

import { AllowDrag } from '@/directives/HxAllowDrag/allowDrag.directive';

import ShareConst from '@/constants/ShareConst.js';
import LiveConst from '@/constants/LiveConst.js';

const NOT_SHARING = 'クリエイターが共有しておりません。閉じる';

export default {
	components: {
		ScreenHeader,
		ConfirmModal: RecordConfirmModal,
		LoopAlertModal,
	},

	directives: { AllowDrag },

	props: {
		isLiveScreenMinimum: {
			type: Boolean,
		},
		isShareScreenHiding: {
			type: Boolean,
			default: false,
		},
		username: {
			type: String,
		},
		mobileView: {
			type: Boolean,
		},
		onMeeting: {
			type: Boolean,
		},
		isShareScreenExpandMode: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			recordConfirmModalId: 'record-confirm-selective-modal',
			recordSelectiveTitle: 'このミーティングは録音されています',
			recordSelectiveContents:
				'このレコーディングがクラウドに保存される場合、アカウント オーナーはレコーディングを視聴することもできます。権限が付与された参加者は、（１）自己のローカルデバイスにレコーディングすることも、（２）レコーディングするようにアプリに要求することもできます。これらの人々は、これらのレコーディングをアプリや他のツールで共有できます。\n\nこのミーティングへの参加を続行すると、レコーディングされることに同意したとみなされます。',

			stopRecordConfirmModalId: 'stop-record-confirm-selective-modal',
			stopRecordSelectiveTitle: 'クラウドレコーディングを停止しますか?',
			stopRecordSelectiveContents:
				'停止後、 クラウド録画が使用可能になると、 メールの通知が届きます。',

			screenIcon: 'live-icon',
			screenName: 'Co-mode Screen',
			menuBarItems: [...ShareConst.MENU_BAR_ITEMS],
			extendMenuBarItems: LiveConst.EXTEND_MENU_BAR_ITEMS,
			isBlur: false,
			backgrounds: LiveConst.BACKGROUNDS,
			participants: [
				{
					displayName: this.username,
				},
			],
			currentParticipant: {},
			currentParticipantId: null,
			secondaryParticipants: [],
			currentUser: {},
			isChromiumAndWithoutSharedArrayBuffer:
				!!window.chrome && !(typeof SharedArrayBuffer === 'function'),
			isChromium: !!window.chrome,
			isSafari: !!window.safari,
			isAndroid: /Android/i.test(navigator.userAgent),
			isIOS: /iPhone|iPad|iPod/i.test(navigator.userAgent),
			resolution: window.chrome
				? LiveConst.RESOLUTION_RATIO['16:9']
				: LiveConst.RESOLUTION_RATIO['4:3'],
			videoQuality: LiveConst.VIDEO_QUALITY['720p'],
			devices: [],
			defaultDevices: [],
			zoomClient: null,
			zoomStream: null,

			streamWidth: 0,
			streamHeight: 0,

			audioDecode: null,
			audioEncode: null,
			videoDecode: null,
			videoEncode: null,

			cloudRecording: null,

			isMuted: true,
			isCapturingVideo: false,
			isShareScreen: undefined,
			isRecordingScreen: false,
			isMenuExpand: false,

			isSeesionJoined: false,

			cameraList: [],
			micList: [],
			speakerList: [],
			activeCamera: undefined,
			activeMic: undefined,
			activeSpeaker: undefined,
			role: 0,
			timeConnected: new Date(),
			isUpdated: false,

			duplicateUser: false,
		};
	},
	computed: {
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'schedule',
			'creatorInfo',
			'managementMasterInfo',
			'preview',
		]),
		reversedParticipants() {
			if (!this.mobileAndTabletCheck) {
				return this.participants.slice().reverse();				
			}
			else {
				return []
			}
		},
		isVideoReady() {
			return this.videoDecode && this.videoEncode;
		},
		isAudioReady() {
			return this.audioDecode && this.audioEncode;
		},
		isSharingView() {
			return this.participants.some((participant) => participant.sharerOn);
		},
		isSharingVideo() {
			return this.participants.some((participant) => participant.bVideoOn);
		},
		displayParticipants() {
			return this.isSharingView ? this.participants : this.secondaryParticipants;
		},
		miniSize() {
			return this.mobileView ? LiveConst.MOBILE_SIZE : LiveConst.MINI_VIEW;
		},
		sharingElementClass() {
			return this.isShareScreenExpandMode
				? 'shareScreen__element--fullSize'
				: 'shareScreen__element';
		},
		mobileAndTabletCheck() {
			let check = false;
			//eslint-disable-next-line
			(function (a) {
				if (
					//eslint-disable-next-line
					/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk|iPhone|iPad|iPod/i.test(
						a
					) ||
					//eslint-disable-next-line
					/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
						a.substr(0, 4)
					)
				)
					check = true;
			})(navigator.userAgent || navigator.vendor || window.opera);
			return check;
		},
	},
	watch: {
		/**
		 * Filter participants to current participant and secondary participants
		 * @param {Array} newParticipants
		 */
		participants: {
			handler: async function (newParticipants, oldParticipants) {
				console.log('participants', newParticipants);
				this.updateUserStatus();
				let isCurrentUserLeft = !newParticipants.some(
					(participant) => participant.userId == this.currentParticipantId
				);
				let refreshCanvas = false;
				if (newParticipants.length == 1) {
					console.log('newParticipants.length == 1');
					this.currentParticipantId = newParticipants[0].userId;
					this.currentParticipant = newParticipants[0];
					this.secondaryParticipants = [];
				}
				newParticipants.map((newParticipant) => {
					let otherParticipant =
						newParticipants.find(
							(participant) => participant.userId != this.currentUser?.userId
						) ?? false;
					if (otherParticipant) {
						this.currentParticipantId = otherParticipant.userId;
						this.currentParticipant = otherParticipant;
					} else {
						if (
							(newParticipant.isHost && !this.currentParticipantId) ||
							isCurrentUserLeft
						) {
							this.currentParticipantId = newParticipant.userId;
							this.currentParticipant = newParticipant;
						}
					}
					this.currentParticipant =
						newParticipant.userId == this.currentParticipantId
							? newParticipant
							: this.currentParticipant;
				});
				let isParticipantShareChange = newParticipants.some((participant, index) => {
					return (
						oldParticipants[index] &&
						participant.sharerOn != oldParticipants[index].sharerOn
					);
				});
				let isParticipantVideoChange = newParticipants.some((participant, index) => {
					return (
						oldParticipants[index] &&
						participant.bVideoOn != oldParticipants[index].bVideoOn
					);
				});
				if (isParticipantShareChange || isParticipantVideoChange) {
					refreshCanvas = true;
				}
				this.currentParticipantId =
					this.participants.find((participant) => participant.sharerOn)?.userId ??
					this.currentParticipantId;
				this.secondaryParticipants = newParticipants.filter(
					(participant) => participant.userId !== this.currentParticipantId
				);
				if (newParticipants.length != oldParticipants.length) {
					refreshCanvas = true;
				}
				if (refreshCanvas) {
					this.fitVideo();
				}
			},
			deep: true,
		},
		currentParticipantId(newParticipantId) {
			this.participants.map((participant) => {
				if (participant.userId === newParticipantId) {
					this.currentParticipant = participant;
				}
			});
			this.secondaryParticipants = this.participants.filter(
				(participant) => participant.userId !== this.currentParticipantId
			);
			if (this.isChromium) {
				this.reRenderVideo();
			}
		},

		isShareScreenExpandMode(newVal) {
			if (newVal) {
				this.menuBarItems = ShareConst.FULL_SIZE_MENU_BAR_ITEMS;
				this.menuBarItems[1].name = this.isMuted ? 'Mute-active' : 'WhiteMute';
				this.menuBarItems[2].name = this.isCapturingVideo ? 'WhiteShowVideo' : 'ShowVideo-active';
			} else {
				this.menuBarItems = ShareConst.MENU_BAR_ITEMS;
				this.menuBarItems[1].name = this.isMuted ? 'Mute-active' : 'Mute';
				this.menuBarItems[2].name = this.isCapturingVideo ? 'ShowVideo' : 'ShowVideo-active';
			}
		},

		async isLiveScreenMinimum(newIsLiveScreenMinimum) {
			if (newIsLiveScreenMinimum === true) {
				this.menuBarItems[LiveConst.MINIMIZE_EXPAND_ID].name = 'DownExpand';
			} else if (newIsLiveScreenMinimum === false) {
				this.menuBarItems[LiveConst.MINIMIZE_EXPAND_ID].name = 'Minimize';
			} else console.log('No mode specified');

			try {
				// if (this.isChromium) {
				// 	this.fitVideo();
				// } else {
				// 	this.$nextTick(() => {
				// 		this.renderVideo();
				// 	});
				// 	this.fitVideo();
				// }
				let receiveScreenElement = document.getElementById(
					LiveConst.RECEIVE_SHARING_SCREEN_ELEMENT
				);
				receiveScreenElement.classList = [this.sharingElementClass];
				this.fitVideo();
			} catch (error) {
				console.log(error);
			}
		},
		userId(newUserId) {
			if (newUserId && !this.zoomStream) {
				this.generator.next().value();
			}
		},
		isMuted(newIsMuted) {
			if (newIsMuted) {
				this.menuBarItems[1].name = 'Mute-active';
			} else {
				if (this.isShareScreenExpandMode) {
					this.menuBarItems[1].name = 'WhiteMute';
				} else {
					this.menuBarItems[1].name = 'Mute';
				}
			}
		},
		isCapturingVideo(newIsCapturingVideo) {
			if (!newIsCapturingVideo) {
				this.menuBarItems[2].name = 'ShowVideo-active';
			} else {
				if (this.isShareScreenExpandMode) {
					this.menuBarItems[2].name = 'WhiteShowVideo';
				} else {
					this.menuBarItems[2].name = 'ShowVideo';
				}
			}
		},
		isVideoReady(newVideoState) {
			if (newVideoState) {
				this.generator.next().value();
			}
		},
		isAudioReady(newAudioState) {
			if (newAudioState) {
				this.startAudio();
			}
		},
		/* eslint-disable no-mixed-spaces-and-tabs */
		cameraList: {
			handler() {
				this.cameraList = this.cameraList.some((device) => device.isActive == undefined)
					? this.cameraList.map((device) => ({
							...device,
							isActive: device.deviceId == this.activeCamera ? true : false,
					  }))
					: this.cameraList;
			},
			deep: true,
		},
		activeCamera(newActiveCamera, oldActiveCamera) {
			this.activeCamera =
				newActiveCamera == 'default' ? oldActiveCamera : newActiveCamera;
		},
		micList: {
			handler() {
				this.micList = this.micList.some((device) => device.isActive == undefined)
					? this.micList.map((device) => ({
							...device,
							isActive: device.deviceId == this.activeMic ? true : false,
					  }))
					: this.micList;
			},
			deep: true,
		},
		speakerList: {
			handler() {
				this.speakerList = this.speakerList.some(
					(device) => device.isActive == undefined
				)
					? this.speakerList.map((device) => ({
							...device,
							isActive: device.deviceId == this.activeSpeaker ? true : false,
					  }))
					: this.speakerList;
			},
			deep: true,
		},
		/* eslint-disable no-mixed-spaces-and-tabs */

		isBlur: {
			handler(newVal) {
				this.backgrounds.forEach((background) => {
					if (background.value === 'blur') background.focused = newVal;
					else background.focused = !newVal;
				});
			},
			immediate: true,
		},

		async isSharingVideo(newIsSharingVideo) {
			if (newIsSharingVideo) {
				this.renderVideo();
			}
		},

		async isSharingView(newIsSharingView) {
			if (newIsSharingView) {
				let userId = this.participants.find(
					(participant) => participant.sharerOn
				)?.userId;
				await this.startShareView(userId);
				// this.fitVideo();
			} else {
				await this.zoomStream.stopShareView().then(console.log).catch(console.log);
				// this.fitVideo();
			}
		},

		isSeesionJoined(newIsSessionJoined) {
			this.$emit('on-change-session-joined', newIsSessionJoined);
		},
		onMeeting(newOnMeeting) {
			if (!newOnMeeting) {
				this.leaveSession(true);
			}
		},
	},
	filters: {
		wordLength(value) {
			return value.length > 4 ? value.slice(0, 4) + '...' : value;
		}
	},
	methods: {
		pinchZoom(canvasElement){
			let canvasElementScale = 1;

			let start = {};

			// Calculate distance between two fingers
			const distance = (event) => {
				return Math.hypot(event.touches[0].pageX - event.touches[1].pageX, event.touches[0].pageY - event.touches[1].pageY);
			};

			canvasElement.addEventListener('touchstart', (event) => {
				if(!this.isShareScreenExpandMode) return
				// console.log('touchstart', event);
				if (event.touches.length === 2) {
					event.preventDefault(); // Prevent page scroll

					// Calculate where the fingers have started on the X and Y axis
					start.x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
					start.y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
					start.distance = distance(event);
				}
			});

			canvasElement.addEventListener('touchmove', (event) => {
				if(!this.isShareScreenExpandMode) return
				// console.log('touchmove', event);
				if (event.touches.length === 2) {
					event.preventDefault(); // Prevent page scroll

					// Safari provides event.scale as two fingers move on the screen
					// For other browsers just calculate the scale manually
					let scale;
					if (event.scale) {
						scale = event.scale;
					} else {
						const deltaDistance = distance(event);
						scale = deltaDistance / start.distance;
					}
					console.log(Math.min(Math.max(1, scale), 4))
					canvasElementScale = Math.min(Math.max(1, scale), 4);

					// Calculate how much the fingers have moved on the X and Y axis
					const deltaX = (((event.touches[0].pageX + event.touches[1].pageX) / 2) - start.x) * 2; // x2 for accelarated movement
					const deltaY = (((event.touches[0].pageY + event.touches[1].pageY) / 2) - start.y) * 2; // x2 for accelarated movement

					// Transform the image to make it grow and move with fingers
					const transform = `translate3d(${deltaX}px, ${deltaY}px, 0) scale(${canvasElementScale})`;
					canvasElement.style.transform = transform;
					canvasElement.style.WebkitTransform = transform;
				}
			});
		},
		drawCircleCanvas(canvasId,x, width, firstRun = false) {
			console.log('%c ==============Draw circle==================', ' color: red');
			console.log('canvasId', canvasId);
			let canvas = document.getElementById(canvasId);
			const ctx = canvas.getContext('2d');
			ctx.beginPath();
			let color = this.isShareScreenExpandMode || firstRun ? '#ebeae9' : '#000000'
			ctx.fillStyle = color;
			ctx.arc(x, width, 400, 0, Math.PI * 2, false); // outer (filled)
			ctx.arc(x, width, width, 0, Math.PI * 2, true); // outer (unfills it)
			ctx.fill();
		},
		
		onClose() {
			this.$emit('close-share-screen', {
				plainModal: false,
				selectiveModal: true,
			});
		},

		/**
		 * Handle menu item click event
		 * @param {String} id Menu item name
		 */
		async onMinimumMenuBarItemClick(id) {
			switch (id) {
				case 1: {
					this.$emit('expand-share-screen');
					let receiveScreenElement = document.getElementById(
						LiveConst.RECEIVE_SHARING_SCREEN_ELEMENT
					);
					receiveScreenElement.classList = ['shareScreen__element--fullSize'];
					if (!this.mobileAndTabletCheck) {
						this.drawCircleCanvas('layout-canvas-0', 105, 37);
						this.drawCircleCanvas('layout-canvas-1', 46, 37);
					}
					if (this.mobileAndTabletCheck && this.isShareScreenExpandMode) {
						let receivedCanvas = document.getElementById('receive-sharing-screen-element');
						receivedCanvas.style.transform = "";
						receivedCanvas.style.WebkitTransform = "";
					}
					this.fitVideo();
					break;
				}
				case 2:
					console.log(
						`${id} click`,
						this.fileterClickingButtonIdx(id, this.menuBarItems)
					);
					if (this.currentParticipant.displayName == NOT_SHARING) return;
					this.isMuted ? this.unMute() : this.mute();
					break;
				case 3:
					if (await this.zoomClient.getCurrentUserInfo().bVideoOn) {
						await this.stopVideo();
						this.reRenderVideo();
					} else {
						await this.startVideo();
					}
					console.log(
						`${id} click`,
						this.fileterClickingButtonIdx(id, this.menuBarItems)
					);
					break;
				case 4:
					this.$emit('close-share-screen', {
						plainModal: false,
						selectiveModal: true,
					});
					break;
				default:
					console.log('No item clicked');
					break;
			}
		},

		async onBackgroundButtonClick(button) {
			let isSupportVirtualBackground =
				await this.zoomStream.isSupportVirtualBackground();
			console.log(`onBackgroundButtonClick ${button.value}`, {
				isSupportVirtualBackground,
			});
			if (isSupportVirtualBackground) {
				await this.updateVirtualBackground(button.value);
			} else {
				console.log('%c Not supportVirtualBackground', 'color: red');
			}
		},
		/**
		 *  update virtual background	and render video
		 * @param {imageUrl| string | "blur" | undefined} background
		 */
		async updateVirtualBackground(background) {
			if (background === 'blur') {
				await this.zoomStream
					.previewVirtualBackground(background)
					.then(() => {})
					.catch(console.log);
				await this.zoomStream
					.updateVirtualBackgroundImage(background)
					.then(() => {
						this.isBlur = true;
					})
					.catch(console.log);
			} else {
				await this.zoomStream
					.updateVirtualBackgroundImage()
					.then(() => {
						this.isBlur = false;
					})
					.catch(console.log);
			}
		},
		/**
		 * Filter item of menu is clicking by name
		 * @param {String} itemName Item name
		 * @param {Array} items Menu bar items
		 * @return {Object}
		 */
		fileterClickingButtonIdx(itemName, items) {
			if (itemName && items) {
				return items.findIndex((item) => item.id === itemName);
			}
		},
		/**
		 * Create a session for zoom meeting
		 * @returns returns a promise<ZoomSession>
		 */
		async createSession() {
			let body = {
				user_id: this.userId,
				project_id: this.projectId,
				expire_time: LiveConst.SESSION_EXPIRE_TIME,
			};
			console.log(body);
			let response = await ZoomService.createSession(body);
			if (!response || response.status !== 200) {
				throw new Error('Create session failed');
			}
			return response.data;
		},
		/**
		 *  Leave a session for zoom meeting
		 * if user is host session will be ended, else user will be removed from session
		 */
		async leaveSession(end = false) {
			console.log({
				end,
				getSessionHost: await this.zoomClient.getSessionHost(),
				isManager: await this.zoomClient.isManager(),
				isHost: await this.zoomClient.isHost(),
			});
			let isEndMeeting = end && this.role;
			console.log({ isEndMeeting });
			await this.zoomClient.leave(isEndMeeting);
			if (this.role == 1 && end == true && !this.isUpdated) {
				this.updateDuration();
			}
			this.isSeesionJoined = false;
			console.log('%cLeave session successfully', 'color: green');
		},

		async refreshParticipants() {
			console.log('refreshParticipants');
			this.participants = await this.zoomClient.getAllUser();
		},

		listenZoomEvents() {
			this.zoomClient.on('user-added', async () => {
				console.log('%c user-added', 'color: #00ff00');
				// You can refresh the participants when
				await this.refreshParticipants();
				this.isUpdated = false;
				this.timeConnected = new Date();
			});
			this.zoomClient.on('user-removed', this.onRemovedUser)
			this.zoomClient.on('user-updated', async (payloads) => {
				console.log('%c user-updated', 'color: #0000ff', payloads);
				// refresh the participants
				await this.refreshParticipants();
				if (payloads.some((payload) => payload.sharerOn === false)) {
					// this.fitVideo();
					console.log('test 538');
					this.$emit('close-share-screen', {
						plainModal: false,
						selectiveModal: true,
					});
				}
				let newStartVideo = payloads.find((payload) => payload.bVideoOn);
				if (newStartVideo) {
					await this.renderVideo();
				}
				let newStopVideo = payloads.find((payload) => payload.bVideoOn == false);
				if (newStopVideo) {
					await this.stopRenderVideo();
				}
			});

			this.zoomClient.on('video-dimension-change', (payload) => {
				console.log('video-dimension-change', payload);
			});
			this.zoomClient.on('recording-change', async (payload) => {
				console.log('%c recording change ', 'color: #0000ff');
				if (payload.state == 'Recording') {
					this.$bvModal.show('record-confirm-selective-modal');
				} else {
					this.isRecordingScreen = false;
				}
			});
			this.zoomClient.on('media-sdk-change', (payload) => {
				console.log('media-sdk-change', payload);
				if (payload.type === 'audio' && payload.result === 'success') {
					if (payload.action === 'encode') {
						// encode for sending audio stream (speak)
						this.audioEncode = true;
					} else if (payload.action === 'decode') {
						// decode for receiving audio stream (hear)
						this.audioDecode = true;
					}
				}
				if (payload.type === 'video' && payload.result === 'success') {
					if (payload.action === 'encode') {
						// encode for sending audio stream (speak)
						this.videoEncode = true;
					} else if (payload.action === 'decode') {
						// decode for receiving audio stream (hear)
						this.videoDecode = true;
					}
				}
				this.getAllDevices();
			});
			this.zoomClient.on('auto-play-audio-failed', async () => {
				console.log(
					'%c auto play failed, waiting for a user interaction',
					'color: #ff0000'
				);
				this.isMuted = true;
			});
			this.zoomClient.on('active-speaker', (payload) => {
				console.log('%c active-speaker', 'color: #00ff00', payload);
				// let { userId } = payload[0];
				// this.currentParticipantId = userId ?? this.currentParticipantId;
			});
			this.zoomClient.on('unmute-audio-consent', (payload) => {
				console.log('%c unmute-audio-consent', 'color: #00ff00', payload);
				console.log(payload);
				console.log('Host asked me to unmute');
			});
			this.zoomClient.on('audio-statistic-data-change', (payload) => {
				console.log('%c audio-statistic-data-change', 'color:#00ff00', payload);
			});

			this.zoomClient.on('device-change', async (payload) => {
				console.log('%c device-change', 'color: #00ff00', payload);
				this.getAllDevices();
			});
			this.zoomClient.on('video-statistic-data-change', (payload) => {
				console.log('%c video-statistic-data-change', 'color: #00ff00', payload);
			});
			this.zoomClient.on('peer-video-state-change', async (payload) => {
				console.log('%c peer-video-state-change', 'color: #00ff00', payload);
				let canvasId = this.isChromium
					? 'participant-canvas'
					: 'canvas-' + payload.userId;
				let participantCanvas = document.getElementById(canvasId);
				if (payload.action === 'Start') {
					await this.renderVideo();
					// this.zoomStream
					// 	.renderVideo(
					// 		participantCanvas,
					// 		payload.userId,
					// 		this.miniSize.width,
					// 		this.miniSize.height,
					// 		0,
					// 		0,
					// 		this.videoQuality
					// 	)
					// 	.catch(console.log);
				} else if (payload.action === 'Stop') {
					this.stopRenderVideo();
					this.zoomStream
						.stopRenderVideo(participantCanvas, payload.userId)
						.catch(console.log);
					await this.reRenderVideo();
				}
			});
			this.zoomClient.on('video-active-change', async (payload) => {
				console.log('%c video-active-change', 'color: #00ff00', payload);
				try {
					this.getAllActiveDevices();
					await this.refreshParticipants();
					if (payload.state === 'Active') {
						if (this.isChromium) {
							this.renderVideo();
						}
					} else {
						if (this.isChromium) {
							this.stopRenderVideo();
						}
					}
				} catch (error) {
					console.log(error);
				}
			});
			this.zoomClient.on('active-share-change', async (payload) => {
				console.log('%c active-share-change', 'color: #00ff00', payload);
				this.refreshParticipants();
				if (payload.state === 'Active') {
					let shareScreenElement = document.getElementById(
						LiveConst.RECEIVE_SHARING_SCREEN_ELEMENT
					);
					if (window.getComputedStyle(shareScreenElement).display !== 'none') {
						await this.startShareView(payload.userId);
					}
					// this.fitVideo();
				} 
					// await this.zoomStream
					// 	.stopShareView()
					// 	.then(console.log)
					// 	.catch(console.log);
					// this.fitVideo();
			});
			this.zoomClient.on('share-content-dimension-change', async (payload) => {
				console.log('share-content-dimension-change', payload);
				await this.refreshParticipants();
				let shareScreenElement = document.getElementById(
					LiveConst.SHARING_SCREEN_ELEMENT
				);
				let receiveScreenElement = document.getElementById(
					LiveConst.RECEIVE_SHARING_SCREEN_ELEMENT
				);
				let parentElement = this.isLiveScreenMinimum
					? this.miniSize
					: document
							.getElementsByClassName('shareScreen__bottom')[0]
							.getBoundingClientRect();
				let width = parentElement.width;
				let height = parentElement.height;
				if (payload.type == 'sended') {
					try {
						let scale
						this.streamWidth = payload.width * height / payload.height;
						this.streamHeight = height;
						if (this.streamWidth > width) {
							this.streamWidth = width;
							scale = width / payload.width 
							this.streamHeight = payload.height * scale
							receiveScreenElement.style.bottom = 33 + (height - this.streamHeight) / 2 + 'px';
							receiveScreenElement.style.marginLeft = this.isShareScreenExpandMode || this.mobileAndTabletCheck ? '0px' : '25px';
						}
						else {
							receiveScreenElement.style.bottom = '33px';
							receiveScreenElement.style.marginLeft = 25 + (width - this.streamWidth) / 2 + 'px';
						}
						this.updateCanvasStyleSize(shareScreenElement, this.streamWidth, this.streamHeight);
						// this.updateCanvasSize(shareScreenElement, width, height);

						// if (this.isLiveScreenMinimum) {
						// 	if (this.participants.length == 1) {
						// 		shareScreenElement.style.marginTop = `0px`;
						// 		shareScreenElement.style.borderTopRightRadius = `40px`;
						// 	} else if (this.participants.length > 1) {
						// 		let marginTop = (this.participants.length - 1) * this.miniSize.height;
						// 		shareScreenElement.style.marginTop = `${marginTop}px`;
						// 		shareScreenElement.style.borderTopRightRadius = `0px`;
						// 	}
						// }
						// //check if body update height or width
						// setTimeout(() => {
						// 	let checkWidth = parentElement.width;
						// 	let checkHeight = parentElement.height;
						// 	if (
						// 		shareScreenElement.width != checkWidth ||
						// 		shareScreenElement.height != checkHeight
						// 	) {
						// 		if (this.isChromium) {
						// 			this.updateCanvasSize(shareScreenElement, checkWidth, checkHeight);
						// 		} else {
						// 			this.updateCanvasStyleSize(
						// 				shareScreenElement,
						// 				checkWidth,
						// 				checkHeight
						// 			);
						// 		}
						// 		this.updateSharingView(checkWidth, checkHeight);
						// 	}
						// }, 250);
					} catch (error) {
						console.log(error);
					} finally {
						this.updateSharingView(shareScreenElement.width, shareScreenElement.height);
					}
				} else if (payload.type == 'received') {
					let scale
					this.streamWidth = payload.width * height / payload.height;
					this.streamHeight = height;
					if (this.streamWidth > width) {
						this.streamWidth = width;
						scale = width / payload.width 
						this.streamHeight = payload.height * scale
						receiveScreenElement.style.bottom = 33 + (height - this.streamHeight) / 2 + 'px';
						receiveScreenElement.style.marginLeft = this.isShareScreenExpandMode || this.mobileAndTabletCheck ? '0px' : '25px';
					}
					else {
						receiveScreenElement.style.bottom = '33px';
						receiveScreenElement.style.marginLeft = 25 + (width - this.streamWidth) / 2 + 'px';
					}
					this.updateCanvasStyleSize(receiveScreenElement, this.streamWidth, this.streamHeight);
				}
			});
			this.zoomClient.on('peer-share-state-change', async (payload) => {
				console.log('peer-share-state-change', payload);
				await this.refreshParticipants();
				let receiveScreenElement = document.getElementById(
					LiveConst.RECEIVE_SHARING_SCREEN_ELEMENT
				);
				// receiveScreenElement.classList = [this.sharingElementClass];

				if (payload.action == 'Start') {
					receiveScreenElement.style.display = 'block';

					// await this.startShareView(payload.userId);
				} else if (payload.action == 'Stop') {
					// await this.zoomStream
					// 	.stopShareView()
					// 	.then((...result) => {
					// 		console.log('stopShareView', result);
					// 	})
					// 	.catch(console.log);

					receiveScreenElement.style.display = 'none';
				}
			});
			this.zoomClient.on('connection-change', async (payload) => {
				console.log('%c connection-change', 'color: #00ff00', payload);
				if (payload.state === 'Closed') {
					this.$emit(
						this.duplicateUser ? 'on-duplicate-user' : 'host-close-share-screen',
						{
							plainModal: false,
							selectiveModal: false,
						}
					);
				}
				// You can refresh the participants when
			});
			this.zoomClient.on('main-session-user-updated', async (payload) => {
				console.log('%c main-session-user-updated', 'color: #00ff00', payload);
				// You can refresh the participants when
			});
			this.zoomClient.on('share-audio-change', (payload) => {
				if (payload.state == 'on') {
					this.turnOffStreamAudio()
					this.mute()
				}
			})
		},
		async onRemovedUser() {
			console.log('%c user-removed', 'color: #ff0000');
			// You can refresh the participants when
			if (!await this.zoomClient.isHost()) {
				this.$emit('close-live-screen', {
					plainModal: false,
					selectiveModal: false,
				});
			}
			else {
				this.$emit('on-switch-live-screen-mode', !this.isLiveScreenMinimum);
				await this.refreshParticipants();
			}
			this.updateDuration();
		},
		/**
		 * Render zoom video
		 */
		async startVideo() {
			console.log('%c START startVideo', 'color:blue');
			try {
				if (this.mobileView || this.isAndroid || this.isChromium) {
					// if desktop Chrome or Edge (Chromium) with SharedArrayBuffer not enabled
					console.log('%c startVideo with SharedArrayBuffer', 'color:blue');
					let currentUserId = await this.zoomClient.getCurrentUserInfo()?.userId;
					await this.zoomStream.startVideo({
						videoElement: document.getElementById(`canvas-${currentUserId}`),
					});
				} else {
					// all other browsers and desktop Chrome or Edge (Chromium) with SharedArrayBuffer enabled
					this.activeCamera =
						this.getDevice(this.activeCamera)?.deviceId ?? this.cameraList[0]?.deviceId;
					await this.zoomStream
						.startVideo({
							cameraId: this.activeCamera,
						})
						.catch(console.log);
					this.isCapturingVideo = true;
				}
			} catch (e) {
				this.isCapturingVideo = false;
				console.log(e);
			}
			console.log('%c END startVideo', 'color:blue');
		},
		/**
		 * Stop zoom video
		 */
		async stopVideo() {
			this.isCapturingVideo = false;
			await this.zoomStream.stopVideo();
		},
		/**
		 * Start recordingVideo
		 */
		async startRecordingVideo() {
			console.log('%c startRecordingVideo', 'color:blue');
			this.cloudRecording.startCloudRecording();
			this.isRecordingScreen = true;
			console.log('%c startRecordingVideo DONE', 'color:blue');
		},
		/**
		 * Stop recordingVideo
		 */
		async stopRecordingVideo() {
			console.log('%c stopRecordingVideo', 'color:blue');
			this.isRecordingScreen = false;
			this.cloudRecording.stopCloudRecording();
			console.log('%c stopRecordingVideo DONE', 'color:blue');
		},

		cancelStopRecordingVideo() {
			console.log('%c cancelStopRecordingVideo', 'color: green');
		},

		onStopRecordingClick() {
			this.$bvModal.show('stop-record-confirm-selective-modal');
		},

		/**
		 * Start shareScreen
		 */
		async startShareScreen() {
			console.log('%c startShareScreen');
			var sharingScreenElement = document.getElementById(
				LiveConst.SHARING_SCREEN_ELEMENT
			);
			try {
				await this.zoomStream.startShareScreen(sharingScreenElement);
				// this.fitVideo();
				if (this.participants.length == 1) {
					socketClient.send('request_comode', {
						room: this.projectInfo.id,
						data: {
							mode: 'share',
						},
					});
				}
				this.isShareScreen = true;
			} catch (e) {
				this.isShareScreen = false;
				console.log(e);
			}

			// setTimeout(() => {
			// 	if (this.participants.length == 1) {
			// 		socketClient.send('request_comode', {
			// 			room: this.projectInfo.id,
			// 			data: {
			// 				mode: 'close',
			// 			},
			// 		});
			// 		this.$emit('timeout-share');
			// 	}
			// }, 30000);
			if (this.isSafari) this.fitVideo();
			console.log('%c startShareScreen DONE');
		},
		/**
		 *  Stop shareScreen
		 */
		async stopShareScreen() {
			try {
				await this.zoomStream.stopShareScreen();
				await this.zoomStream.stopShareView();
				await this.refreshParticipants();
				this.isShareScreen = false;
				// this.fitVideo(200);
			} catch (e) {
				this.isShareScreen = true;
				console.log(e);
			}
		},
		/**
		 * Start streaming audio
		 */
		async startAudio() {
			// if desktop Safari https://stackoverflow.com/a/42189492/6592510
			try {
				console.log('%c START startAudio', 'color:blue');
				if (this.isSafari) {
					// desktop Safari, check if desktop Safari audio has been initialized
					if (this.audioEncode && this.audioDecode) {
						// desktop Safari audio has been initialized, continue to start audio
						await this.zoomStream.startAudio();
						this.isMuted = false;
					} else {
						// desktop Safari audio has not been initialized, retry or handle error
						console.log('safari audio has not finished initializing');
						this.isMuted = true;
					}
				} else {
					// not desktop Safari, continue to start audio
					await this.zoomStream.startAudio();
					this.isMuted = false;
				}
			} catch (e) {
				this.isMuted = true;
			}
		},
		/**
		 * Stop streaming audio
		 */
		stopAudio() {
			this.zoomStream.stopAudio();
		},
		/**
		 * mute audio
		 */
		async mute() {
			try {
				await this.zoomStream.muteAudio();
				this.isMuted = true;
			} catch (e) {
				this.isMuted = false;
				await this.zoomStream.startAudio();
				await this.zoomStream.muteAudio();
				this.isMuted = true;
			}
		},
		/**
		 * Update Duration
		 */
		async updateDuration() {
			let curr = new Date();
			let durations = (curr.getTime() - this.timeConnected.getTime()) / 1000 / 60;
			await ZoomService.updateDuration(this.projectId, {
				zoom_session_duration: durations,
			});
			this.isUpdated = true;
		},
		/**
		 * unmute audio
		 */
		async unMute() {
			try {
				await this.zoomStream.unmuteAudio();
				this.isMuted = false;
			} catch (e) {
				this.isMuted = true;
				await this.zoomStream.startAudio();
				await this.zoomStream.unmuteAudio();
				this.isMuted = false;
			}
		},
		/**
		 * Mute share audio
		 */
		async turnOffStreamAudio() {
			try {
				await this.zoomStream.muteShareAudio();
			} catch (e) {
				await this.zoomStream.unmuteShareAudio();
				await this.zoomStream.muteShareAudio();
			}
		},
		/**
		 *  Render zoom video
		 */
		async renderVideo() {
			console.log('%c START renderVideo', 'color: #00f');
			var canvas = document.getElementById(LiveConst.PARTICIPANT_CANVAS);
			console.log({ canvas });
			if (this.isChromium && !this.mobileView) {
				if (this.participants.length == 1) {
					if (this.participants[0].bVideoOn) {
						this.zoomStream
							.renderVideo(
								canvas,
								this.participants[0].userId,
								this.miniSize.width,
								this.miniSize.height,
								0,
								0,
								this.videoQuality
							)
							.catch(console.log);
					}
				} else {
					this.participants.forEach((participant, index) => {
						if (participant.bVideoOn) {
							this.zoomStream
								.renderVideo(
									canvas,
									participant.userId,
									this.miniSize.width,
									this.miniSize.height,
									75,
									index * this.miniSize.height,
									this.videoQuality
								)
								.catch(console.log);
						}
					});
				}
			} else {
				this.participants.forEach(async (participant) => {
					if (participant.bVideoOn) {
						let participantCanvas = document.getElementById(
							'canvas-' + participant.userId
						);
						this.zoomStream
							.renderVideo(
								participantCanvas,
								participant.userId,
								this.miniSize.width,
								this.miniSize.height,
								0,
								0,
								this.videoQuality
							)
							.catch(console.log);
					}
				});
			}
			// this.fitVideo();
			console.log('%c renderVideo DONE', 'color: #00f');
		},
		/**
		 * stop zoom video
		 */
		async stopRenderVideo() {
			if (!this.zoomStream) {
				return;
			}
			console.log('%c START stopRenderVideo', 'color: #00f');
			let className = this.isLiveScreenMinimum ? 'canvas-minimum' : 'canvas-fullsize';
			let canvas = document.getElementsByClassName(className)[0];
			for (let participant of this.participants) {
				if (!participant.bVideoOn) {
					let participantCanvas = this.isChromium
						? canvas
						: document.getElementById('canvas-' + participant.userId);
					await this.zoomStream
						.stopRenderVideo(participantCanvas, participant.userId)
						.catch(console.log);
				}
			}
			console.log('%c stopRenderVideo DONE', 'color: #00f');
		},
		async reRenderVideo() {
			try {
				if (!this.zoomStream) {
					return;
				}
				if (this.isChromiumAndWithoutSharedArrayBuffer) {
					await this.stopVideo();
					await this.startVideo();
				} else {
					console.log('%c START rerenderVideo', 'color: #00f');
					var canvas = document.getElementById(LiveConst.PARTICIPANT_CANVAS);
					console.log({ canvas });
					if (!canvas && this.isChromium) {
						return;
					}
						if (this.isChromium) {
							if (this.participants.length == 1) {
								this.adjustRenderedVideoPosition(
									canvas,
									this.participants[0].userId,
									this.miniSize.width,
									this.miniSize.height,
									75,
									0
								);
							}
							else {
								this.participants.forEach((participant, index) => {
									if (participant.bVideoOn) {
										this.adjustRenderedVideoPosition(
											canvas,
											participant.userId,
											this.miniSize.width,
											this.miniSize.height,
											index == 0 ? 0 : 75,
											index * this.miniSize.height
										);
									}
								});
							}
						}
					}
				console.log('%c reRenderVideo DONE', 'color: #00f');
			} catch (e) {
				console.log(e);
			}
		},
		/**
		 * update zoom video canvas size
		 */
		async updateVideoCanvas(canvas, width, height) {
			console.log('%c START UpdateVideoCanvas', 'color: #00f', canvas, width, height);
			await this.zoomStream
				.updateVideoCanvasDimension(canvas, width, height)
				.catch(console.log);
			console.log('%c DONE UpdateVideoCanvas ', 'color: #00f');
		},
		async updateSharingView(width, height) {
			console.log('%c START UpdateSharingView', 'color: #00f', width, height);
			await this.zoomStream
				.updateSharingCanvasDimension(width, height)
				.catch(console.log);
		},
		/*
		 * Update zoom video canvas position
		 */
		adjustRenderedVideoPosition(canvas, userId, width, height, x, y) {
			console.log(
				'%c START adjustRenderedVideoPosition',
				'color: #00f',
				canvas,
				userId,
				width,
				height,
				x,
				y
			);
			this.zoomStream
				.adjustRenderedVideoPosition(canvas, userId, width, height, x, y)
				.catch((e) => {
					console.log(e);
				});
			console.log('%c DONE adjustRenderedVideoPosition ', 'color: #00f');
		},
		async startShareView(userId) {
			console.log('%c START startShareView', 'color: #00f', userId);
			let shareScreenElement = document.getElementById(
				LiveConst.RECEIVE_SHARING_SCREEN_ELEMENT
			);
			console.log(shareScreenElement);
			this.$store.commit('setIsAppProcessing', false);
			try {
				await this.zoomStream.startShareView(shareScreenElement, userId);
			} catch (e) {
				console.log(e);
			}
			shareScreenElement.classList = ['shareScreen__element'];
		},
		/**
		 * Fit the video to the screen
		 * @param {Number} Time in ms to wait before fitting the video
		 */
		async fitVideo(time = 0) {
			console.log('FitVideo');
			setTimeout(async () => {
				if (!this.zoomStream) {
					return;
				}
				let className = 'canvas-minimum'
				let canvas = document.getElementsByClassName(className)[0];
				// let canvasFullSizeMiniview = document.getElementsByClassName(
				// 	'canvas-fullsize-miniview'
				// );
				let shareScreenElement = document.getElementById(
					LiveConst.SHARING_SCREEN_ELEMENT
				);
				let receiveScreenElement = document.getElementById(
					LiveConst.RECEIVE_SHARING_SCREEN_ELEMENT
				);
				console.log({
					canvas,
					shareScreenElement,
					receiveScreenElement,
				});
				if (this.isLiveScreenMinimum) {
					let parentElement = document
						.getElementById('participants-container')
						.getBoundingClientRect();
					if (this.isChromium && !this.mobileView) {
						this.updateCanvasSize(canvas, parentElement.width, parentElement.height);
					} else {
						for (let participant of this.participants) {
							let participantCanvas = document.getElementById(
								'canvas-' + participant.userId
							);
							this.updateCanvasStyleSize(
								participantCanvas,
								this.miniSize.width,
								this.miniSize.height
							);
							// await this.updateVideoCanvas(
							// 	participantCanvas,
							// 	this.miniSize.width,
							// 	this.miniSize.height
							// );
						}
					}
					await this.updateVideoCanvas(
						canvas,
						parentElement.width + 56,
						parentElement.height
					);
					if (this.isSharingView) {
						if (this.participants.length == 1) {
							shareScreenElement.style.marginTop = `0px`;
							shareScreenElement.style.borderTopRightRadius = `40px`;
						} else if (this.participants.length > 1) {
							let marginTop = (this.participants.length - 1) * this.miniSize.height;
							shareScreenElement.style.marginTop = `${marginTop}px`;
							shareScreenElement.style.borderTopRightRadius = `0px`;
							receiveScreenElement.style.marginTop = `0px`;
							receiveScreenElement.style.borderTopRightRadius = `40px`;
						}
						this.updateCanvasStyleSize(
							shareScreenElement,
							this.miniSize.width,
							this.miniSize.height
						);
						await this.updateSharingView(this.miniSize.width, this.miniSize.height);
						this.updateCanvasStyleSize(
							receiveScreenElement,
							this.miniSize.width,
							this.miniSize.height
						);
					}
					if (this.isChromium) {
						this.reRenderVideo();
					}
				} else {
					let parentElement = document
						.getElementById('participants-container')
						.getBoundingClientRect();
					if (this.isChromium && !this.mobileView) {
						this.updateCanvasSize(canvas, parentElement.width+56, parentElement.height);
					}
					// else {
					// 	if (!this.mobileAndTabletCheck && !this.isSafari) {
					// 		console.log("ok")
					// 		this.participants.forEach((participant) => {
					// 			let canvasContainer = document.getElementById(
					// 				participant.userId
					// 			).getBoundingClientRect();
					// 			let width = canvasContainer.width;
					// 			let height = canvasContainer.height;
					// 			let canvasParticipant = document.getElementById(
					// 				'canvas-' + participant.userId
					// 			);
					// 			this.updateCanvasSize(canvasParticipant, width, height);
					// 		})
					// 	}
					// }
					await this.updateVideoCanvas(
						canvas,
						parentElement.width+56,
						parentElement.height
					);
					parentElement = document
						.getElementsByClassName('shareScreen__container')[0]
						.getBoundingClientRect();
					let liveScreenBody = document
						.getElementsByClassName('shareScreen__bottom')[0]
						.getBoundingClientRect();
					console.log({
						parentElement,
						liveScreenBody,
					});
					let bodyWidth = liveScreenBody.width;
					let bodyHeight = liveScreenBody.height;
					if (this.isSharingView && bodyWidth && bodyHeight) {
						if (this.isShareScreen && !this.mobileAndTabletCheck && !this.isChromium) {
							shareScreenElement.style.borderTopRightRadius = '0px';
							let tempHeight = this.streamHeight
							let tempWidth = this.streamWidth
							let scale = bodyHeight / this.streamHeight
							this.streamHeight = bodyHeight;
							this.streamWidth = this.streamWidth * scale
							if (this.streamWidth > bodyWidth) {
								this.streamWidth = bodyWidth;
								scale = bodyWidth / tempWidth 
								this.streamHeight = tempHeight * scale
								shareScreenElement.style.bottom = 33 + (bodyHeight - this.streamHeight) / 2 + 'px';
								shareScreenElement.style.marginLeft = this.mobileView || this.isShareScreenExpandMode ? '0px' : '25px';
							}
							else {
								shareScreenElement.style.bottom = '33px';
								shareScreenElement.style.marginLeft = this.mobileView || this.isShareScreenExpandMode ? '0px' : 25 + (bodyWidth - this.streamWidth) / 2 + 'px';
							}
							this.updateCanvasStyleSize(shareScreenElement, this.streamWidth, this.streamHeight);
						}
						else if (this.isShareScreen) {
							shareScreenElement.style.borderTopRightRadius = '0px';
							this.updateCanvasStyleSize(shareScreenElement, bodyWidth, bodyHeight);
							await this.updateSharingView(bodyWidth, bodyHeight);
						}
						else {
							receiveScreenElement.style.borderTopRightRadius = '0px';
							let tempHeight = this.streamHeight
							let tempWidth = this.streamWidth
							let scale = bodyHeight / this.streamHeight
							this.streamHeight = bodyHeight;
							this.streamWidth = this.streamWidth * scale
							if (this.streamWidth > bodyWidth) {
								this.streamWidth = bodyWidth;
								scale = bodyWidth / tempWidth 
								this.streamHeight = tempHeight * scale
								receiveScreenElement.style.bottom = 33 + (bodyHeight - this.streamHeight) / 2 + 'px';
								receiveScreenElement.style.marginLeft = this.isShareScreenExpandMode || this.mobileAndTabletCheck ? '0px' : '25px';
							}
							else {
								receiveScreenElement.style.bottom = '33px';
								receiveScreenElement.style.marginLeft = 25 + (bodyWidth - this.streamWidth) / 2 + 'px';
							}
							this.updateCanvasStyleSize(receiveScreenElement, this.streamWidth, this.streamHeight);
						}
					}
					if (this.isChromium) {
						this.reRenderVideo();
					}
				}
			}, time);
		},
		async getAllDevices() {
			[
				this.cameraList,
				this.micList,
				this.speakerList,
				this.activeCamera,
				this.activeMic,
				this.activeSpeaker,
				this.devices,
			] = await Promise.all([
				this.zoomStream.getCameraList(),
				this.zoomStream.getMicList(),
				this.zoomStream.getSpeakerList(),
				this.zoomStream.getActiveCamera(),
				this.zoomStream.getActiveMicrophone(),
				this.zoomStream.getActiveSpeaker(),
				ZoomVideo.getDevices(),
			]);
		},
		async getAllActiveDevices() {
			[this.activeCamera, this.activeMic, this.activeSpeaker] = await Promise.all([
				this.zoomStream.getActiveCamera(),
				this.zoomStream.getActiveMicrophone(),
				this.zoomStream.getActiveSpeaker(),
			]);
		},
		generator: function* () {
			//initialize the generator
			yield () => {
				// fit canvas to the screen
				window.addEventListener('resize', () => {
					this.fitVideo();
				});
				window.addEventListener('beforeunload', () => {
					this.leaveSession(false);
				});
			};
			//join session
			yield async () => {
				try {
					let {
						zoom_session_name,
						zoom_session_password,
						signature,
						register_name,
						role,
					} = await this.createSession();
					this.role = role;
					console.log({
						zoom_session_name,
						zoom_session_password,
						signature,
						register_name,
						role,
					});
					// join a Zoom client with the session information (signature, session name, session password)
					await this.zoomClient
						.join(zoom_session_name, signature, register_name, zoom_session_password)
						.then(async () => {
							this.isSeesionJoined = true;
							console.log('%c Join session successfully', 'color: green');
						})
						.catch(() => {
							this.isSeesionJoined = false;
							console.log('%c Join session faile', 'color: red');
						});
					this.zoomStream = await this.zoomClient.getMediaStream();
					this.listenZoomEvents();
					[this.currentUser, this.participants] = await Promise.all([
						this.zoomClient.getCurrentUserInfo(),
						this.zoomClient.getAllUser(),
					]);
					// this.participants = [this.currentUser];
					let duplicateParticipant = this.participants.filter(
						(participant) => participant.displayName == register_name
					);
					if (duplicateParticipant.length > 1) {
						this.duplicateUser = true;
						this.leaveSession(false);
						return;
					}
				} catch (e) {
					console.log(e);
				}
			};
			// open camera and microphone
			yield async () => {
				// Promise.all([
				// 	// this.startVideo(),
				// 	async () => {
				// 		let activeShareUserId =
				// 			await this.zoomStream.getActiveShareUserId();
				// 		if (activeShareUserId) {
				// 			this.startShareView(activeShareUserId);
				// 		}
				// 	},
				// ]);
				if (this.role == 1) {
					if (this.isChromium) {
						await this.startShareScreen();
					}
					await this.$store.commit('setIsAppProcessing', false);
					await this.$bvModal.show('share-screen-alert-modal');
				} else {
					let activeShareUserId = await this.zoomStream.getActiveShareUserId();
					if (activeShareUserId) {
						this.startShareView(activeShareUserId);
					} else {
						// this.leaveSession(false);
						this.$store.commit('setIsAppProcessing', false);
						this.$emit('not-sharing-screen');
					}
				}
				this.mute()
			};
		},
		/**
		 * Update canvas size
		 * @param {CanvasElement} canvas
		 * @param {Number} width
		 * @param {Number} height
		 */
		updateCanvasSize(canvas, width, height) {
			console.log('updateCanvasSize', canvas, width, height);
			try {
				canvas.width = width;
				canvas.height = height;
			} catch (e) {
				console.log(e);
			}
		},
		/**
		 * Update canvas size by style
		 * @param {CanvasElement} canvas
		 * @param {Number} width
		 * @param {Number} height
		 */
		updateCanvasStyleSize(canvas, width, height) {
			console.log('updateCanvasStyleSize', canvas, width, height);
			try {
				canvas.style.width = `${width}px`;
				canvas.style.height = `${height}px`;
			} catch (e) {
				console.log(e);
			}
		},
		getDeviceList(name) {
			return this[name] || [];
		},
		getDevice(id) {
			return this.devices.find((device) => device.deviceId == id);
		},
		async switchDevice(id) {
			console.log('switchDevice', id);
			const { deviceId, kind } = this.getDevice(id);
			console.log(this.getDevice(id));
			switch (kind) {
				case 'videoinput':
					await this.zoomStream
						.switchCamera(deviceId)
						.then(() => {
							this.activeCamera = deviceId;
						})
						.catch(console.log);
					this.getAllDevices();
					break;
				case 'audiooutput':
					await this.zoomStream
						.switchSpeaker(deviceId)
						.then(() => {
							this.activeSpeaker = deviceId;
						})
						.catch(console.log);
					this.getAllDevices();
					break;
				case 'audioinput':
					await this.zoomStream
						.switchMicrophone(deviceId)
						.then(() => {
							this.activeSpeaker = deviceId;
						})
						.catch(console.log);
					this.activeMicrophone = deviceId;
					this.getAllDevices();
					break;
				default:
					break;
			}
		},
		/**
		 *  Retry some functions
		 * @param {function} callback: function to call
		 * @param {number} tryTime :times to try
		 * @returns null
		 */
		retry(callback, tryTime = 3) {
			return new Promise((resolve, reject) => {
				let tryCount = 0;
				const tryFunc = () => {
					try {
						callback();
						resolve();
					} catch (e) {
						if (tryCount < tryTime) {
							tryCount++;
							setTimeout(tryFunc, 1000);
						} else {
							reject(e);
						}
					}
				};
				tryFunc();
			});
		},
		async updateUserStatus() {
			try {
				this.currentUser = (await this.zoomClient?.getCurrentUserInfo()) ?? {};
				this.isShareScreen = this.currentUser?.sharerOn ?? false;
				this.isCapturingVideo = this.currentUser?.bVideoOn ?? false;
			} catch (e) {
				console.log(e);
			}
		},
		onIgnoreAlert() {
			if (!this.mobileAndTabletCheck && !this.isChromium) {
				this.startShareScreen()
			}
		},
		onShowShareScreen() {
			this.$emit('show-share-screen');
		}
	},

	async mounted() {
		console.log('LiveScreen mounted', this.userId);
		if (this.preview) {
			this.leaveSession(false);
			this.$emit('close-live-screen', {
				plainModal: false,
				selectiveModal: false,
			});
			return;
		}
		this.timeConnected = new Date();
		this.generator = await this.generator();
		await this.generator.next().value();
		if (this.userId && !this.zoomStream) {
			this.generator.next().value();
		}
		if (!this.mobileAndTabletCheck) {
			this.drawCircleCanvas('layout-canvas-0', 105, 37, true);
			this.drawCircleCanvas('layout-canvas-1', 46, 37, true);
		}
		let receivedCanvas = document.getElementById('receive-sharing-screen-element');
		this.pinchZoom(receivedCanvas)
	},
	created() {
		this.zoomClient = ZoomVideo.createClient();
		this.zoomClient.init(LiveConst.LANGUAGE, LiveConst.DEPENDENT_ASSETS);
		this.cloudRecording = this.zoomClient.getRecordingClient();
		this.updateUserStatus();
		ZoomVideo.getDevices()
			.then((devices) => {
				this.devices = devices;
				console.log({ devices });
			})
			.catch((e) => {
				console.log('get devices error!', e);
			});
	},
	beforeDestroy() {
		console.log('LiveScreen beforeDestroy');
		this.zoomClient.off('user-removed', this.onRemovedUser)
		this.menuBarItems[2].name = 'ShowVideo-active'
		this.leaveSession(this.duplicateUser || !this.role ? false : true);
		socketClient.send('request_comode', {
			room: this.projectInfo.id,
			data: {
				mode: 'close',
			},
		});
	},
};
