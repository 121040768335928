var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"live-screen",class:{
		'live-screen-container__minimum-mode': _vm.isLiveScreenMinimum,
		'live-screen-pc': !_vm.mobileAndTabletCheck
	},attrs:{"id":"zmmtg-root"}},[_c('ConfirmModal',{attrs:{"selectiveModalId":_vm.recordConfirmModalId,"selectiveTitle":_vm.recordSelectiveTitle,"selectiveContents":_vm.recordSelectiveContents,"yesButtonLabel":"了 解","noButtonLabel":"退 出"},on:{"on-confirm-yes":_vm.startRecordingVideo,"on-confirm-no":_vm.leaveSession}}),_c('ConfirmModal',{attrs:{"selectiveModalId":_vm.stopRecordConfirmModalId,"selectiveTitle":_vm.stopRecordSelectiveTitle,"selectiveContents":_vm.stopRecordSelectiveContents,"yesButtonLabel":"停 止","noButtonLabel":"キャンセル"},on:{"on-confirm-yes":_vm.stopRecordingVideo,"on-confirm-no":_vm.cancelStopRecordingVideo}}),[(_vm.isChromium)?_c('video',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShareScreen),expression:"isShareScreen"}],class:_vm.sharingElementClass,attrs:{"id":"sharing-screen-element","width":_vm.miniSize.width,"height":_vm.miniSize.height}}):_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShareScreen),expression:"isShareScreen"}],class:_vm.sharingElementClass,attrs:{"width":_vm.miniSize.width,"height":_vm.miniSize.height,"id":"sharing-screen-element"}}),_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSharingView && !_vm.isShareScreen),expression:"isSharingView && !isShareScreen"}],class:_vm.sharingElementClass,attrs:{"id":"receive-sharing-screen-element","width":_vm.miniSize.width,"height":_vm.miniSize.height}}),(_vm.isChromium && !_vm.mobileAndTabletCheck)?_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(_vm.participants && _vm.participants.length > 0 && !_vm.mobileView),expression:"participants && participants.length > 0 && !mobileView"}],class:{
				'canvas-minimum': _vm.isLiveScreenMinimum,
				'canvas-fullsize': !_vm.isLiveScreenMinimum,
			},style:({
				left: _vm.isLiveScreenMinimum ? '-28px' : '20px',
			}),attrs:{"width":_vm.miniSize.width,"height":_vm.miniSize.height,"id":"participant-canvas"}}):_vm._e(),(!_vm.mobileAndTabletCheck)?_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(_vm.participants && _vm.participants.length > 0 && _vm.isLiveScreenMinimum && !_vm.mobileView),expression:"participants && participants.length > 0 && isLiveScreenMinimum && !mobileView"}],staticClass:"layout-canvas",style:({
				top: '9px'
			}),attrs:{"width":152,"height":74,"id":"layout-canvas-0"}}):_vm._e(),(!_vm.mobileAndTabletCheck)?_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(_vm.participants && _vm.participants.length > 1 && _vm.isLiveScreenMinimum && !_vm.mobileView),expression:"participants && participants.length > 1 && isLiveScreenMinimum && !mobileView"}],staticClass:"layout-canvas",style:({
				top: _vm.miniSize.height + 9 + 'px',
			}),attrs:{"width":152,"height":74,"id":"layout-canvas-1"}}):_vm._e(),(!_vm.mobileAndTabletCheck)?_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(_vm.participants && _vm.participants.length > 1 && !_vm.isLiveScreenMinimum),expression:"participants && participants.length > 1 && !isLiveScreenMinimum"}],staticClass:"layout-canvas",style:({
				top: '20px',
				right: '20px'
			}),attrs:{"width":121,"height":68,"id":"layout-canvas-full"}}):_vm._e(),(!_vm.isChromium && !_vm.mobileAndTabletCheck)?_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(_vm.participants && _vm.participants.length > 1 && _vm.participants[1]?.userId && !_vm.mobileView),expression:"participants && participants.length > 1 && participants[1]?.userId && !mobileView"}],staticClass:"canvas-participant",class:{
				'canvas-fullsize': !_vm.isLiveScreenMinimum,
			},style:({
				borderTopRightRadius: _vm.isLiveScreenMinimum ? '10px' : '0px',
				borderTopLeftRadius: _vm.isLiveScreenMinimum ? '10px' : '0px',
				top: _vm.isLiveScreenMinimum ? '9px' : '88px',
				right: _vm.isLiveScreenMinimum ? '-15px' : '',
				left: _vm.isLiveScreenMinimum ? '' : '20px',
			}),attrs:{"id":'canvas-' + _vm.participants[1]?.userId,"width":_vm.miniSize.width,"height":_vm.miniSize.height}}):_vm._e(),(!_vm.isChromium && !_vm.mobileAndTabletCheck)?_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(_vm.participants && _vm.participants.length > 0 && _vm.participants[0]?.userId && !_vm.mobileView),expression:"participants && participants.length > 0 && participants[0]?.userId && !mobileView"}],staticClass:"canvas-participant",class:{
				'canvas-fullsize': !_vm.isLiveScreenMinimum,
			},style:({
				borderTopRightRadius:
					_vm.isLiveScreenMinimum && _vm.participants.length <= 1 ? '10px' : '0px',
				borderTopLeftRadius:
					_vm.isLiveScreenMinimum && _vm.participants.length <= 1 ? '10px' : '0px',
				borderBottomLeftRadius:
					_vm.isLiveScreenMinimum && _vm.participants.length > 1 ? '10px' : '0px',
				borderBottomRightRadius:
					_vm.isLiveScreenMinimum && _vm.participants.length > 1 ? '10px' : '0px',
				top: 
					_vm.isLiveScreenMinimum && _vm.participants.length <= 1 ? '9px' :
					_vm.isLiveScreenMinimum && _vm.participants.length > 1 ?   '83px' :
					!_vm.isLiveScreenMinimum && _vm.participants.length <= 1 ? ''
					: '20px',
				right:
					_vm.isLiveScreenMinimum && _vm.participants.length <= 1 ? '-15px' :
					_vm.isLiveScreenMinimum && _vm.participants.length > 1 ? '41px'
					:'20px'
			}),attrs:{"id":'canvas-' + _vm.participants[0]?.userId,"width":_vm.isLiveScreenMinimum || _vm.mobileAndTabletCheck ? _vm.miniSize.width : 120,"height":_vm.isLiveScreenMinimum || _vm.mobileAndTabletCheck ? _vm.miniSize.height : 68}}):_vm._e(),(_vm.mobileAndTabletCheck)?_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(_vm.participants && _vm.participants.length > 1 && _vm.participants[1]?.userId),expression:"participants && participants.length > 1 && participants[1]?.userId"}],staticClass:"canvas-participant",class:{
				'canvas-fullsize':
					!_vm.isLiveScreenMinimum
			},style:({
				borderTopRightRadius: _vm.isLiveScreenMinimum ? '10px' : '0px',
				borderTopLeftRadius: _vm.isLiveScreenMinimum ? '10px' : '0px',
				right: _vm.isSharingView && !_vm.isLiveScreenMinimum ? '140px' : 'unset',
				bottom: _vm.participants.length > 1 && !_vm.isLiveScreenMinimum? '56px' : 'unset'
			}),attrs:{"id":'canvas-' + _vm.participants[1]?.userId,"width":_vm.miniSize.width,"height":_vm.miniSize.height}}):_vm._e(),(_vm.mobileAndTabletCheck && !_vm.isAndroid)?_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(_vm.participants && _vm.participants.length > 0 && _vm.participants[0]?.userId),expression:"participants && participants.length > 0 && participants[0]?.userId"}],staticClass:"canvas-participant",class:{
				'canvas-fullsize':
					!_vm.isLiveScreenMinimum,
			},style:({
				borderTopRightRadius:
					_vm.isLiveScreenMinimum && _vm.participants.length <= 1 ? '10px' : '0px',
				borderTopLeftRadius:
					_vm.isLiveScreenMinimum && _vm.participants.length <= 1 ? '10px' : '0px',
				borderBottomLeftRadius:
					_vm.isLiveScreenMinimum && _vm.participants.length > 1 ? '10px' : '0px',
				borderBottomRightRadius:
					_vm.isLiveScreenMinimum && _vm.participants.length > 1 ? '10px' : '0px',
			}),attrs:{"id":'canvas-' + _vm.participants[0]?.userId,"width":_vm.miniSize.width,"height":_vm.miniSize.height}}):(_vm.mobileAndTabletCheck)?_c('video',{directives:[{name:"show",rawName:"v-show",value:(_vm.participants && _vm.participants.length > 0 && _vm.participants[0]?.userId),expression:"participants && participants.length > 0 && participants[0]?.userId"}],staticClass:"canvas-participant",class:{
				'canvas-fullsize':
					!_vm.isLiveScreenMinimum,
			},style:({
				borderTopRightRadius:
					_vm.isLiveScreenMinimum && _vm.participants.length <= 1 ? '10px' : '0px',
				borderTopLeftRadius:
					_vm.isLiveScreenMinimum && _vm.participants.length <= 1 ? '10px' : '0px',
				borderBottomLeftRadius:
					_vm.isLiveScreenMinimum && _vm.participants.length > 1 ? '10px' : '0px',
				borderBottomRightRadius:
					_vm.isLiveScreenMinimum && _vm.participants.length > 1 ? '10px' : '0px',
			}),attrs:{"id":'canvas-' + _vm.participants[0]?.userId,"width":_vm.miniSize.width,"height":_vm.miniSize.height}}):_vm._e(),(_vm.isLiveScreenMinimum)?_c('div',{staticClass:"live-screen__minimum_size"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mobileView || _vm.mobileAndTabletCheck),expression:"!mobileView || mobileAndTabletCheck"}],staticClass:"participants-show-container",attrs:{"id":"participants-container"}},_vm._l((_vm.reversedParticipants),function(participant,parIndex){return _c('div',{key:parIndex,staticClass:"participant",class:{
						'participant-video-off': !participant.bVideoOn && !_vm.isShareScreen,
						'participant-video-on': participant.bVideoOn || _vm.isShareScreen,
						'participant-pc': !_vm.mobileAndTabletCheck,
					},attrs:{"track-by":"id","id":participant.userId}},[_c('label',{staticClass:"participant-name",class:{
							'participant-name-label-video-off':
								!participant.bVideoOn && !_vm.isShareScreen,
							'first-participant-video-on': participant.bVideoOn && parIndex === 0,
							'second-participant-video-on': participant.bVideoOn && parIndex === 1,
						}},[_c('span',{staticClass:"participant-name__text"},[_vm._v(" "+_vm._s(_vm._f("wordLength")(participant.displayName))+" ")]),(participant.muted)?_c('img',{staticClass:"muted-icon",attrs:{"src":require("@/assets/LiveScreen/Mute-active.svg")}}):_vm._e()])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.participants && _vm.participants.length > 0),expression:"participants && participants.length > 0"}],staticClass:"menu-bar-container"},[_c('div',{staticClass:"menu-bar-items"},[_vm._l((_vm.menuBarItems),function(menuBarItem,menuIndex){return _c('button',{key:menuIndex,staticClass:"menu-bar-button",class:{
							'menu-bar-button--display-none':
								(_vm.mobileView &&
									['Setting', 'ShareScreen'].includes(menuBarItem.name)) ||
								(!_vm.mobileAndTabletCheck &&
									_vm.mobileView &&
									menuBarItem.name == 'UpExpand') ||
								menuBarItem.name === 'RecordScreen',
							'menu-bar-button--hidden': !_vm.isShowMenuBar && !_vm.mobileView,
							'menu-bar-button--setting':
								menuBarItem.name === 'Setting' && _vm.isMenuExpand,
						},attrs:{"disabled":_vm.mobileAndTabletCheck &&
							menuBarItem.name === 'UpExpand' &&
							_vm.participants.length <= 1},on:{"click":function($event){return _vm.onMinimumMenuBarItemClick(menuBarItem.id)}}},[_c('img',{staticClass:"menu-bar-icon",class:{
								'expand-icon': menuBarItem.name === 'UpExpand',
								'leave-icon': menuBarItem.name === 'Leave',
								'is-active': menuBarItem.isActive,
							},attrs:{"src":require(`@/assets/LiveScreen/${menuBarItem.name}.svg`)}})])}),_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mobileView),expression:"!mobileView"}],staticClass:"menu-bar-button",on:{"click":_vm.onToggleMenubar}},[_c('img',{staticClass:"menu-bar-icon option-icon",attrs:{"src":require("@/assets/LiveScreen/3dot.svg"),"alt":" "}})])],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRecordingScreen),expression:"isRecordingScreen"}],staticClass:"menu-pause-area"},[_c('img',{staticClass:"menu-bar-icon pause-icon",attrs:{"src":require("@/assets/LiveScreen/Pause.svg")},on:{"click":_vm.onStopRecordingClick}}),_c('label',[_vm._v("録画を一時停止")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mobileView && _vm.isSharingView),expression:"!mobileView && isSharingView"}],staticClass:"sharing-screen-notify sharing-screen-notify__margin"},[_vm._v(" 画面を共有しています ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMenuExpand),expression:"isMenuExpand"}],staticClass:"extend-menu-bar-items",on:{"mouseover":function($event){_vm.isMenuExpand = true}}},[_c('div',_vm._l((_vm.filteredExtendMenuBarItems),function(item,index){return _c('div',{key:index,staticClass:"extend-menu-bar-item",class:{
								'extend-menu-bar-last-item': index === _vm.filteredExtendMenuBarItems.length - 1,
							}},[_c('div',{staticClass:"extend-item-label"},[_vm._v(_vm._s(item.label))]),_vm._l((_vm.getDeviceList(item.list)),function(device,deviceIndex){return _c('div',{key:deviceIndex,staticClass:"device-item-container"},[_c('div',{staticClass:"device-item"},[_c('div',{staticClass:"device-item-check",class:{
											'device-item-active': device.isActive,
											'device-item-inactive': !device.isActive,
										}},[_c('img',{staticClass:"check-icon",attrs:{"src":require("@/assets/LiveScreen/checked.svg")}})]),_c('div',{staticClass:"device-item-label",on:{"click":function($event){device.isActive || _vm.switchDevice(device.deviceId)}}},[_vm._v(" "+_vm._s(device.label)+" ")])])])}),(item.list == 'background')?_c('div',{staticClass:"background-selected-container"},_vm._l((_vm.backgrounds),function(button,buttonIdx){return _c('button',{key:buttonIdx,staticClass:"background-button",class:{ 'background-button--focused': button.focused },on:{"click":function($event){return _vm.onBackgroundButtonClick(button)}}},[_vm._v(" "+_vm._s(button.label)+" ")])}),0):_vm._e()],2)}),0)])])]):_c('div',{staticClass:"live-screen__full_size",class:{
				mobile__full_size: _vm.mobileAndTabletCheck,
			}},[_c('div',{staticClass:"live-screen-header"},[_vm._m(0),_c('div',{staticClass:"menu-bar-container menu-bar-container__reverse",class:{ 'menu-bar-container--full': !_vm.isLiveScreenMinimum }},[_c('div',{staticClass:"menu-bar-items menu-bar-items--full-size"},[_vm._l((_vm.menuBarItems),function(menuBarItem,menuIndex){return _c('button',{key:menuIndex,staticClass:"menu-bar-button",class:{
								'menu-bar-button--display-none':
									(_vm.mobileView &&
										['Setting', 'ShareScreen'].includes(menuBarItem.name)) ||
									menuBarItem.name === 'RecordScreen' ||
									menuBarItem.name === '3dot',
							},attrs:{"disabled":menuBarItem.name === 'RecordScreen'},on:{"click":function($event){return _vm.onMinimumMenuBarItemClick(menuBarItem.id)}}},[_c('img',{staticClass:"menu-bar-icon",class:{
									'expand-icon': menuBarItem.name === 'UpExpand',
									'leave-icon': menuBarItem.name === 'Leave',
									'is-active': menuBarItem.isActive,
								},attrs:{"src":require(`@/assets/LiveScreen/${menuBarItem.name}.svg`)}})])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMenuExpand),expression:"isMenuExpand"}],staticClass:"extend-menu-bar-items extend-menu-bar-items--full-size",on:{"mouseover":function($event){_vm.isMenuExpand = true}}},_vm._l((_vm.filteredExtendMenuBarItems),function(item,index){return _c('div',{key:index,staticClass:"extend-menu-bar-item",class:{
									'extend-menu-bar-last-item':
										index === _vm.filteredExtendMenuBarItems.length - 1,
								}},[_c('div',{staticClass:"extend-item-label"},[_vm._v(_vm._s(item.label))]),_vm._l((_vm.getDeviceList(item.list)),function(device,deviceIndex){return _c('div',{key:deviceIndex,staticClass:"device-item-container"},[_c('div',{staticClass:"device-item"},[_c('div',{staticClass:"device-item-check",class:{
												'device-item-active': device.isActive,
												'device-item-inactive': !device.isActive,
											}},[_c('img',{staticClass:"check-icon",attrs:{"src":require("@/assets/LiveScreen/checked.svg")}})]),_c('div',{staticClass:"device-item-label",on:{"click":function($event){device.isActive || _vm.switchDevice(device.deviceId)}}},[_vm._v(" "+_vm._s(device.label)+" ")])])])}),(item.list == 'background')?_c('div',{staticClass:"background-selected-container"},_vm._l((_vm.backgrounds),function(button,buttonIdx){return _c('button',{key:buttonIdx,staticClass:"background-button",class:{ 'background-button--focused': button.focused },on:{"click":function($event){return _vm.onBackgroundButtonClick(button)}}},[_vm._v(" "+_vm._s(button.label)+" ")])}),0):_vm._e()],2)}),0)],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRecordingScreen),expression:"isRecordingScreen"}],staticClass:"menu-pause-area",class:{ 'menu-pause-area--full-size': !_vm.isLiveScreenMinimum }},[_c('img',{staticClass:"menu-bar-icon pause-icon",attrs:{"src":require("@/assets/LiveScreen/Pause.svg")},on:{"click":_vm.onStopRecordingClick}}),_c('label',[_vm._v("録画を一時停止")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mobileView && _vm.isSharingView),expression:"!mobileView && isSharingView"}],staticClass:"sharing-screen-notify sharing-screen-notify__margin"},[_vm._v(" 画面を共有しています ")])]),(!_vm.mobileView)?_c('div',{staticClass:"secondary-participant-area"},_vm._l((_vm.displayParticipants),function(participant,participantIdx){return _c('div',{key:participantIdx,staticClass:"secondary-participant",attrs:{"id":participant.userId}},[_c('label',{staticClass:"participant-name",class:{
								'participant-name-label-video-off': !participant.bVideoOn,
								'participant-name-label-video-on': participant.bVideoOn,
							}},[_vm._v(" "+_vm._s(_vm._f("wordLength")(participant.displayName))+" "),(participant.muted)?_c('img',{staticClass:"muted-icon",attrs:{"src":require("@/assets/LiveScreen/Mute-active.svg")}}):_vm._e()])])}),0):_vm._e()]),_c('div',{staticClass:"live-screen-body"},[(!_vm.mobileView)?_c('div',{staticClass:"current-participant",attrs:{"id":_vm.currentParticipant.userId}},[_c('label',{staticClass:"participant-name participant-name__large",class:{
							'participant-name-label-video-off':
								!_vm.currentParticipant.bVideoOn && !_vm.isSharingView,
							'participant-name-label-video-on':
								_vm.currentParticipant.bVideoOn || _vm.isSharingView,
						}},[_vm._v(" "+_vm._s(_vm._f("wordLength")(_vm.currentParticipant.displayName))+" "),(_vm.currentParticipant.muted)?_c('img',{staticClass:"muted-icon muted-icon--large",attrs:{"src":require("@/assets/LiveScreen/Mute-active.svg")}}):_vm._e()])]):_vm._l((_vm.participants),function(participant,parIndex){return _c('div',{key:parIndex,staticClass:"participant",class:{
						'participant-video-off': !participant.bVideoOn,
						'participant-video-on': participant.bVideoOn,
					},attrs:{"track-by":"id","id":participant.userId}},[_c('label',{staticClass:"participant-name",class:{
							'participant-name-label-video-off': !participant.bVideoOn,
							'participant-name-label-video-on': participant.bVideoOn,
						}},[_vm._v(" "+_vm._s(_vm._f("wordLength")(participant.displayName))+" "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(participant.muted),expression:"participant.muted"}],staticClass:"muted-icon",attrs:{"src":require("@/assets/LiveScreen/Mute-active.svg")}})])])})],2)])]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"icon-area"},[_c('img',{staticClass:"live-screen-header__icon",attrs:{"src":require("@/assets/RightSideBarIcon/Live.svg")}}),_c('label',{staticClass:"live-screen-header__label"},[_vm._v("Co-mode")])])
}]

export { render, staticRenderFns }