import ApiService from './api.service';

const serviceURL = 'reproposal_general';
const ReProposalGeneralService = {
	/**
	 * Acquire ProposalGeneral and children by projectID
	 * @param {Integer} projectId
	 * @returns {Object} ProposalGeneral
	 */
	get(projectId) {
		return ApiService.get(`${serviceURL}`, `${projectId}`);
	},

	/**
	 * Create new ProposalGeneral record
	 * @param {Object} body {doc_content: {proposal_general Object}}
	 * @returns {Object} include success message and record ID
	 */
	post(contents) {
		let body = {
			doc_content: contents,
		};
		return ApiService.post(`${serviceURL}/`, body);
	},

	/**
	 *
	 * @param {Integer} projectId
	 * @param {Object} body
	 * @returns
	 */
	put(projectId, contents) {
		let body = {
			doc_content: contents,
		};
		return ApiService.put(`${serviceURL}/${projectId}`, body);
	},
};

export default ReProposalGeneralService;
