// import dragElement from './NotificationDragFunc';
import { mapState } from 'vuex';
import { downloadFile } from '../../store/modules/Image.js';

export default {
	name: 'PreviewModal',
	props: {
		selectiveModalId: {
			type: String,
		},
		previewItems: {
			type: Array,
			required: true,
		},
		selectedItem: {
			type: Object,
			required: true,
		},
		selectedMessageUserId: {
			type: Number,
		},
		hideDownloadButton: {
			type: Boolean,
		},
	},
	data() {
		return {
			inputContent: '',
			lastItem: '',
			firstItem: '',
			viewItem: {},
		};
	},
	computed: {
		// Get projectId, userId from store
		...mapState(['clientInfo', 'creatorInfo', 'creatorAvatar', 'clientAvatar']),
	},
	watch: {
		previewItems: function (newItems) {
			this.firstItem = newItems.at(0);
			this.lastItem = newItems.at(-1);
			newItems.map(function (item, index) {
				item.id = index;
			});
		},
		selectedItem: function (newSelectedItem) {
			this.viewItem = newSelectedItem;
		},
	},
	methods: {
		onSelectYesClick() {
			this.$bvModal.hide(`${this.selectiveModalId}`);
		},
		onSelectNoClick() {
			this.$bvModal.hide(`${this.selectiveModalId}`);
		},
		show() {
			console.log(
				{ previewItems: this.previewItems },
				{ lastItem: this.lastItem },
				{ firstItem: this.firstItem },
				{ selectedItem: this.viewItem }
			);
		},
		nextItemClick: function () {
			if (this.viewItem == this.lastItem) return;
			this.viewItem = this.previewItems.at(this.viewItem.id + 1);
			this.$emit('selectedItem-change', this.viewItem);
			console.log(
				'next click',
				{ viewItem: this.viewItem },
				{ previewItems: this.previewItems }
			);
		},
		previewItemsClick: function () {
			if (this.viewItem == this.firstItem) return;
			this.viewItem = this.previewItems.at(this.viewItem.id - 1);
			this.$emit('selectedItem-change', this.viewItem);
			console.log(
				'previous click',
				{ viewItem: this.viewItem },
				{ previewItems: this.previewItems }
			);
		},
		findItemsById: function (id) {
			return this.previewItems.find((element) => element.id == id);
		},

		onDownloadImage(imageUrl) {
			downloadFile(imageUrl);
		},
	},
};
