import ApiService from './api.service';

const addOnURL = 'add_on';
const AddOnService = {
	/**
	 * Acquire addOn by projectID
	 * @param {Integer} projectId
	 * @returns {Object} Schedule details
	 */
	get(projectId) {
		return ApiService.get(`${addOnURL}`, `${projectId}`);
	},

	/**
	 * Create new addOn record
	 * @param {Object} body {doc_content: {addOn Object}}
	 * @returns {Object} include success message and record ID
	 */
	post(body) {
		return ApiService.post(`${addOnURL}/`, body);
	},

	/**
	 * Update addOn content
	 * @param {Integer} addOnId: addOn ID
	 * @param {Object} contents:
	 * @returns
	 */
	update(addOnId, contents) {
		let body = {
			doc_content: contents,
		};
		return ApiService.put(`${addOnURL}/${addOnId}`, body);
	},

	/**
	 * Delete addOn record
	 * @returns {Object} Success message
	 */
	delete(addOnId) {
		return ApiService.delete(`${addOnURL}/${addOnId}`);
	},
};

export default AddOnService;
