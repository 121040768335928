import SideBarLeft from '../SideBarLeft/SideBarLeft.vue';

export default {
	name: 'MobileSideBarLeft',
	props: {
		menuItems: {
			type: Array,
			required: true,
		},
		showNavBar: {
			type: Boolean,
		},
		settlementItem: {
			type: Object,
		},
		documentItem: {
			type: Object,
		},
		screenFinishedItem: {
			type: Object,
		},
		projectDeadline: {
			type: String,
		},
		status: {
			type: Number,
		},
		showQuestionNotification: {
			type: Boolean,
		},
		showCreativeNotification: {
			type: Boolean,
		},
		isShowSettingContainer: {
			type: Boolean,
		},
		isCreator: {
			type: Boolean,
		},
		mobileView: {
			type: Boolean,
		},
	},
	components: {
		SideBarLeft,
	},
	watch: {
		status: {
			handler: function (newStatus) {
				this.processStatus = newStatus;
			},
			immediate: true,
		},
	},
	data() {
		return {
			processStatus: '',
		};
	},
	methods: {
		onHide() {},
		hideSideBar() {
			this.$emit('on-hide-side-bar');
		},
		onLeftMenuItemClick() {
			this.$emit('on-select-left-menu');
			this.$emit('on-hide-side-bar');
		},
		onClickMyPage() {
			this.$emit('on-click-mypage');
			this.$emit('on-hide-side-bar');
		},
		onClickProjectMenu() {
			this.$emit('on-click-projectmenu');
			this.$emit('on-hide-side-bar');
		},
		onClickEditProject() {
			this.$emit('on-click-editproject');
			this.$emit('on-hide-side-bar');
		},
	},
};
