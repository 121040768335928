import SideBarRight from '../SideBarRight/SideBarRight.vue';

export default {
	components: {
		SideBarRight,
	},
	props: {
		menuItems: {
			type: Array,
			required: true,
		},
		userImage: {
			type: String,
			required: true,
		},
		userName: {
			type: String,
			required: true,
			default: '',
		},
		sideBtnClass: {
			type: String,
			required: true,
		},
		showSpecialButton: {
			type: Boolean,
			default: false,
		},
		showBottomName: {
			type: Boolean,
			default: false,
		},
		messageEvent: {
			type: Number,
		},
		mobileView: {
			type: Boolean,
		},
	},
	data() {
		return {
			activeItem: '',
		};
	},
	methods: {
		onOpenClientMode() {
			this.$emit('on-open-client-modal');
		},

		onToggleRightMenuMobileScreen(index) {
			this.$emit('on-hide-side-bar');
			this.$emit('on-toggle-right-menu-mobile-screen', index);
		},
		closeActive() {
			this.activeItem = '';
		},
		hideSideBar() {
			this.$emit('on-hide-side-bar');
		},
		onUnseenMessages(unSeenMessages) {
			this.$emit('on-unseen-messages', unSeenMessages);
		},
	},
};
