import ApiService from './api.service';

const serviceURL = 'management_master';
const ManagementMasterService = {
	/**
	 * Acquire User by ManagementMaster ID
	 * @param {Integer} id : ManagementMaster ID
	 * @returns {Object} ManagementMaster details
	 */
	get(id) {
		return ApiService.get(`${serviceURL}`, `${id}`);
	},
};

export default ManagementMasterService;
