import TagInput from '@/components/TagInput/TagInput.vue';
import ImagePreviewModal from '@/components/PreviewModal/ImagePreviewModal.vue';
import ImageSelectModal from '@/components/Modal/CoCreateImageSelectModal/ImageSelectModal.vue';
import DropDown from '@/components/DropDown/DropDown.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import CoCreatePdfExporter from '@/components/CoCreatePdfExporter/CoCreatePdfExporter.vue';
import SaveButtonArea from '@/components/SaveButtonArea/SaveButtonArea.vue';
import ProcessingSpinner from '@/components/Spinner/Processing/Processing.vue';

import DecisionService from '@/services/API/decision.service';
import CoCreateService from '@/services/API/co_create.service';
import SearchImage from '@/services/API/search_img.service';
import TransferService from '@/services/API/transfer.service';
import AddOnService from '@/services/API/add_on.service';

import ConstValue from '@/constants/CoCreateConst.js';
import CommonConst from '@/constants/CommonConst';

import { mapState } from 'vuex';
import socketClient from '@/services/SOCKET';

export default {
	name: 'CoCreateScreen',
	components: {
		TagInput,
		ImagePreviewModal,
		DropDown,
		NotificationModal,
		ImageSelectModal,
		CoCreatePdfExporter,
		SaveButtonArea,
		ProcessingSpinner,
	},
	props: {
		mobileView: {
			type: Boolean,
		},
		isSaveBtnShowed: {
			type: Boolean,
		},
	},
	data() {
		return {
			file_name: '',
			keyWordList: [],
			resultImages: ConstValue.DEFAULT_RESULT_IMGS,
			mobileMenuItems: ConstValue.MOBILE_MENU_ITEMS,
			counting: 0,
			showPreviousBtn: false,
			crossId: ConstValue.CROSS_ID,
			roundId: ConstValue.ROUND_ID,
			resultsId: ConstValue.RESULT_ID,
			roundImages: ConstValue.DEFAULT_SELECTED_IMGS,
			crossImages: ConstValue.DEFAULT_SELECTED_IMGS,
			categories: ConstValue.CATEGORIES,
			screenShots: [],
			jobKeyword: 'すべて',
			productKeyword: 'すべて',
			coCreationContent: '',
			decisionImgList: [],
			pdfContent: {},
			//NotificationModal Clear Content
			clearContentConfirmModalId: 'clear-contents-confirm-modal',
			clearContentConfirmModalContents: '内容を削除します。',

			//NotificationModal Save Content
			saveDecisionContentModalId: 'save-contents-confirm-modal',

			updateDecisionFailModalId: 'update-decision-fail-modal',
			updateDecisionFailModalContents: '「コンセプトデザイン」に反映できません',

			savePdfModalId: 'save-pdf-modal',
			savePdfContent: 'Fileに保存します。',

			//Mobile Screen used Data
			isMobileMenuOpen: false,
			menuScreenIdx: undefined,
			isShowPreviewBtn: true,
		};
	},
	computed: {
		...mapState(['projectId', 'userId', 'projectInfo', 'isShareCoCreate']),

		imageOffset() {
			if (this.counting === 0) {
				return 0;
			}
			return ConstValue.SEARCH_RANGE * this.counting;
		},

		selectedCategory() {
			return [this.productKeyword, this.jobKeyword];
		},

		showInputKeyWord() {
			return this.menuScreenIdx === 0;
		},

		showDecisionInput() {
			return this.menuScreenIdx === 1;
		},

		showSelectedImages() {
			return this.menuScreenIdx === 2;
		},

		saveDecisionContentModalContents() {
			let projectStatus = this.projectInfo?.status;

			if (projectStatus === CommonConst.SCREEN_ID.ADDCOST) {
				return '内容を「コンセプトデザイン」に追加されます。\nよろしいですか？';
			}

			return '内容を「コンセプトデザイン」に反映させます。\nよろしいですか？';
		},

		showSaveArea() {
			let projectStatus = this.projectInfo?.status;

			return (
				projectStatus === CommonConst.SCREEN_ID.ADDCOST ||
				projectStatus === CommonConst.SCREEN_ID.DECISION
			);
		},
	},
	watch: {
		/**
		 * Watching counting value for show or hide previous btn
		 */
		async counting() {
			this.$store.commit('setIsAppProcessing', true);

			if (this.counting > 0) {
				this.showPreviousBtn = true;
			} else {
				this.showPreviousBtn = false;
			}
			this.resultImages = await this._searchImage();
			this.sendSocketEvent(CommonConst.COCREATE_SOCKET_INDEX.IMG_RESULT);
			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Watching search result Array for changing UI
		 */
		resultImages(newVal) {
			this.$nextTick(() => {
				// Scroll to top when search results have been change
				this.$refs.searchResulsContainer.scrollTop = 0;
			});
			if (this.resultImages.length == 0) {
				// Showing empty img box
				this.resultImages = ConstValue.DEFAULT_RESULT_IMGS;
			}

			this.handleImageLoadingSpinner(newVal);
		},
		/**
		 *  Watching roundImages Array Keeping the same length as DEFAULT_SELECTED_IMGS
		 * @param {*} items
		 */
		roundImages: {
			handler: async function (items, oldItems) {
				// TO DO : Check the length of roundImages
				let MAX_LENGTH = ConstValue.MAX_LENGTH;
				if (items.length < MAX_LENGTH) {
					this.roundImages = [...items, ...ConstValue.DEFAULT_SELECTED_IMGS].slice(
						0,
						MAX_LENGTH
					);
				}
				let coCreateData = {
					round_items: this.roundImages,
				};
				await this._updateCoCreateData(this.projectId, coCreateData);
			},
			deep: true,
		},

		/**
		 *  Watching crossImages Array Keeping the same length as DEFAULT_SELECTED_IMGS
		 * @param {*} items
		 */
		crossImages: {
			handler: async function (items, oldItems) {
				// TODO: Check if  crossImages is empty

				let MAX_LENGTH = ConstValue.MAX_LENGTH;
				if (items.length < MAX_LENGTH) {
					this.crossImages = [...items, ...ConstValue.DEFAULT_SELECTED_IMGS].slice(
						0,
						MAX_LENGTH
					);
				}
				let coCreateData = {
					cross_items: this.crossImages,
				};

				await this._updateCoCreateData(this.projectId, coCreateData);
			},
			deep: true,
		},

		async jobKeyword(newVal, oldVal) {
			this.$store.commit('setIsAppProcessing', true);
			this.resultImages = await this._searchImage();
			let coCreateData = {
				job_categories: newVal,
			};
			await this._updateCoCreateData(this.projectId, coCreateData);

			this.$store.commit('setIsAppProcessing', false);
		},

		async productKeyword(newVal, oldVal) {
			this.$store.commit('setIsAppProcessing', true);
			this.resultImages = await this._searchImage();
			let coCreateData = {
				product_categories: newVal,
			};
			await this._updateCoCreateData(this.projectId, coCreateData);

			this.$store.commit('setIsAppProcessing', false);
		},

		async keyWordList(newVal) {
			this.$store.commit('setIsAppProcessing', true);

			this.resultImages = await this._searchImage();
			let coCreateData = {
				keyword_list: newVal,
			};
			await this._updateCoCreateData(this.projectId, coCreateData);

			this.$store.commit('setIsAppProcessing', false);
		},
	},
	methods: {
		handleImageLoadingSpinner(resultImages) {
			resultImages.forEach((element) => {
				const image = document.createElement('img');
				image.src = element.url;
				image.onload = function () {
					element.isLoaded = false;
				};
			});
		},

		//PC Screen Handle Methods
		onOpenSelectImageModal(selectImageModalId) {
			this.$bvModal.show(selectImageModalId);
		},
		onInputCoCreationContent() {
			this.sendSocketEvent(CommonConst.COCREATE_SOCKET_INDEX.CONTENT);
		},
		onSelectCategory(categoryInfo) {
			if (categoryInfo.idx == ConstValue.PRODUCT_CATEGORI_IDX) {
				this.productKeyword = categoryInfo.item;
			} else {
				this.jobKeyword = categoryInfo.item;
			}
			this.counting = 0;
			this.sendSocketEvent(CommonConst.COCREATE_SOCKET_INDEX.KEYWORD_DROPDOWN);
		},

		/**
		 * Hanle when having search keywords for searching images by Adobe Stock API
		 * @param {Array} keywords: List of search keywords
		 */
		async onSearchByInput(keywords) {
			this.keyWordList = keywords;
			this.sendSocketEvent(CommonConst.COCREATE_SOCKET_INDEX.KEYWORD_LIST);
		},

		/**
		 * Handle previous btn for showing previous 100 results
		 * @param {Integer} step counting's step: -1 and 1
		 */
		async onReSearching(step) {
			this.counting += step;
		},

		/**
		 * Handle when click the round btn at the image
		 * @param {Integer} data.id : selected image's id defined by AdobeStock
		 * @param {Integer} data.listId : target list image's id : cross imagelist or round imagelist
		 */
		onResultImageClick(data) {
			const targetId = data.listId;
			const imageId = data.id;

			let target = this.getArrayImages(targetId);

			const selectedImg = this._filterSelectedImageObject(
				this.resultImages,
				imageId,
				targetId
			);
			let isDuplicate = this[target].some((img) => img.id === selectedImg.id);
			if (isDuplicate) {
				return;
			}

			const temporaryImagesList = this._filterSelectedImageArray(
				this[target],
				selectedImg
			);
			this[target] = [...temporaryImagesList];
			this.sendSocketEvent(CommonConst.COCREATE_SOCKET_INDEX.IMG_LIST);
		},

		/**
		 * Handle delete selected image
		 * @param {Object} image : delete image Object
		 * @param {Array} targetArray : Array of images has delete image's id
		 */
		async onDeleteClick(targetArray, image) {
			this.$store.commit('setIsAppProcessing', true);

			let deleteImageIdx = this._getImageIdxById(targetArray, image['id']);
			let resultIdx = this._getImageIdxById(this.resultImages, image['id']);
			if (resultIdx != -1) {
				this.resultImages[resultIdx]['target'] = this.resultsId;
			}
			// Get index of Selected Imgs at CoCreateScreen in Decision's img list
			let decisionDeleteImageIdx = this._getImageIdxById(
				this.decisionImgList,
				image['id']
			);
			// Remove deleted img at Circle or Round Image Array
			targetArray.splice(deleteImageIdx, 1);
			// Remove deleted img at Decision Transfer Image List
			this.sendSocketEvent(CommonConst.COCREATE_SOCKET_INDEX.IMG_LIST);
			if (decisionDeleteImageIdx == -1) {
				this.$store.commit('setIsAppProcessing', false);
				return;
			}
			this.decisionImgList.splice(decisionDeleteImageIdx, 1);
			let updateCoCreateImagesData = {
				image_list: this.decisionImgList,
				update_user: this.userId,
			};
			await this._updateDecision(this.projectId, updateCoCreateImagesData);

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Handle open selected preview Modal
		 * @param {String} modalId: image's modal Id
		 */
		onPreview(modalId) {
			this.$bvModal.show(modalId);
		},

		async onSaveSelectedImgContent(transferData) {
			//Find index of specific image using findIndex method.

			const imageIdx = this.resultImages.findIndex(
				(obj) => obj.id == transferData['id']
			);
			let roundId = this.roundImages.findIndex((obj) => obj.id == transferData['id']);
			let crossId = this.crossImages.findIndex((obj) => obj.id == transferData['id']);
			if (roundId != -1) {
				this.roundImages[roundId]['comment'] = transferData['comment'];
			}
			if (crossId != -1) {
				this.crossImages[crossId]['comment'] = transferData['comment'];
			}
			//Update image's comment property.
			if (imageIdx != -1) {
				this.resultImages[imageIdx]['comment'] = transferData['comment'];
			}
			this.sendSocketEvent(CommonConst.COCREATE_SOCKET_INDEX.IMG_LIST);
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'send_images_to_decision',
					content: {
						updateDecisionContent: false,
					},
				},
				room: this.projectId,
			});
		},

		onDeleteAll() {
			this.$bvModal.show(`${this.clearContentConfirmModalId}`);
		},

		onSaveContent() {
			this.$bvModal.show(`${this.saveDecisionContentModalId}`);
		},

		/**
		 * Handle clear button
		 */
		_deleteAllContent() {
			this.keyWordList = [];
			this.roundImages = ConstValue.DEFAULT_SELECTED_IMGS;
			this.crossImages = ConstValue.DEFAULT_SELECTED_IMGS;
			this.resultImages = [];
			this.productKeyword = 'すべて';
			this.jobKeyword = 'すべて';
			this.coCreationContent = '';
			this.sendSocketEvent(CommonConst.COCREATE_SOCKET_INDEX.CLEAR_CONTENT);
		},

		/**
		 * Handle save text area input button
		 */
		async onSavePdf() {
			this.$store.commit('setIsAppProcessing', true);

			let base64roundImages = await Promise.all(
				this.roundImages.map(async (img) => {
					if (img.url) {
						img.url = await this._toDataURL(img.url);
					}
					return img;
				})
			);
			let base64crossImages = await Promise.all(
				this.crossImages.map(async (img) => {
					if (img.url) {
						img.url = await this._toDataURL(img.url);
					}
					return img;
				})
			);
			this.pdfContent = {
				productKeyword: this.productKeyword,
				jobKeyword: this.jobKeyword,
				keyWordList: this.keyWordList,
				coCreationContent: this.coCreationContent,
				roundImages: base64roundImages,
				crossImages: base64crossImages,
			};

			// Get file name
			let files = await AddOnService.get(this.projectId);
			let cocreate_files = files.data.filter(
				(file) => file.screen_name === 'co_create'
			);
			let index = cocreate_files.length + 1;
			this.file_name = '参考画像 ' + index;

			// Render pdf
			let pdf = await this.$refs.coCreatePdfExporter.getRenderedPDFTemplate();
			let pdfFileParams = {
				content: pdf,
				project_id: this.projectId,
				file_name: this.file_name + '.pdf',
			};
			let pdfFileKey = await this._uploadPdf(pdfFileParams);
			await this.onTransferUploadedFile(pdfFileKey, this.file_name + '.pdf');

			this.$bvModal.show(this.savePdfModalId);

			this.$store.commit('setIsAppProcessing', false);
		},

		async onSaveDecisionContent() {
			this.$store.commit('setIsAppProcessing', true);

			let updateCoCreateInputData = {
				decision_content: this.coCreationContent + '\r\n\n',
				update_user: this.userId,
				image_list: [...this.decisionImgList, ...this.roundImages]
					.filter((img) => Object.keys(img).length > 0)
					.filter(
						(img, index, self) =>
							self.findIndex((t) => {
								return t.id === img.id;
							}) === index
					),
			};
			await this._updateDecision(this.projectId, updateCoCreateInputData);

			socketClient.send('data_transfer', {
				data: {
					event_name: 'send_images_to_decision',
					content: {
						updateDecisionContent: true,
					},
				},
				room: this.projectId,
			});

			this.$store.commit('setIsAppProcessing', false);
		},

		async _toDataURL(url) {
			return new Promise((resolve, reject) => {
				var xhr = new XMLHttpRequest();
				xhr.onload = function () {
					var reader = new FileReader();
					reader.onloadend = function () {
						resolve(reader.result);
					};
					reader.readAsDataURL(xhr.response);
				};
				xhr.onerror = () => {
					reject({
						status: this.status,
						statusText: xhr.statusText,
					});
				};
				xhr.open('GET', url);
				xhr.responseType = 'blob';
				xhr.send();
			});
		},
		async _uploadPdf(params) {
			try {
				let response = await TransferService.postPdf(params);
				if (!response || response.status !== 200) {
					throw 'Upload PDF failed!';
				}

				console.log('%c Upload PDF successfully!', 'color: green');
				let key = response.data.key;
				return key;
			} catch (error) {
				console.log(error);
			}
		},
		async onTransferUploadedFile(key, file_name) {
			let body = {
				doc_content: {
					project_id: this.projectId,
					user_id: this.userId,
					default_name: file_name,
					image_key: key,
					screen_name: 'co_create',
					name_by_client: '',
					name_by_creator: '',
					delete_flag: 0,
				},
			};
			try {
				let response = await AddOnService.post(body);
				if (response.status == 200) {
					console.log(`Done upload PDF ${file_name}`);
				}
			} catch (error) {
				console.log(`Upload PDF failed: ${error}`, 'color: red');
			}
		},
		/**
		 * Upload image
		 * @param {Object} params request objects
		 * @returns uploaded file key
		 */
		async _uploadImage(params) {
			try {
				let response = await TransferService.post(params);
				if (!response || response.status !== 200) {
					throw 'Upload Image failed!';
				}

				console.log('%c Upload image successfully!', 'color: green');
				return response.data.key;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Handle when drag Image
		 * @param {Object} event : drag event
		 */
		onDragImage(e) {},

		onDragEnd() {
			this.isShowPreviewBtn = true;
		},
		/**
		 * Handle when start drag Image
		 * @param {Object} event : drag event
		 * */

		onDragImageStart(e, image, sourceId, swap) {
			this.isShowPreviewBtn = false;
			const { dataTransfer } = e;
			dataTransfer.setData('dragData', JSON.stringify({ image, swap, sourceId }));
		},

		/**
		 * Handle when drop Image
		 * @param {Object} event : drop event
		 * @param {Object} target : drop target
		 */
		onDropImage(e, targetId) {
			e.preventDefault();
			const { dataTransfer } = e;
			const { image, swap, sourceId } = JSON.parse(dataTransfer.getData('dragData'));
			if (image['target'] && sourceId == this.resultsId) {
				return;
			}
			const selectedImg = this._filterSelectedImageObject(
				this.resultImages,
				image['id'],
				targetId
			);
			let target = this.getArrayImages(targetId);
			if (swap) {
				let source = this.getArrayImages(sourceId);
				this[source] = this.removeItem(this[source], image['id']);
			}
			this[target] = this._filterSelectedImageArray(this[target], selectedImg);
			this.sendSocketEvent(CommonConst.COCREATE_SOCKET_INDEX.IMG_LIST);
		},

		onClose() {
			this.$emit('on-close-co-create-screen');
		},

		//****************************************************/

		//Mobile Screen Handle Methods

		onMobileMenuItemClick(menuIndex) {
			this.menuScreenIdx = menuIndex;
			switch (menuIndex) {
				case 0:
					this.isMobileMenuOpen = true;
					return;
				case 1:
					this.isMobileMenuOpen = true;
					return;
				case 2:
					this.isMobileMenuOpen = true;
					return;
				case 3:
					// Handle when click in back btn
					this.onReSearching(-1);
					return;
				case 4:
					// Handle when click in refesh btn
					this.onReSearching(1);
					return;
				case 5:
					// Handle when click in refesh btn
					this.onClearContent();
					return;
			}
		},

		onCloseMenuScreen() {
			this.isMobileMenuOpen = false;
		},

		//****************************************************/

		//Comon Methods

		/**
		 * Find target image's index in Array of images has target image's id
		 * @param {Integer} targetImageId : image id from AdobeStock
		 * @param {Array} targetArray : Array of images has target image's id
		 * @returns image's index in targetArray
		 */
		_getImageIdxById(targetArray, targetImageId) {
			return targetArray.findIndex((x) => x.id === targetImageId);
		},

		/**
		 * Handle filter the targetImage Object
		 * @param {Array} targetImageArray : Array of images has target image's id
		 * @param {Integer} targetImageId : target image id
		 * @return filtered Image's Object
		 */
		_filterSelectedImageObject(imageArray, imageId, targetId) {
			let selectedImageIdx = this._getImageIdxById(imageArray, imageId);
			var selectedImgUrl = '';
			if (selectedImageIdx != -1) {
				imageArray[selectedImageIdx]['target'] = targetId;
				selectedImgUrl = imageArray[selectedImageIdx]['url'];
			}
			let selectedImg = { ...ConstValue.SELECTED_IMAGE };
			selectedImg['url'] = selectedImgUrl;
			selectedImg['id'] = imageId;
			selectedImg['target'] = targetId;
			return selectedImg;
		},

		/**
		 * Handle when selected cross or round btn at search result
		 * @param {Array} targetImageArray : crossImageAray or roundImageArray
		 * @param {Object} selectedImage : selected image
		 * @returns {Array} filtered images 's Array
		 */
		_filterSelectedImageArray(targetImageArray, selectedImage) {
			let filteredImageArray = [...targetImageArray];
			for (let i = 0; i < filteredImageArray.length; i++) {
				if (Object.keys(filteredImageArray[i]).length === 0) {
					filteredImageArray[i] = selectedImage;
					break;
				}
			}
			return filteredImageArray;
		},

		/**
		 * Deleted images have been seleted when re-searching
		 * @param {Array} resultImages Array of images get from Adobe Stock
		 * @returns Array of image not include seleted images
		 */
		_filterResultsImages(resultImages) {
			let crossImageIds = [];
			let roundImageIds = [];
			if (this.crossImages.length > 0) {
				crossImageIds = new Set(this.crossImages.map(({ id }) => id));
			}
			if (this.roundImages.length > 0) {
				roundImageIds = new Set(this.roundImages.map(({ id }) => id));
			}

			for (let img of resultImages) {
				this.$set(img, 'isLoaded', true);

				img['url'] = img['thumbnail_url'];
				delete img['thumbnail_url'];
				if (crossImageIds.has(img['id'])) {
					img['target'] = this.crossId;
				}
				if (roundImageIds.has(img['id'])) {
					img['target'] = this.roundId;
				}
			}
			return resultImages;
		},

		/**
		 * Combine all search keywords (input keywords, dropdown values )
		 * @returns combined keywords
		 */
		_getAllSearchKeyword() {
			let jobKeyword = this.jobKeyword;
			let productKeyword = this.productKeyword;

			if (jobKeyword === 'すべて') jobKeyword = '';
			if (productKeyword === 'すべて') productKeyword = '';

			let combinedSearchKeyWord =
				this.keyWordList.join('') + '' + jobKeyword + '' + productKeyword;
			return combinedSearchKeyWord;
		},

		/**
		 * Handle when call Adobe Stock search image API
		 * @returns Array of image querry by search keywords
		 */
		async _searchImage() {
			try {
				const keyWord = this._getAllSearchKeyword();
				if (!keyWord && keyWord.trim().length === 0) {
					return [];
				}

				const searchOptions = {
					key_word: keyWord,
					limit: ConstValue.SEARCH_RANGE,
					offset: this.imageOffset,
				};
				let response = await SearchImage.search(searchOptions);
				if (response.status !== 200) {
					throw 'Seach Image Failed';
				}
				const resultImages = this._filterResultsImages(response.data.files);
				return resultImages;
			} catch (error) {
				console.log(error);
			}
		},

		async _getDecisionData(projectId) {
			try {
				let response = await DecisionService.get(projectId);
				if (!response || response.status !== 200) {
					throw 'Get Decision failed';
				}
				this.coCreationContent = response.data['decision_content'] || [];
				this.decisionImgList = response.data['image_list'] || [];
				return true;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		async _getCoCreateData(projectId) {
			try {
				let response = await CoCreateService.get(projectId);
				if (!response || response.status !== 200) {
					throw 'Get Decision failed';
				}

				if (Object.keys(response.data).length === 0) {
					await this._createCoCreateData();
				}
				this.keyWordList = response.data['keyword_list'] || [];
				this.jobKeyword = response.data['job_categories'] || 'すべて';
				this.productKeyword = response.data['product_categories'] || 'すべて';
				this.roundImages = response.data['round_items'] || [];
				this.crossImages = response.data['cross_items'] || [];
				this.screenShots = response.data['screen_shots'] || [];
				return true;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		/**
		 *  Create Co-Create data
		 * @returns {Promise<boolean>}
		 */
		async _createCoCreateData() {
			try {
				let coCreateData = {
					project_id: this.projectId,
					product_categories: '',
					job_categories: '',
					keyword: '',
					round_items: [],
					cross_items: [],
				};

				let response = await CoCreateService.post({
					doc_content: coCreateData,
				});

				if (!response || response.status !== 200) {
					throw 'Create Co-Create failed';
				}

				console.log('%c Create Co-Create successfully', 'color: green');
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		/**
		 * Update co-create data
		 */
		async _updateCoCreateData(id, data) {
			try {
				await CoCreateService.update(id, data);
				console.log('%c Update Co-Create successfully', 'color: green');
				return true;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		/**
		 * Update CoCreation Input text to Decision Content
		 * @param {Integer} projectId Project ID
		 * @param {Object} updateDecisionData updating data
		 */
		async _updateDecision(projectId, updateDecisionData) {
			try {
				await DecisionService.update(projectId, {
					doc_content: updateDecisionData,
				});
				console.log('%c Update Decision successfully!', 'color: green');
				return true;
			} catch (error) {
				this.$bvModal.show(this.updateDecisionFailModalId);
				console.log(error);
				return false;
			}
		},

		/**
		 *  remove image from array
		 * @param {Array} items
		 * @param {Integer} id
		 * */
		removeItem(items, id) {
			return items.filter((item) => item.id != id);
		},

		/**
		 * Get array key by  id
		 *  @param {Array} id
		 **/
		getArrayImages(id) {
			switch (id) {
				case 0:
					return 'resultImages';
				case 1:
					return 'roundImages';
				case 2:
					return 'crossImages';
			}
		},
		sendSocketEvent(type) {
			let receiveUser =
				this.projectInfo.responsible_user == this.userId ? 'client' : 'creator';
			if (type == CommonConst.COCREATE_SOCKET_INDEX.KEYWORD_DROPDOWN) {
				socketClient.send('data_transfer', {
					data: {
						event_name: `send_keywork_to_${receiveUser}`,
						productKeyword: this.productKeyword,
						jobKeyword: this.jobKeyword,
					},
					room: this.projectId,
				});
			} else if (type == CommonConst.COCREATE_SOCKET_INDEX.KEYWORD_LIST) {
				socketClient.send('data_transfer', {
					data: {
						event_name: `send_keyword_list_to_${receiveUser}`,
						keyWordList: this.keyWordList,
					},
					room: this.projectId,
				});
			} else if (type == CommonConst.COCREATE_SOCKET_INDEX.CONTENT) {
				socketClient.send('data_transfer', {
					data: {
						event_name: `send_cocreate_content_to_${receiveUser}`,
						coCreationContent: this.coCreationContent,
					},
					room: this.projectId,
				});
			} else if (type == CommonConst.COCREATE_SOCKET_INDEX.IMG_LIST) {
				socketClient.send('data_transfer', {
					data: {
						event_name: `send_round_cross_images_to_${receiveUser}`,
						roundImages: this.roundImages,
						crossImages: this.crossImages,
						resultImages: this.resultImages,
					},
					room: this.projectId,
				});
			} else if (type == CommonConst.COCREATE_SOCKET_INDEX.CLEAR_CONTENT) {
				socketClient.send('data_transfer', {
					data: {
						event_name: `clear_content_${receiveUser}`,
					},
					room: this.projectId,
				});
			} else if (type == CommonConst.COCREATE_SOCKET_INDEX.IMG_RESULT) {
				socketClient.send('data_transfer', {
					data: {
						event_name: `reload_image_result_${receiveUser}`,
						counting: this.counting,
					},
					room: this.projectId,
				});
			}
		},
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		if (this.projectId) {
			await this._getDecisionData(this.projectId);
			await this._getCoCreateData(this.projectId);
		}

		this.$store.commit('setIsAppProcessing', false);

		window.addEventListener('beforeunload', () => {
			this.$destroy();
		});
		socketClient.listen('new_data_transfer', async (data) => {
			let receiveUser =
				this.projectInfo.responsible_user == this.userId ? 'creator' : 'client';
			switch (data.event_name) {
				case `send_keywork_to_${receiveUser}`:
					this.productKeyword = data.productKeyword;
					this.jobKeyword = data.jobKeyword;
					break;
				case `send_keyword_list_to_${receiveUser}`:
					this.keyWordList = data.keyWordList;
					break;
				case `send_cocreate_content_to_${receiveUser}`:
					this.coCreationContent = data.coCreationContent;
					break;
				case `send_round_cross_images_to_${receiveUser}`:
					this.roundImages = data.roundImages;
					this.crossImages = data.crossImages;
					this.resultImages = data.resultImages;
					break;
				case `clear_content_${receiveUser}`:
					this.keyWordList = [];
					this.roundImages = ConstValue.DEFAULT_SELECTED_IMGS;
					this.crossImages = ConstValue.DEFAULT_SELECTED_IMGS;
					this.resultImages = [];
					this.productKeyword = 'すべて';
					this.jobKeyword = 'すべて';
					this.coCreationContent = '';
					break;
				case `reload_image_result_${receiveUser}`:
					this.counting = data.counting;
					break;
				default:
					break;
			}
		});
	},
};
