import DropDown from '@/components/DropDown/DropDown.vue';
import EditImageNameModal from '@/components/Modal/EditImageName/EditImageNameModal.vue';
import Modal from '@/components/Modal/Modal.vue';

import AddOnFolderConst from '@/constants/AddOnFolderConst.js/';
import CommonConst from '@/constants/CommonConst.js';

import AddOnService from '@/services/API/add_on.service';
import TransferService from '@/services/API/transfer.service';
import socketClient from '@/services/SOCKET';

import { mapState } from 'vuex';
import moment from 'moment';
import Axios from 'axios';
const MAX_LENGTH_DEFAULT = 15;
const MAX_LENGTH_MOBILE = 10;
const MOBILE_WIDTH = 420;

export default {
	components: { DropDown, EditImageNameModal, Modal },
	data() {
		return {
			categories: [],
			screenType: 'すべて',
			sortType: '追加日',
			currentImageCards: [],
			originImageCards: [],

			/**
			 * @binding {string} Index of card which is currently openned options_menu
			 */
			showingCardOptionIdx: null,

			/**
			 * @value {integer} id : id of addon record
			 * @value {string} name : editting card name
			 */
			selectingCard: {
				id: null,
				name: null,
			},
			isListView: false,

			pdfPreviewer: {
				key: '',
				url: '',
				name: '',
				dateCreated: '',
			},
			previewModalId: 'add-on-folder-preview-modal',
		};
	},

	computed: {
		selectedCategory() {
			return [this.screenType, this.sortType];
		},

		isClientApp() {
			return this.$route.path.includes('client');
		},

		...mapState(['projectId', 'userId', 'projectInfo', 'clientInfo']),
	},

	watch: {
		screenType(newVal) {
			const [all, co_create, white_board, memo, comode_ai] = [
				'すべて',
				'Co-mode Image',
				'Co-mode Canvas',
				'Memo',
				'Co-mode AI',
			];
			switch (newVal) {
				case all:
					this.currentImageCards = this.originImageCards.sort((a, b) =>
						this.sortByDate(a, b)
					);
					return;

				case co_create:
					this.currentImageCards = this.filterImageCardsScreenType(
						co_create,
						this.originImageCards
					);
					return;

				case white_board:
					this.currentImageCards = this.filterImageCardsScreenType(
						white_board,
						this.originImageCards
					);
					return;

				case memo:
					this.currentImageCards = this.filterImageCardsScreenType(
						memo,
						this.originImageCards
					);
					return;
				
				case comode_ai:
					this.currentImageCards = this.filterImageCardsScreenType(
						comode_ai,
						this.originImageCards
					);
					return;

				default:
					console.log('%c No valid option selected', 'color: red');
					break;
			}
		},

		sortType(newVal) {
			console.log({ newVal });
			this.currentImageCards = this.sortImageCards(newVal, this.currentImageCards);
		},
	},

	methods: {
		//Filter value length 10 or 15 depend on width of screen
		_titleFilter: function (value) {
			let maxLength = MAX_LENGTH_DEFAULT;
			if (window.innerWidth <= MOBILE_WIDTH && this.isListView) {
				maxLength = MAX_LENGTH_MOBILE;
			}
			if (value.length <= maxLength) {
				return value;
			}
			return value.slice(0, maxLength) + '...';
		},
		//get list imagecards after filter item.name
		getFilterCurrentImageCards() {
			return this.currentImageCards.map((item) => {
				return { ...item, filterName: this._titleFilter(item.name) };
			});
		},
		clickOutSide() {
			this.showingCardOptionIdx = null;
		},

		/**
		 * Handle dropdown's select event
		 * @param {String} categoryInfo selected category's item
		 */
		onSelectCategory(categoryInfo) {
			if (categoryInfo.idx === AddOnFolderConst.SCREEN_TYPE_IDX) {
				this.screenType = categoryInfo.item;
			} else if (categoryInfo.idx === AddOnFolderConst.SORT_TYPE_IDX) {
				this.sortType = categoryInfo.item;
			} else return;
		},

		/**
		 * Handle change view btn
		 */
		onChangeView() {
			this.isListView = !this.isListView;
		},

		/**
		 * Handle show options
		 * @param {Number} imageIdx : image index
		 * @returns
		 */
		onShowOption(imageIdx) {
			// Make sure click event is executes after click-ouside directive
			setTimeout(() => {
				this.showingCardOptionIdx = imageIdx;
			}, 0);
		},

		async onDeleteImage(imageId) {
			this.$store.commit('setIsAppProcessing', true);

			this.showingCardOptionIdx = null;
			const cardIdx = this.findCardIndex(this.originImageCards, imageId);

			let body = { delete_flag: 1 };
			await this.updateAddOnFolderFiles(imageId, body);

			this.originImageCards[cardIdx].deleteFlag = 1;

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 *
		 * @param {Integer} imageId Add on record id
		 * @param {String} imageName File name
		 */
		onEditNameClick(imageId, imageName) {
			this.showingCardOptionIdx = null;

			this.selectingCard.id = imageId;
			this.selectingCard.name = imageName;

			this.$bvModal.show('edit-image-name-modal');
		},

		async onSaveNewName(newName) {
			this.$store.commit('setIsAppProcessing', true);

			const cardIdx = this.findCardIndex(this.originImageCards, this.selectingCard.id);

			let body = this.isClientApp
				? { name_by_client: newName + '.pdf' }
				: { name_by_creator: newName + '.pdf' };
			await this.updateAddOnFolderFiles(this.selectingCard.id, body);

			this.originImageCards[cardIdx].name = newName;

			this.$store.commit('setIsAppProcessing', false);
		},

		async onPreview(idx) {
			let imageCard = this.currentImageCards[idx];
			let fileUrl = await this._getPreviewImgUrl(imageCard.imageKey, 600);
			this.pdfPreviewer = {
				key: imageCard.imageKey,
				url: fileUrl,
				name: imageCard.name,
				dateCreated: imageCard.dateCreated,
			};
			setTimeout(() => {
				this.$bvModal.show(`${this.previewModalId}`);
			}, 0);
		},

		/**
		 * Find index og cardImage object in it's array
		 * @param {Array} imageCards target array
		 * @param {Integer} cardId imageCard id
		 */
		findCardIndex(imageCards, cardId) {
			if (!cardId || imageCards.length <= 0) return;
			console.log({ cardId });
			return imageCards.findIndex((card) => card.id === cardId);
		},

		checkImageName(nameByCreator, nameByClient, defaultName) {
			const PATH = this.$route.path;

			if (PATH.includes('creator') && nameByCreator) {
				return nameByCreator;
			} else if (PATH.includes('client') && nameByClient) {
				return nameByClient;
			} else return defaultName;
		},

		removeFileExtention(fileName) {
			return fileName.split('.').slice(0, -1).join('.');
		},

		/**
		 *
		 * @param {String} screenName
		 * @param {Array}
		 * @return {Array} currentImageCards from screen
		 */
		filterImageCardsScreenType(screenName, originImageCards) {
			return originImageCards.filter((element) => {
				return element.screenName === screenName;
			});
		},

		/**
		 * Sort currentImageCards
		 * @param {String} type type of sorting condition
		 * @param {Array} currentImageCards Sorting target array
		 */
		sortImageCards(type, currentImageCards) {
			let imageCardsArr = [...currentImageCards];
			console.log('sortting');
			if (type === '追加日') {
				imageCardsArr.sort((a, b) => this.sortByName(a, b));
			} else if (type === 'タイトル') {
				imageCardsArr.sort((a, b) => this.sortByDate(a, b));
			} else console.log('%c No valid option selected', 'color: red');
			return imageCardsArr;
		},

		sortByDate(a, b) {
			console.log(b.name, a.name);
			const nameA = a.name.toUpperCase(); // ignore upper and lowercase
			const nameB = b.name.toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
				return 1;
			}
			if (nameA > nameB) {
				return -1;
			}
			// names must be equal
			return 0;
		},

		sortByName(a, b) {
			return new Date(b.dateCreated) - new Date(a.dateCreated);
		},

		/**
		 * Download file with specific name
		 * @param {String} imgKey image key
		 * @param {String} imgName image name
		 * @returns
		 */
		async onDownload(imgKey, imgName) {
			this.$store.commit('setIsAppProcessing', true);

			if (!imgKey) {
				this.$store.commit('setIsAppProcessing', false);
				return;
			}
			let fileExtention = imgKey.split('.').pop();

			let imgUrl = await this._getPreviewImgUrl(imgKey, CommonConst.ONE_WEEK_SECONDS);

			// Using Axios get PDF file to browser and rename it before downloading
			// Reason: Can not rename PDF file directly by external URL just using <a> tag
			try {
				let pdfFile = await Axios.get(imgUrl, {
					headers: {
						'Content-Type': 'application/octet-stream',
					},
					responseType: 'blob',
				});
				let aTag = document.createElement('a');
				let url = window.URL.createObjectURL(pdfFile.data);
				console.log({ url });
				aTag.href = url;
				aTag.download = imgName + '.' + fileExtention;
				aTag.click();
			} catch (error) {
				console.log(error);
			}

			this.$store.commit('setIsAppProcessing', false);
		},

		onClose() {
			this.$emit('on-close-add-on-folder');
		},

		/**
		 * Get All co_create screen shot list
		 * @param {Numbers} projectId this project Id
		 * @returns screen shots array
		 */
		async _getAddOnFolderFiles(projectId) {
			try {
				// TODO: Call API method
				let response = await AddOnService.get(projectId);
				let files = response.data;
				console.log({ files });

				// // ClientApp only includes white_board and memo
				// if (this.isClientApp) {
				// 	files = files.filter((screenShot) =>
				// 		['white_board', 'memo'].includes(screenShot.screen_name)
				// 	);
				// }

				await this._filterImagesCard(files);
				return true;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		async updateAddOnFolderFiles(addOnId, body) {
			try {
				const response = await AddOnService.update(addOnId, body);

				if (response.status !== 200) {
					throw new Error('Update status failed');
				}

				console.log('%c Update AddOn successfully', 'color: green');
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		/**
		 * Get image preview url
		 * @param {String} imgKey Image key to get image url
		 * @param {String} expiration The lifetime of the url in seconds
		 * @returns image url
		 */
		async _getPreviewImgUrl(imgKey, expiration) {
			try {
				let response = await TransferService.get(imgKey, expiration);
				if (!response || response.status !== 200) {
					throw 'Get image url failed!';
				}

				console.log('%c Get image url successfully!', 'color: red');
				return response.data.link;
			} catch (error) {
				console.log(error);
			}
		},

		async _filterImagesCard(files) {
			if (files.length <= 0) return;

			let originImageCards = [];

			for (const element of files) {
				let imageCard = { ...AddOnFolderConst.IMAGE_INFO };

				imageCard.id = element['id'];

				imageCard.imageKey = element['image_key'];

				let name = this.checkImageName(
					element['name_by_creator'],
					element['name_by_client'],
					element['default_name']
				);
				imageCard.name = this.removeFileExtention(name);

				imageCard.dateCreated = moment(element['date_created']).format(
					'YYYY/MM/DD hh:mm'
				);

				imageCard.screenName =
					AddOnFolderConst.RESOURCE_SCREENS[`${element['screen_name']}`].name;
				imageCard.screenIcon =
					AddOnFolderConst.RESOURCE_SCREENS[`${element['screen_name']}`].icon;

				imageCard.deleteFlag = element['delete_flag'];

				if (element['screen_name'] !== 'memo' || element['user_id'] === this.userId) {
					originImageCards.push(imageCard);
				}
			}

			originImageCards = this.sortImageCards(this.sortType, originImageCards);

			this.originImageCards = originImageCards;
			this.currentImageCards = this.originImageCards;
		},
	},
	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		await this._getAddOnFolderFiles(this.projectId);

		this.$store.commit('setIsAppProcessing', false);
	},

	created() {
		socketClient.listen('new_data_transfer', async (data) => {
			let event_name =
				this.userId === this.projectInfo['responsible_user']
					? 'creator-memo-upload-file'
					: 'client-memo-upload-file';

			if (data.event_name === event_name) {
				this.$store.commit('setIsAppProcessing', true);

				await this._getAddOnFolderFiles(this.projectId);

				this.$store.commit('setIsAppProcessing', false);
			}
		});
		this.categories = this.isClientApp
			? AddOnFolderConst.CLIENT_CATEGORIES
			: AddOnFolderConst.CREATOR_CATEGORIES;
	},
};
