import { Compact } from 'vue-color';
import { Tools, ClipTools } from '../ToolTypes';
export default {
	name: 'Menu',
	components: {
		'color-picker': Compact,
	},
	data() {
		return {
			tools: Tools,
			clipTools: ClipTools,
			colors: {},
			potentialRoomName: '',
			strokeWidths: [1, 4, 7, 10],
			toolsWithDropdown: [
				Tools.Stroke,
				Tools.StrokeColor,
				Tools.FillColor,
				Tools.Select,
				Tools.Polygon,
			],
			toolNameMap: {
				[Tools.Pen]: 'Pen',
				[Tools.Stroke]: 'Width',
				[Tools.Line]: 'Line',
				[Tools.StrokeColor]: 'Stroke',
				[Tools.Arrow]: 'Arrow',
				[Tools.FillColor]: 'Fill',
				[Tools.Text]: 'Text',
				[Tools.Rectangle]: 'Rect',
				[Tools.Image]: 'Image',
				[Tools.Ellipse]: 'Ellipse',
				[Tools.Select]: 'Select',
				[Tools.Polygon]: 'Poly',
				[Tools.Pan]: 'Pan',
				[Tools.Undo]: 'Undo',
				[Tools.Clear]: 'Clear',
				[Tools.Redo]: 'Redo',
			},
			clipToolNameMap: {
				[ClipTools.Cut]: 'Cut',
				[ClipTools.Copy]: 'Copy',
				[ClipTools.Paste]: 'Paste',
				[ClipTools.Delete]: 'Delete',
			},
		};
	},
	props: {
		selectorOpen: {
			type: Boolean,
			default: false,
		},
		toolSelected: {
			type: String,
		},
		strokeColor: {
			type: String,
		},
		fillColor: {
			type: String,
		},
		strokeWidth: {
			type: Number,
		},
		roomName: {
			type: String,
		},
		isMobile: {
			type: Boolean,
			default: false,
		},
		scaleFactor: {
			type: Number,
			default: 1,
		},
	},
	methods: {
		clickedTool(toolName) {
			if (toolName === Tools.Image) {
				document.getElementById('imgLoader').click();
			} else {
				this.$emit('tool-selected', toolName);
			}
		},
		clickedClipTool(toolName) {
			this.$emit('clip-tool-selected', toolName);
		},
		updateColor(value) {
			this.$emit('update-color', value);
		},
		changeStrokeWidth(value) {
			this.$emit('update-stroke', value);
		},
		goToRoom() {
			this.$emit('go-to-room', this.potentialRoomName);
		},
		onUploadImage() {
			document.getElementById('imgLoader').onchange = (e) => {
				const reader = new FileReader();
				reader.onload = (event) => {
					const imgObj = new Image();
					imgObj.src = event.target.result;
					imgObj.onload = () => {
						this.$emit('img-uploaded', imgObj);
					};
				};
				reader.readAsDataURL(e.target.files[0]);
			};
		},
	},
	computed: {
		toolNames: function () {
			return Object.values(this.tools);
		},
		clipToolNames: function () {
			return Object.values(this.clipTools);
		},
		displayFactor: function () {
			return this.scaleFactor * 100 + '%';
		},
	},
	mounted() {
		this.onUploadImage();
	},
	created() {
		this.potentialRoomName = this.$route.params.room || '';
	},
};
