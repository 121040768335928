<template>
	<!-- This inline style is hacking that alow Vue understand style of tag, which Vue can not understand form css file-->
	<div style="display: flex; gap: 5px; padding: 4.5px 8px" class="tags-input">
		<div class="tags-input__tag" v-for="(tag, index) in tags" :key="'tag' + index">
			<div class="tags__label">{{ tag }}</div>
			<span @click="removeTag(index)"
				><img src="../../assets/CoCreation/delete_btn.svg"
			/></span>
		</div>
		<input
			class="tags-input__text"
			:class="{ 'tags-input__text--mobile': mobileView }"
			v-model="tagValue"
			@keyup.enter="addTag"
			placeholder="キーワードを追加"
		/>
	</div>
</template>

<script>
export default {
	data: () => {
		return {
			tagValue: '',
			tags: [],
			activeTag: null,
		};
	},
	props: {
		keyWordList: {
			type: Array,
		},
		mobileView: {
			type: Boolean,
		},
	},
	watch: {
		keyWordList(newVal) {
			if (newVal.length > 0) {
				this.tags = this.keyWordList;
			} else {
				this.tags = [];
			}
		},
	},
	methods: {
		addTag() {
			if (!this.tagValue == '') this.tags.push(this.tagValue);
			this.tagValue = '';
			this.$emit('on-search-image', this.tags);
		},
		removeTag(index) {
			this.tags.splice(index, 1);
			this.$emit('on-search-image', this.tags);
		},
	},
};
</script>

<style lang="scss" src="./TagInput.scss" scoped></style>
