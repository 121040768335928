const SELECTED_IMAGE = {
	url: '',
	id: '',
	comment: '',
};

const DEFAULT_SELECTED_IMGS = [
	{},
	{},
	{},
	{},
	{},
	{},
	{},
	{},
	{},
	{},
	{},
	{},
	{},
	{},
	{},
	{},
];
const DEFAULT_RESULT_IMGS = [];
const JOB_TYPES = [
	'すべて',
	'飲食店',
	'企業',
	'医療',
	'IT',
	'教育',
	'福祉',
	'娯楽',
	'不動産',
	'技術',
];

const PRODUCT_TYPES = [
	'すべて',
	'ロゴ',
	'名刺',
	'ショップカード',
	'フライヤー',
	'ポスター',
	'パンフレット',
	'リフレット',
];

const CATEGORIES = [
	{
		title: '制作物',
		items: PRODUCT_TYPES,
	},
	{
		title: '業種',
		items: JOB_TYPES,
	},
];

const MOBILE_MENU_ITEMS = [
	{
		icon: 'open_input_keyword_btn.svg',
	},
	{
		icon: 'open_hearing_text_area_btn.svg',
	},
	{
		icon: 'open_selected_imgs_btn.svg',
	},
	{
		icon: 'back_btn.svg',
	},
	{
		icon: 'refresh_btn.svg',
	},
	{
		icon: 'clear_btn.svg',
	},
];

const JOB_CATEGORI_IDX = 1;
const PRODUCT_CATEGORI_IDX = 0;
const SEARCH_RANGE = 10;
const RESULT_ID = 0;
const ROUND_ID = 1;
const CROSS_ID = 2;
const MAX_LENGTH = 16;

export default {
	SELECTED_IMAGE,
	DEFAULT_SELECTED_IMGS,
	DEFAULT_RESULT_IMGS,
	CATEGORIES,
	JOB_CATEGORI_IDX,
	PRODUCT_CATEGORI_IDX,
	SEARCH_RANGE,
	CROSS_ID,
	ROUND_ID,
	RESULT_ID,
	MAX_LENGTH,
	MOBILE_MENU_ITEMS,
};
