<template>
  <div class="pdf_container" id="pdf-memo">
    <div class="pdf_header">
      <p class="pdf_title">{{file_name}}</p>
      <p class="pdf_time">{{day}}</p>
    </div> 
    <div class="editor-horizontal-line"></div>
    <div class="screen-info-container">
      <div class="screen-icon">
        <img src="./Memo.png" />
      </div>
      <div class="screen-name">
        <label for="screen-name">メモ</label>
      </div>
    </div>
    <div id="memo-content">
    </div>
    <div class="logo_container">
      <img style="height: 8px;width: 44px; display:none" id="comode-logo" src="./ComodeIcon.png">
    </div>
  </div>
</template>

<script>
import * as dayjs from 'dayjs'
import html2pdf from 'html2pdf.js';
// import screenshotService from '@/services/API/screenshot.service'
const OPT = {
  margin: [0.4, 0.4, 0.5, 0.4],
  filename: 'メモ.pdf',
  pagebreak: { mode: 'avoid-all' },
  image: { type: 'jpeg', quality: 2 },
  html2canvas: { scale: 2 },
  jsPDF: {
    unit: 'in',
    format: 'a4',
    orientation: 'portrait',
  },
}
export default {
  name: 'MemoPdfExporter',
  props: {
    "content": {
      type: String,
      default: '',
    },
    "file_name": {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      day: '',
    }
  },
  methods: {
    async getRenderedPDFTemplate () {
      this.day = dayjs().format('YYYY/MM/DD HH:mm')
      let el = document.getElementById('memo-content')
      el.innerHTML = this.content
      let pdf = document.getElementById('pdf-memo')
      let comodeLogo = document.getElementById('comode-logo')
      pdf.style.display = 'block'
      let pdfURI = await html2pdf().from(pdf).set(OPT).toPdf().get('pdf').then((pdf) => {
        let total_page = pdf.internal.getNumberOfPages()
        try{
          for(let i = 0; i < total_page; i++) {
            pdf.setPage(i)
            pdf.addImage(comodeLogo.src, 'PNG', 7, 11.3, 0.6, 0.1)
          }
        } catch (exception){
          console.log(exception)
        }
      }).outputPdf('datauristring')
      pdf.style.display = 'none'
      return pdfURI
    }
  }
}
</script>

<style src="./MemoPdfExporter.css" scoped>
</style>
<style src="./MemoPdfExporter__global.css">
</style>