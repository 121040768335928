<template>
  <div
    class="menu-bar-container-safari"
    id="menu-bar-hiding"
  >
    <div class="menu-bar-items">
      <button @click="onShowShareScreen" class="menu-bar-button">
        <img
          :src="require(`@/assets/LiveScreen/UpExpand.svg`)"
          class="menu-bar-icon"
        />
      </button>
      <button
        class="menu-bar-button"
        v-for="(menuBarItem, menuIndex) in menuBarItems"
        :key="menuIndex"
        @click="onMinimumMenuBarItemClick(menuBarItem.id)"
        v-show="menuBarItem.name != 'ExpandShare'"
      >
        <img
          :src="require(`@/assets/LiveScreen/${menuBarItem.name}.svg`)"
          class="menu-bar-icon"
        />
      </button>
    </div>
    <div class="sharing-screen-notify sharing-screen-notify__margin">
      画面を共有しています
    </div>
  </div>
</template>

<script>
export default {
  name: "ShareControlerSafari",
  props: {
    menuBarItems: {
      type: Array,
      default: () => []
    },
  },
  methods:{
    onShowShareScreen(){
      this.$emit("on-show-share-screen");
    },
    onMinimumMenuBarItemClick(id){
      this.$emit("on-minimum-bar-item-click", id);
    }
  }
};
</script>

<style src="./ShareControllerSafari.css">
</style>