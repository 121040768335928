const LiveConst = {
	MINIMIZE_EXPAND_ID: 0,
	SESSION_EXPIRE_TIME: 120,
	MENU_BAR_ITEMS: [
		{
			name: 'UpExpand',
			isActive: false,
			id: 0,
		},
		{
			name: 'RecordScreen',
			isActive: false,
			id: 2,
		},
		{
			name: 'Mute-active',
			isActive: false,
			id: 3,
		},
		{
			name: 'ShowVideo',
			isActive: false,
			id: 4,
		},

		{
			name: 'Setting',
			isActive: false,
			id: 5,
		},
		{
			name: 'Leave',
			isActive: false,
			id: 6,
		},
	],
	EXTEND_MENU_BAR_ITEMS: [
		{
			label: '背景',
			name: 'Background',
			list: 'background',
			className: '',
		},
		{
			label: 'カメラを選択',
			name: 'SelectCamera',
			list: 'cameraList',
			className: '',
		},
		{
			label: 'マイクを選択',
			name: 'SelectMic',
			list: 'micList',
			className: '',
		},
		{
			label: 'スピーカーを選択',
			name: 'SelectSpeaker',
			list: 'speakerList',
			className: '',
		},
	],
	BACKGROUNDS: [
		{
			id: 0,
			label: 'None',
			value: 'none',
			focused: true,
		},
		{
			id: 1,
			label: 'ぼかし',
			value: 'blur',
			focused: false,
		},
	],
	LANGUAGE: 'en-US',
	DEPENDENT_ASSETS: 'CDN',
	VIDEO_QUALITY: {
		'90p': 0,
		'180p': 1,
		'360p': 2,
		'720p': 3,
	},
	MINI_VIEW: {
		width: 132,
		height: 74,
	},
	FULLSCREEN_MINI_VIEW: {
		width: 120,
		height: 67.5,
	},
	MOBILE_SIZE: {
		width: 90,
		height: 67.5,
	},
	RESOLUTION_RATIO: {
		'4:3': 4 / 3,
		'16:9': 16 / 9,
	},
	SHARING_SCREEN_ELEMENT: 'sharing-screen-element',
	RECEIVE_SHARING_SCREEN_ELEMENT: 'receive-sharing-screen-element',
	PARTICIPANT_CANVAS: 'participant-canvas',
	LIVE_SCREEN_BODY: 'live-screen-body',
	LIVE_SCREEN_FULLSIZE: 'live-screen__full_size',

	NOT_SUPPORT_SHARE_MOBILE:
		'co-mode Screenは、スマートフォンに対応しておりません\nパソコンでご利用ください',
	NOT_SUPPORT_SHARE_SAFARI:
		'co-mode Screenは、Safariに対応しておりません\nChromeやFirefoxでご利用ください',
	CREATOR_SHARING:
		'Co-mode Screen が開始されました\nCo-mode Screenを立ち上げてください',
	REQUEST_TALKING: 'Co-modeが開始されました\nCo-modeを立ち上げてください',

	TIME_OUT: 'Co-modeを終了します',
};

export default LiveConst;
