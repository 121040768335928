const EMOJIS = [
	'😀',
	'😃',
	'😄',
	'😁',
	'😆',
	'😅',
	'😂',
	'🤣',
	'🙂',
	'🙃',
	'😉',
	'😊',
	'😇',
	'😍',
	'😘',
	'😗',
	'😚',
	'😙',
	'😋',
	'😛',
	'😜',
	'😝',
	'🤑',
	'🤗',
	'🤔',
	'🤐',
	'😐',
	'😑',
	'😶',
	'😏',
	'😒',
	'🙄',
	'😬',
	'😌',
	'😔',
	'😪',
	'😴',
	'😷',
	'🤒',
	'🤕',
	'😵',
	'😎',
	'🤓',
	'😕',
	'😟',
	'🙁',
	'😮',
	'😯',
	'😲',
	'😳',
	'😦',
	'😧',
	'😨',
	'😰',
	'😥',
	'😢',
	'😭',
	'😱',
	'😖',
	'😣',
	'😞',
	'😓',
	'😩',
	'😫',
	'😤',
	'😡',
	'😠',
	'😈',
	'👿',
	'💀',
	'☠',
	'💩',
	'👹',
	'👺',
	'👻',
	'👽',
	'👾',
	'🤖',
	'😺',
	'😸',
	'😹',
	'😻',
	'😼',
	'😽',
	'🙀',
	'😿',
	'😾',
	'🙈',
	'🙉',
	'🙊',
	'💋',
	'💌',
	'💘',
	'💝',
	'💖',
	'💗',
	'💓',
	'💞',
	'💕',
	'💟',
	'💔',
	'💛',
	'💚',
	'💙',
	'💜',
	'💯',
	'💢',
	'💥',
	'💫',
	'💦',
	'💨',
	'🕳',
	'💣',
	'💬',
	'👁️‍🗨️',
	'🗨',
	'🗯',
	'💭',
	'💤',
	'👋',
	'🖐',
	'✋',
	'🖖',
	'👌',
	'🤘',
	'👈',
	'👉',
	'👆',
	'🖕',
	'👇',
	'👍',
	'👎',
	'✊',
	'👊',
	'👏',
	'🙌',
	'👐',
	'🙏',
	'💅',
	'💪',
	'👂',
	'👃',
	'👀',
	'👁',
	'👅',
	'👄',
];

export default {
	name: 'ReactionPicker',
	data() {
		return {
			emojis: EMOJIS,
		};
	},
	methods: {
		onEmojiPick(emoji) {
			this.$emit('on-emoji-pick', emoji);
		},
	},
};
