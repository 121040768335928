import ComodeAiConst from '@/constants/ComodeAiConst';
import DecisionConst from '@/constants/DecisionConst';

import MobilePreviewModal from '@/components/Modal/PreviewPredictImage/PreviewPredictImage.vue';
import SaveImageModal from '@/components/PreviewModal/ImagePreviewModal.vue';
import CoMoDeAIPdfExporter from '@/components/CoMoDeAIPdfExporter/CoMoDeAIPdfExporter.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';

import TransferService from '@/services/API/transfer.service';
import AddOnService from '@/services/API/add_on.service';
import OpenAIService from '@/services/API/openai.service';
import TranslateService from '@/services/API/translate.service';

import { mapState } from 'vuex';

export default {
	components: {
		MobilePreviewModal,
		SaveImageModal,
		CoMoDeAIPdfExporter,
		NotificationModal,
	},
	data: () => {
		return {
			screenName: ComodeAiConst.SCREEN_NAME,
			predictInput: '',
			nonePredict: true,
			predictionStatus: DecisionConst.PREDICTION_STATUS.init,
			PREDICTION_STATUS: DecisionConst.PREDICTION_STATUS,
			processValue: 0,
			resultImage: '',
			saveImage: {
				url: '',
				comment: '',
			},
			emptyText: ComodeAiConst.EMPTY_TEXT,
			placeHolder: ComodeAiConst.PLACE_HOLDER,
			buttonName: ComodeAiConst.PREDICT_BUTTON,
			previewModalId: 'preview-predict-modal-comode-ai',
			pdfContent: {},
			fileName: '',
			progressBarInterval: null,

			errorModalId: 'error-modal-id-comode-ai',
			errorModalContent:
				'現在Comode-AIはご利用いただけません。\n少し経ってからお試しください。',

			imageList: [],
			selectedIdx: 0,
		};
	},
	computed: {
		...mapState(['projectId', 'userId', 'projectInfo']),

		isCreator() {
			return this.userId === this.projectInfo.responsible_user;
		},

		mobileAndTabletCheck() {
			let check = false;
			//eslint-disable-next-line
			(function (a) {
				if (
					//eslint-disable-next-line
					/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk|iPhone|iPad|iPod/i.test(
						a
					) ||
					//eslint-disable-next-line
					/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
						a.substr(0, 4)
					)
				)
					check = true;
			})(navigator.userAgent || navigator.vendor || window.opera);
			return check;
		},
	},
	methods: {
		onClose() {
			this.$emit('on-close-ai-screen');
		},
		async onPredict() {
			this.intervalProgressBar();
			try {
				let translatedText = await TranslateService.get(this.predictInput);
				let imgResult = await OpenAIService.generateImg(
					translatedText.data.text,
					4
				);
				this.processValue = 100;
				imgResult.data.data.map((img) => {
					let resultImg = 'data:image/png;base64, ' + img.b64_json;
					this.imageList.push(resultImg);
				});
				setTimeout(() => {
					this.predictionStatus = this.PREDICTION_STATUS.finished;
				}, 1000);
			} catch (error) {
				if (!error.response) {
					this.errorModalContent = ComodeAiConst.NO_INTERNET
				}
				else {
					let errorData = error.response.data.error;
					if (errorData.code == "billing_hard_limit_reached") {
						this.errorModalContent = ComodeAiConst.BILLING_HARD_LIMIT_REACHED
					}
					else if (errorData.message.includes("text that is not allowed")) {
						this.errorModalContent = ComodeAiConst.NSFW_TEXT
					}
					else {
						this.errorModalContent = ComodeAiConst.GENERAL_ERROR
					}
				}
				this.$bvModal.show(this.errorModalId);
				clearInterval(this.progressBarInterval);
				this.resetValue();
			}
		},
		intervalProgressBar(processValue = 0) {
			this.nonePredict = false;
			this.processValue = processValue;
			this.predictionStatus = this.PREDICTION_STATUS.processing;
			this.imageList = [];
			this.progressBarInterval = setInterval(() => {
				if (this.processValue <= 25) {
					this.processValue += 3;
				} else if (this.processValue > 25 && this.processValue <= 50) {
					this.processValue += 1;
				} else if (this.processValue > 50 && this.processValue <= 70) {
					this.processValue += 2;
				} else if (this.processValue > 70) {
					clearInterval(this.progressBarInterval);
				}
			}, 500);
		},
		openPreviewModal(idx) {
			this.saveImage.url = this.imageList[idx];
			this.resultImage = this.imageList[idx];
			this.$bvModal.show(this.previewModalId);
		},

		async _uploadPdf(params) {
			try {
				let response = await TransferService.postPdf(params);
				if (!response || response.status !== 200) {
					throw 'Upload PDF failed!';
				}

				console.log('%c Upload PDF successfully!', 'color: green');
				let key = response.data.key;
				return key;
			} catch (error) {
				console.log(error);
			}
		},

		async onTransferUploadedFile(key, file_name) {
			let body = {
				doc_content: {
					project_id: this.projectId,
					user_id: this.userId,
					default_name: file_name,
					image_key: key,
					screen_name: 'comode_ai',
					name_by_client: '',
					name_by_creator: '',
					delete_flag: 0,
				},
			};
			try {
				let response = await AddOnService.post(body);
				if (response.status == 200) {
					console.log(`Done upload PDF ${file_name}`);
				}
			} catch (error) {
				console.log(`Upload PDF failed: ${error}`, 'color: red');
			}
		},

		async _toDataURL(url) {
			return new Promise((resolve, reject) => {
				var xhr = new XMLHttpRequest();
				xhr.onload = function () {
					var reader = new FileReader();
					reader.onloadend = function () {
						resolve(reader.result);
					};
					reader.readAsDataURL(xhr.response);
				};
				xhr.onerror = () => {
					reject({
						status: this.status,
						statusText: xhr.statusText,
					});
				};
				xhr.open('GET', url);
				xhr.responseType = 'blob';
				xhr.send();
			});
		},

		async onSaveToFolder(imageInfo) {
			this.$bvModal.hide(this.previewModalId);
			this.$store.commit('setIsAppProcessing', true);
			this.pdfContent = {
				url: await this._toDataURL(this.resultImage),
				comment: imageInfo.comment,
			};
			if (!this.mobileAndTabletCheck) {
				this.$refs.saveImageModal.clearComment();
			}
			let files = await AddOnService.get(this.projectId);
			let comode_ai_files = files.data.filter(
				(file) => file.screen_name === 'comode_ai'
			);
			let index = comode_ai_files.length + 1;
			this.fileName = '提案画像 ' + index;

			let pdf = await this.$refs.comodeAIPdfExporter.getRenderedPDFTemplate();
			let pdfParams = {
				content: pdf,
				project_id: this.projectId,
				file_name: this.fileName + '.pdf',
			};
			let pdfFileKey = await this._uploadPdf(pdfParams);
			await this.onTransferUploadedFile(pdfFileKey, this.fileName + '.pdf');

			this.$store.commit('setIsAppProcessing', false);
		},
		resetValue() {
			this.imageList = [];
			this.nonePredict = true;
			this.isImageSaved = new Array(4).fill(false);
			this.isShowBackdrop = new Array(4).fill(false);
			this.predictionStatus = this.PREDICTION_STATUS.init;
			this.processValue = 0;
		},
	},
};
