exports.Tools = {
	Pen: 'pen',
	Line: 'line',
	Arrow: 'arrow',
	Stroke: 'stroke',
	StrokeColor: 'stroke-color',
	FillColor: 'fill-color',
	Text: 'text',
	Image: 'image',
	Rectangle: 'rectangle',
	Ellipse: 'ellipse',
	Select: 'select',
	Pan: 'pan',
	Undo: 'undo',
	Redo: 'redo',
	ZoomIn: 'ZoomIn',
	ShowFactor: 'ShowFactor',
	ZoomOut: 'ZoomOut',
	// Clear: 'clear',
	// Save: 'Save',
};

exports.ClipTools = {
	Cut: 'cut',
	Paste: 'paste',
	Delete: 'delete',
};
