// import dragElement from './NotificationDragFunc';
import { AllowDrag } from '../../directives/HxAllowDrag/allowDrag.directive';
export default {
	name: 'RecordComfirmModal',
	directives: {
		AllowDrag,
	},
	props: {
		selectiveContents: {
			type: String,
		},
		selectiveTitle: {
			type: String,
		},
		isSelective: {
			type: Boolean,
		},
		selectiveModalId: {
			type: String,
		},
		yesButtonLabel: {
			type: String,
		},
		noButtonLabel: {
			type: String,
		},
	},
	methods: {
		onSelectYesClick() {
			this.$emit('on-confirm-yes');
			this.$bvModal.hide(`${this.selectiveModalId}`);
		},
		onSelectNoClick() {
			this.$emit('on-confirm-no');
			this.$bvModal.hide(`${this.selectiveModalId}`);
		},
	},
};
