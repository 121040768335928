var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"chatBoxContainer",attrs:{"id":"chatBoxContainer"}},_vm._l((_vm.displayedMessages),function(displayedMessage,index){return _c('div',{key:index,staticClass:"messageContainer"},[_c('div',{staticClass:"messageContainer__day"},[_c('div',{staticClass:"horizontalLine"}),_c('div',{staticClass:"dayAndWeekDay"},[_vm._v(_vm._s(_vm._f("messageDate")(displayedMessage.date)))])]),_vm._l((displayedMessage.messageContent),function(message,idx){return _c('div',{key:idx,ref:'message' + message.baseIdx,refInFor:true,staticClass:"messageContainer__content",class:[
				message.user_id === _vm.userId
					? 'messageContainer__content--sender'
					: 'messageContainer__content--receiver',
				{ 'target-scroll-message': message.isTargetScrollMessage },
				message.reactions.length > 0 && message.delete_flag !== 1
					? 'messageContainer__content--reactionExisted'
					: '',
				message.status === 'edited' && message.delete_flag !== 1
					? 'messageContainer__content--edited'
					: '',
			],on:{"mouseleave":function($event){(message.optionOpen = false), (message.active = false)},"mouseover":function($event){message.active = true}}},[_c('div',{staticClass:"messageWrapper",class:{
					'messageWrapper--sender': message.user_id === _vm.userId,
					'messageWrapper--receiver': message.user_id !== _vm.userId,
				}},[_c('div',{staticClass:"messageWrapper__inner",class:{
						'messageWrapper__inner--sender': message.user_id === _vm.userId,
						'messageWrapper__inner--receiver': message.user_id !== _vm.userId,
					},on:{"mouseover":function($event){message.active = true},"mouseleave":function($event){message.active = false}}},[(message.delete_flag !== 1)?_c('div',{staticClass:"messageOptionsContainer",class:{
							invisible: !message.active,
						},on:{"mouseover":function($event){message.active = true},"mouseleave":function($event){message.active = false;
							message.optionOpen = false;
							message.reactionOpen = false;
							_vm.revertPosition(message.baseIdx, 'moreOption');
							_vm.revertPosition(message.baseIdx, 'reactionPicker');}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEditMessageDone),expression:"isEditMessageDone"}],staticClass:"messageOptionsContainer__showBtn",class:{
								'messageOptionsContainer__showBtn--sender':
									message.user_id === _vm.userId,
							},on:{"click":function($event){message.optionOpen = true;
								_vm.handleElementPosition(message.baseIdx, 'moreOption');}}},[_c('img',{attrs:{"src":require(`@/assets/ForComponent/MessageIcon/3dot.svg`)}})]),_c('div',{ref:'reactionPicker' + message.baseIdx,refInFor:true,staticClass:"reactionsPicker",class:{
								'reactionsPicker--sender': message.user_id === _vm.userId,
								'reactionsPicker--receiver': message.user_id !== _vm.userId,
								invisible: !message.reactionOpen,
							},on:{"mouseleave":function($event){message.reactionOpen = false;
								message.active = true;
								_vm.revertPosition(message.baseIdx, 'reactionPicker');},"mouseover":function($event){message.reactionOpen = true;
								message.active = true;
								message.optionOpen = true;}}},[_c('ReactionPicker',{on:{"on-emoji-pick":function($event){_vm.onEmojiPick($event, message.baseIdx);
									message.reactionOpen = false;}}})],1),_c('div',{ref:'moreOption' + message.baseIdx,refInFor:true,staticClass:"messageOptionsContainer__content",class:{
								'messageOptionsContainer__content--sender':
									message.user_id === _vm.userId,
								'messageOptionsContainer__content--sender--file':
									message.user_id === _vm.userId && message.attachments.length > 0,
								'messageOptionsContainer__content--receiver':
									message.user_id !== _vm.userId,
								invisible: !message.optionOpen,
							}},[_c('div',{staticClass:"messageOptionsContainer__content--virtual"},[_c('div',{staticClass:"optionItem",on:{"click":function($event){message.reactionOpen = true;
										_vm.handleElementPosition(message.baseIdx, 'reactionPicker');}}},[_c('img',{staticClass:"optionItem__edit",attrs:{"src":require(`@/assets/ForComponent/MessageIcon/Reaction.svg`)}})]),_c('div',{staticClass:"optionItem",on:{"click":function($event){return _vm.onQuoteButton(message)}}},[_c('img',{attrs:{"src":require(`@/assets/ForComponent/MessageIcon/Quote.svg`)}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
										!(message.attachments.length > 0 || message.user_id != _vm.userId)
									),expression:"\n\t\t\t\t\t\t\t\t\t\t!(message.attachments.length > 0 || message.user_id != userId)\n\t\t\t\t\t\t\t\t\t"}],staticClass:"optionItem",on:{"click":function($event){return _vm.onEdit(message)}}},[_c('img',{attrs:{"src":require(`@/assets/ForComponent/MessageIcon/Edit.svg`)}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!(message.user_id != _vm.userId)),expression:"!(message.user_id != userId)"}],staticClass:"optionItem",on:{"click":function($event){return _vm.onDeleteMessage(message)}}},[_c('img',{attrs:{"src":require(`@/assets/ForComponent/MessageIcon/Delete.svg`)}})])])])]):_vm._e(),(message.message || message.quote || message.delete_flag === 1)?_c('div',{staticClass:"messageContent",class:{
							'messageContent--sender': message.user_id === _vm.userId,
							'messageContent--receiver': message.user_id !== _vm.userId,
							'boderRadius--br-none':
								message.showAvatar && message.user_id === _vm.userId,
							'boderRadius--bl-none':
								message.showAvatar && message.user_id !== _vm.userId,
							'messageContent--quote': message.quote,
							'messageContent--deleted': message.delete_flag === 1,
						}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(message.status === 'edited' && message.delete_flag !== 1),expression:"message.status === 'edited' && message.delete_flag !== 1"}],staticClass:"messageContent__dateModified"},[_vm._v(" "+_vm._s(_vm._f("messageDateModified")(message.date_modified))+" ")]),(message.quote && message.delete_flag !== 1)?_c('div',{staticClass:"messageContent__quoteWapper",class:{
								'--lightBoder':
									message.quote.user_id !== _vm.clientInfo['responsible_id'],
							}},[_c('img',{staticClass:"quote__userAvatar",attrs:{"src":[
									message.quote.user_id === _vm.clientInfo['responsible_id']
										? _vm.creatorAvatar
										: _vm.clientAvatar,
								]}}),_c('div',{staticClass:"quote__content"},[_c('div',{staticClass:"quote__message",domProps:{"innerHTML":_vm._s(
										_vm.filterMessage(
											message.isQuoteExpand
												? message.quote?.message
												: _vm.truncateLongText(message.quote?.message, 1000)
										)
									)}}),(message.quote && message.quote.attachments.length > 0)?_c('div',_vm._l((message.quote.attachments),function(acttachment,attachmentIdx){return _c('span',{key:attachmentIdx},[_vm._v(" "+_vm._s(acttachment.file_name)+" "),(attachmentIdx < message.quote.attachments.length - 1)?_c('span',[_vm._v(_vm._s(', '))]):_vm._e()])}),0):_vm._e(),(message.isQuoteOverLimit && message.delete_flag !== 1)?_c('div',{staticClass:"expandingMessage",attrs:{"id":'expanding-quote-' + message.baseIdx},on:{"click":function($event){_vm.onExpandQuote(message.baseIdx);
										_vm.scrollToElement(
											'expanding-quote-' + message.baseIdx,
											message.isQuoteExpand
										);}}},[_c('div',{staticClass:"expandingMessage__text"},[_vm._v(" "+_vm._s(message.isQuoteExpand ? '省略' : 'すべて見る')+" ")]),_c('div',{staticClass:"expandingMessage__icon"},[_vm._v(" "+_vm._s(message.isQuoteExpand ? '＜' : '＞')+" ")])]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"messageContent__text",class:{
								'messageContent__text--edited':
									message.id && _vm.selectedMessageId === message.id,
								'messageContent__text--quote':
									message.quote && message.delete_flag !== 1,
								'messageContent__text--deleted': message.delete_flag === 1,
							}},[(message.delete_flag !== 1)?_c('p',{domProps:{"innerHTML":_vm._s(
									_vm.filterMessage(
										message.isMessageExpand || !message.quote
											? message.message
											: _vm.truncateLongText(message.message, 1000)
									)
								)}}):_vm._e(),(message.delete_flag === 1)?_c('div',{staticClass:"messageContent__text--deleted"},[_c('span',{staticClass:"deletedText__userName"},[_vm._v(_vm._s(_vm.getUserName(message.user_id))+" ")]),_c('span',{staticClass:"deletedText__description"},[_vm._v(_vm._s(_vm.deletedMessageText))])]):_vm._e(),(
									message.quote &&
									message.delete_flag !== 1 &&
									message.isMessageOverLimit
								)?_c('div',{staticClass:"expandingMessage",attrs:{"id":'expanding-message-' + message.baseIdx},on:{"click":function($event){_vm.onExpandMessage(message.baseIdx);

									_vm.scrollToElement(
										'expanding-message-' + message.baseIdx,
										message.isMessageExpand
									);}}},[_c('div',{staticClass:"expandingMessage__text"},[_vm._v(" "+_vm._s(message.isMessageExpand ? '省略' : 'すべて見る')+" ")]),_c('div',{staticClass:"expandingMessage__icon"},[_vm._v(" "+_vm._s(message.isMessageExpand ? '＜' : '＞')+" ")])]):_vm._e()]),(message.attachments.length && message.delete_flag !== 1)?_c('div',{staticClass:"messageContent__quoteFile",class:{
								'messageContent__quoteFile--single': message.attachments.length === 1,
							}},_vm._l((message.attachments),function(file,attachmentIdx){return _c('div',{key:attachmentIdx,staticClass:"fileViewer__item fileViewer__item--quote",on:{"mouseover":function($event){_vm.showByKey = file.key},"mouseleave":function($event){_vm.showByKey = ''}}},[_c('img',{staticClass:"file file--quote",attrs:{"src":_vm.isImage(file.file_type) ? file.url : file.icon},on:{"click":function($event){return _vm.onAttachmentClick(message, file)}}}),_c('div',{staticClass:"file__name",class:{
										'file__name--sender': message.user_id == _vm.userId,
										'file__name--receiver': message.user_id != _vm.userId,
									}},[_vm._v(" "+_vm._s(_vm._f("shortQuoteFileName")(file.file_name))+" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByKey == file.key),expression:"showByKey == file.key"}],staticClass:"downloadBtn--background downloadBtn--quote"},[_c('button',{on:{"click":function($event){return _vm.downloadFile(file.url)}}},[_c('img',{staticClass:"downloadBtn__icon",attrs:{"src":require("@/assets/ForComponent/MessageIcon/ArrowDownGray.svg")}})])])])])}),0):_vm._e()]):_vm._e(),(
							message.attachments.length &&
							!message.quote &&
							message.delete_flag !== 1
						)?_c('div',{staticClass:"fileViewerContainer",class:{
							'fileViewerContainer--single': message.attachments.length === 1,
							'fileViewerContainer--excludeImg': !_vm.isImage(
								message.attachments[0].file_type
							),
							'boderRadius--br-none': message.showAvatar && message.user_id == _vm.userId,
							'boderRadius--bl-none': message.showAvatar && message.user_id != _vm.userId,
						}},_vm._l((message.attachments),function(file,attachmentIdx){return _c('div',{key:attachmentIdx,staticClass:"fileViewer",class:{
								'fileViewer--excludeImg': !_vm.isImage(file.file_type),

								'fileViewer--last':
									attachmentIdx === message.attachments.length - 1 &&
									attachmentIdx > 0 &&
									attachmentIdx % 2 === 0,
							},on:{"mouseover":function($event){_vm.showByKey = file.key},"mouseleave":function($event){_vm.showByKey = ''}}},[_c('SkeletonLoadingImage',{staticClass:"fileViewer__file",class:{
									'fileViewer__file--excludeImg': !_vm.isImage(file.file_type),
								},attrs:{"imageSrc":_vm.isImage(file.file_type)
										? file.data
											? file.data
											: file.url
										: file.icon,"imageCover":true},on:{"on-image-click":function($event){_vm.isImage(file.file_type)
										? _vm.previewAttrachments(file, message.attachments, message.user_id)
										: _vm.downloadFile(file.url)},"on-image-load-error":function($event){return _vm.onLoadImageError(file)}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByKey === file.key && !_vm.isImage(file.file_type)),expression:"showByKey === file.key && !isImage(file.file_type)"}],staticClass:"downloadBtn--background downloadBtn--background--excludeImg"},[_c('button',{on:{"click":function($event){return _vm.downloadFile(file.url)}}},[_c('img',{staticClass:"downloadBtn__icon",attrs:{"src":require("@/assets/ForComponent/MessageIcon/ArrowDownGray.svg")}})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isImage(file.file_type)),expression:"!isImage(file.file_type)"}],staticClass:"fileViewer__name"},[_vm._v(" "+_vm._s(_vm._f("shortFileName")(file.file_name))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByKey === file.key && _vm.isImage(file.file_type)),expression:"showByKey === file.key && isImage(file.file_type)"}],staticClass:"downloadBtn--background"},[_c('button',{on:{"click":function($event){return _vm.downloadFile(file.url)}}},[_c('img',{staticClass:"downloadBtn__icon",attrs:{"src":require("@/assets/ForComponent/MessageIcon/ArrowDownGray.svg")}})])])],1)}),0):_vm._e(),(message.delete_flag !== 1)?_c('div',{staticClass:"reactions"},[_c('Reactions',{attrs:{"reactions":message.reactions}})],1):_vm._e()])]),_c('div',{staticClass:"userAvatar"},[_c('div',{staticClass:"userAvatar__sendingTime",class:{
						invisible: !message.active && !_vm.mobileView,
					}},[_vm._v(" "+_vm._s(_vm._f("messageTime")(message.date_created))+" ")]),_c('div',{staticClass:"userAvatar__img",class:{
						'userAvatar__img--sender': message.user_id === _vm.userId,
						'userAvatar__img--receiver': message.user_id !== _vm.userId,
						invisible: !message.showAvatar,
					}},[_c('img',{staticClass:"userAvatar__img-icon",attrs:{"src":[
							message.user_id === _vm.projectInfo['responsible_user']
								? _vm.creatorAvatar
								: _vm.clientAvatar,
						]}})])])])}),_c('PreviewModal',{attrs:{"selectedMessageUserId":_vm.selectedMessageUserId,"selectiveModalId":_vm.previewModalId,"previewItems":_vm.previewItems,"selectedItem":_vm.selectedItem},on:{"selectedItem-change":_vm.onSelectedItemChange}}),_c('ComfirmModal',{attrs:{"selectiveModalId":_vm.comfirmModalId,"selectiveContents":_vm.selectiveContents,"selectiveTitle":_vm.selectiveTitle,"isSelective":true,"isSaveButtonClicked":_vm.isSaveButtonClicked},on:{"on-confirm-require":_vm.onConfirmDelete,"on-decline":_vm.onDecline}})],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }