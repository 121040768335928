const IMAGE_INFO = {
	id: undefined,
	imageKey: '',
	name: '',
	screenName: '',
	screenIcon: '',
	dateCreated: '',
	deleteFlag: undefined,
};

const RESOURCE_SCREENS = {
	co_create: { name: 'Co-mode Image', icon: 'co-create_icon.svg' },
	white_board: { name: 'Co-mode Canvas', icon: 'white_board_icon.svg' },
	memo: { name: 'Memo', icon: 'memo_icon.svg' },
	comode_ai: { name: 'Co-mode AI', icon: 'comode_ai_icon.svg' },
};

const CREATOR_SCREEN_TYPES = [
	'すべて',
	'Co-mode Image',
	'Co-mode Canvas',
	'Memo',
	'Co-mode AI',
];
const CLIENT_SCREEN_TYPES = ['すべて', 'Co-mode Canvas', 'Memo', 'Co-mode AI'];
const SORT_TYPES = ['追加日', 'タイトル'];

const CREATOR_CATEGORIES = [
	{
		title: 'アドオン',
		items: CREATOR_SCREEN_TYPES,
	},
	{
		title: '並び替え',
		items: SORT_TYPES,
	},
];

const CLIENT_CATEGORIES = [
	{
		title: 'アドオン',
		items: CLIENT_SCREEN_TYPES,
	},
	{
		title: '並び替え',
		items: SORT_TYPES,
	},
];

const MOBILE_MENU_ITEMS = [
	{
		icon: 'open_input_keyword_btn.svg',
	},
	{
		icon: 'open_hearing_text_area_btn.svg',
	},
	{
		icon: 'open_selected_imgs_btn.svg',
	},
	{
		icon: 'back_btn.svg',
	},
	{
		icon: 'refresh_btn.svg',
	},
	{
		icon: 'clear_btn.svg',
	},
];

const SCREEN_TYPE_IDX = 0;
const SORT_TYPE_IDX = 1;

export default {
	IMAGE_INFO,
	SORT_TYPES,
	CREATOR_CATEGORIES,
	CLIENT_CATEGORIES,
	MOBILE_MENU_ITEMS,
	SCREEN_TYPE_IDX,
	SORT_TYPE_IDX,
	RESOURCE_SCREENS,
};
