import ApiService from './api.service';

const serviceURL = 'message';
const MessageService = {
	/**
	 * get message
	 * @param {Integer} projectId: Project ID
	 * @returns success array of  messages
	 */
	getMessage(projectId, limitMessages, lastMessageIndex) {
		return ApiService.get(
			`${serviceURL}`,
			`${projectId}/${limitMessages}/${lastMessageIndex}`
		);
	},
	get_unseen_message(projectId, userId) {
		return ApiService.get(`${serviceURL}/get_unseen_messages/${projectId}`, userId);
	},
	getAll(projectId) {
		return ApiService.get(`${serviceURL}`, projectId);
	},
};

export default MessageService;
