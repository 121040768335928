import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import { mapState } from 'vuex';
import { AllowDrag } from '@/directives/HxAllowDrag/allowDrag.directive';
import MemoService from '@/services/API/memo.service';
import TextEditor from '@/components/TextEditor/TextEditor.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';

import ConstValue from '@/constants/MemoConst.js';
import { checkMobileScreen } from '@/store/modules/ScreenWidth';

import AddOnService from '@/services/API/add_on.service';
import socketClient from '@/services/SOCKET';

export default {
	name: 'MemoScreen',
	components: {
		ScreenHeader,
		TextEditor,
		NotificationModal,
	},
	props: {
		isMemoScreenMinimum: Boolean,
	},
	directives: {
		AllowDrag,
	},
	data() {
		return {
			/**
			 * @type {Integer}
			 */
			memoId: undefined,
			/**
			 * setTimeout object
			 * @type {Object}
			 */
			saveTimeout: undefined,
			screenIcon: 'memo-icon',
			screenName: '自分メモ',
			updatedContent: '',
			initContent: '',
			isCreatingMemo: false,

			//NotificationModal Save Content
			saveDecisionContentModalId: 'save-memo-confirm-modal',
			saveDecisionContentModalContents: checkMobileScreen()
				? '編集内容を「 File 」 に\n保存しますか？'
				: '編集内容を「 File 」 に保存しますか？',

			plainModalId: 'memo-save-pdf-modal',
			plainContents: '保存しました',
		};
	},

	computed: {
		// Get projectId, userId from store
		...mapState(['projectId', 'userId', 'projectInfo']),
	},

	watch: {
		async userId(newUserId) {
			await this._getMemo(this.projectId, newUserId);
		},

		/**
		 * reset memo size when activate full mode
		 * @param {Boolean} isMemoScreenMinimum
		 */
		isMemoScreenMinimum(isMemoScreenMinimum) {
			if (!isMemoScreenMinimum) {
				this.resetMemoSize();
			}
		},
	},

	methods: {
		getMemoByteSize(content) {
			return new Blob([content]).size;
		},

		onCloseButonClick() {
			this.$emit('on-close-memno');
		},

		onExpandButtonClick() {
			this.$emit('on-switch-minimun-mode', {
				isMemoScreenMinimum: false,
			});
		},

		/**
		 *
		 * @param {Integer} newElWidth
		 * @param {Integer} newElHeight
		 */
		checkOverSize(newElWidth, newElHeight) {
			const screenWidth = window.innerWidth;
			const screenHeight = window.innerHeight;
			const maxSizeRatio = 0.6;

			const maxWidth = maxSizeRatio * screenWidth;
			const maxHeight = maxSizeRatio * screenHeight;
			const minWidth = 230;
			const minHeight = 180;

			if (
				(newElWidth > maxWidth && newElHeight > maxHeight) ||
				(newElWidth < minWidth && newElHeight < minHeight)
			)
				return 'both';
			else if (newElWidth > maxWidth || newElWidth < minWidth) return 'width';
			else if (newElHeight > maxHeight || newElHeight < minHeight) return 'height';
			else return 'none';
		},

		handleEditorPosition() {
			const $this = this;
			const textEditorRef = this.$refs.textEditor;

			const el = document.querySelector('#draggable-memo-screen');

			let isResizing = false;

			el.addEventListener('mousedown', mousedown);

			function mousedown(e) {
				window.addEventListener('mousemove', mousemove);
				window.addEventListener('mouseup', mouseup);
				document.querySelector('html').style.userSelect = 'none';

				let prevX = e.clientX;
				let prevY = e.clientY;

				function mousemove(e) {
					if (!isResizing) {
						// allow drag by header
						if (
							e.target.className.includes('text-editor-image') ||
							[
								'editor-content__overlay',
								'is-empty',
								'',
								'show-minimal-toolbar-button',
								'function-icon',
								'editor-content __box-shadow'
							].includes(e.target.className)
						)
							return;

						let newX = prevX - e.clientX;
						let newY = prevY - e.clientY;

						const rect = el.getBoundingClientRect();

						el.style.left = rect.left - newX + 'px';
						el.style.top = rect.top - newY + 'px';

						prevX = e.clientX;
						prevY = e.clientY;
					}
				}

				function mouseup() {
					window.removeEventListener('mousemove', mousemove);
					window.removeEventListener('mouseup', mouseup);
					document.querySelector('html').style.userSelect = 'auto';
				}
			}

			const resizers = document.querySelectorAll('.resizer');
			let currentResizer;

			for (let resizer of resizers) {
				const mousedown = (e) => {
					currentResizer = e.target;
					isResizing = true;

					let prevX = e.clientX;
					let prevY = e.clientY;

					window.addEventListener('mousemove', mousemove);
					window.addEventListener('mouseup', mouseup);
					document.querySelector('html').style.userSelect = 'none';

					function mousemove(e) {
						const rect = el.getBoundingClientRect();

						if (currentResizer.classList.contains('bottom-right')) {
							let newElWidth = rect.width - (prevX - e.clientX);
							let newElHeight = rect.height - (prevY - e.clientY);
							const overSizing = $this.checkOverSize(newElWidth, newElHeight);

							if (overSizing === 'both') {
								console.log('both');
							} else if (overSizing === 'width') {
								el.style.height = newElHeight + 'px';
							} else if (overSizing === 'height') {
								el.style.width = newElWidth + 'px';
							} else if (overSizing === 'none') {
								el.style.width = newElWidth + 'px';
								el.style.height = newElHeight + 'px';
							}
						} else if (currentResizer.classList.contains('bottom-left')) {
							let newElWidth = rect.width + (prevX - e.clientX);
							let newElHeight = rect.height - (prevY - e.clientY);
							const overSizing = $this.checkOverSize(newElWidth, newElHeight);

							if (overSizing === 'both') {
								console.log('both');
							} else if (overSizing === 'width') {
								el.style.height = newElHeight + 'px';
							} else if (overSizing === 'height') {
								el.style.width = newElWidth + 'px';
								el.style.left = rect.left - (prevX - e.clientX) + 'px';
							} else if (overSizing === 'none') {
								el.style.width = newElWidth + 'px';
								el.style.height = newElHeight + 'px';
								el.style.left = rect.left - (prevX - e.clientX) + 'px';
							}
						} else if (currentResizer.classList.contains('top-right')) {
							let newElWidth = rect.width - (prevX - e.clientX);
							let newElHeight = rect.height + (prevY - e.clientY);
							const overSizing = $this.checkOverSize(newElWidth, newElHeight);

							if (overSizing === 'both') {
								console.log('both');
							} else if (overSizing === 'width') {
								el.style.height = newElHeight + 'px';
								el.style.top = rect.top - (prevY - e.clientY) + 'px';
							} else if (overSizing === 'height') {
								el.style.width = newElWidth + 'px';
							} else if (overSizing === 'none') {
								el.style.width = newElWidth + 'px';
								el.style.height = newElHeight + 'px';
								el.style.top = rect.top - (prevY - e.clientY) + 'px';
							}
						} else {
							let newElWidth = rect.width + (prevX - e.clientX);
							let newElHeight = rect.height + (prevY - e.clientY);
							const overSizing = $this.checkOverSize(newElWidth, newElHeight);

							if (overSizing === 'both') {
								console.log('both');
							} else if (overSizing === 'width') {
								el.style.height = newElHeight + 'px';
								el.style.top = rect.top - (prevY - e.clientY) + 'px';
							} else if (overSizing === 'height') {
								el.style.width = newElWidth + 'px';
								el.style.left = rect.left - (prevX - e.clientX) + 'px';
							} else if (overSizing === 'none') {
								el.style.width = newElWidth + 'px';
								el.style.height = newElHeight + 'px';
								el.style.top = rect.top - (prevY - e.clientY) + 'px';
								el.style.left = rect.left - (prevX - e.clientX) + 'px';
							}
						}

						prevX = e.clientX;
						prevY = e.clientY;
						textEditorRef.setToolBarPosition();
					}

					function mouseup() {
						window.removeEventListener('mousemove', mousemove);
						window.removeEventListener('mouseup', mouseup);
						document.querySelector('html').style.userSelect = 'auto';

						isResizing = false;
					}
				};

				resizer.addEventListener('mousedown', mousedown);
			}
		},

		async onUpdateMemo(content) {
			this.updatedContent = content;

			if (this.isCreatingMemo) return;

			if (content && !this.memoId) {
				this.isCreatingMemo = true;
				await this._createMemo(content);
				return;
			}

			// Clear previous timeOut variable
			clearTimeout(this.saveTimeout);

			// Start new timeOut
			this.saveTimeout = setTimeout(async () => {
				// save text after 1 second
				let memoByteSize = this.getMemoByteSize(content);
				// Check limit content size.
				if (memoByteSize > ConstValue.LIMIT_SIZE) {
					//TODO: Notify here
					return;
				}
				if (this.memoId) await this._updateMemo(this.memoId, content);
			}, ConstValue.DELAY_TIME);
		},

		/**
		 * Get Memo data
		 * @param {Integer} projectId Project ID
		 * @param {Integer} userId Creator ID
		 */
		async _getMemo(projectId, userId) {
			try {
				let response = await MemoService.search({
					params: {
						project_id: projectId,
						user_id: userId,
					},
				});

				if (!response || response.status !== 200) {
					throw 'Get Memo failed';
				}
				console.log('%c Get Memo successfully', 'color: green');
				this.initContent = this.updatedContent = response.data['content'];
				this.memoId = response.data['id'];
			} catch (error) {
				console.log(`%c Get Memo failed: ${error}`, 'color: red');
			}
		},

		/**
		 * Create new Memo
		 * @param {String} content Memo content
		 */
		async _createMemo(content) {
			try {
				let response = await MemoService.post({
					doc_content: {
						content: content,
						project_id: this.projectId,
						user_id: this.userId,
					},
				});

				if (!response || response.status !== 200) {
					throw 'Create Memo failed';
				}

				console.log('%c Create Memo successfully', 'color: green');
				this.memoId = response.data['id'];
				this.isCreatingMemo = false;
			} catch (error) {
				console.log(`%c Create Memo failed: ${error}`, 'color: red');
			}
		},

		/**
		 * Update Memo
		 * @param {Integer} memoId Memo ID
		 * @param {String} content Memo content
		 */
		async _updateMemo(memoId, content) {
			try {
				let response = await MemoService.update(memoId, {
					doc_content: {
						content: content,
					},
				});

				if (!response || response.status !== 200) {
					throw 'Update memo failed';
				}

				console.log('%c Update memo successfully', 'color: green');
			} catch (error) {
				console.log(`%c Update memo failed: ${error}`, 'color: red');
			}
		},
		async onTransferUploadedFile(key, file_name) {
			this.$store.commit('setIsAppProcessing', true);

			this.pdfFileKey = key.pdfFileKey;

			let body = {
				doc_content: {
					project_id: this.projectId,
					user_id: this.userId,
					default_name: file_name,
					image_key: this.pdfFileKey,
					screen_name: 'memo',
					name_by_client: '',
					name_by_creator: '',
					delete_flag: 0,
				},
			};
			try {
				let response = await AddOnService.post(body);
				if (response.status == 200) {
					console.log(`Done upload PDF ${file_name}`);
					this.$bvModal.show(`${this.plainModalId}`);
				}
				this.sendDataTransfer();
			} catch (error) {
				console.log(`Upload PDF failed: ${error}`, 'color: red');
			}

			this.$store.commit('setIsAppProcessing', false);
		},

		onSaveButtonClick() {
			this.$bvModal.show(this.saveDecisionContentModalId);
		},
		async onSaveMemoContent() {
			this.$store.commit('setIsAppProcessing', true);

			await this.$refs.textEditor.savePdf();

			this.$store.commit('setIsAppProcessing', false);
		},

		sendDataTransfer() {
			let event_name =
				this.userId === this.projectInfo['responsible_user']
					? 'creator-memo-upload-file'
					: 'client-memo-upload-file';

			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: event_name,
				},
				room: this.projectId,
			});
		},
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		this.handleEditorPosition();
		if (this.userId) await this._getMemo(this.projectId, this.userId);

		this.$store.commit('setIsAppProcessing', false);

		// Save text before unload page
		window.addEventListener('beforeunload', async () => {
			this.$store.commit('setIsAppProcessing', true);

			await this._updateMemo(this.memoId, this.updatedContent);

			this.$store.commit('setIsAppProcessing', false);
		});
	},

	async beforeDestroy() {
		this.$store.commit('setIsAppProcessing', true);

		if (this.memoId) await this._updateMemo(this.memoId, this.updatedContent);

		this.$store.commit('setIsAppProcessing', false);
	},
};
