import ApiService from './api.service';

const serviceURL = 'client';
const ClientService = {
	/**
	 * Acquire Client by client ID
	 * @param {Integer} clientId
	 * @returns {Object} project details
	 */
	get(clientId) {
		return ApiService.get(`${serviceURL}`, `${clientId}`);
	},
};

export default ClientService;
