import { mapState } from 'vuex';

export default {
	name: 'Reaction',
	props: {
		reactions: Array,
	},
	data() {
		return {
			reactionsDisplayed: [],
		};
	},
	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
		]),
	},

	watch: {
		reactions: {
			handler: function (reactions) {
				this._filterReations(reactions);
			},
			deep: true,
		},
	},
	methods: {
		onReaction() {
			console.log('on reaction click');
		},

		/**
		 * Filter Raw reactions
		 * @param {Array} reactions Raw Reaction Array
		 * @returns Reaction displayed
		 */
		_filterReations(reactions) {
			let countReactions = {};

			if (reactions.length === 0) {
				this.reactionsDisplayed = [];
				return;
			}

			// Pick emoji count
			reactions.forEach((reaction) => {
				countReactions[reaction.emoji] = {
					count:
						((countReactions[reaction.emoji] &&
							countReactions[reaction.emoji].count) ||
							0) + 1,
				};
			});

			reactions.forEach((reaction) => {
				let userId = reaction.clientId || reaction.creatorId;

				// pick userIds
				!countReactions[reaction.emoji].userIds
					? (countReactions[reaction.emoji].userIds = [userId])
					: countReactions[reaction.emoji].userIds.push(userId);

				// pick userNames
				!countReactions[reaction.emoji].userNames
					? (countReactions[reaction.emoji].userNames = [
							reaction.userName,
						])
					: countReactions[reaction.emoji].userNames.push(
							reaction.userName
						);
			});

			let reactionsDisplayed = [];
			for (const [key, value] of Object.entries(countReactions)) {
				reactionsDisplayed.push({
					emoji: key,
					count: value.count,
					userIds: value.userIds,
					isToolTipVisible: false,
					userNames: value.userNames.sort(),
				});
			}

			this.reactionsDisplayed = reactionsDisplayed;
		},
	},

	created() {
		this._filterReations(this.reactions);
	},
};
