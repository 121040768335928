import SideBarLeft from '@/components/SideBarLeft/SideBarLeft.vue';
import SideBarRight from '@/components/SideBarRight/SideBarRight.vue';
import DrawerLeft from '@/components/DrawerLeft/DrawerLeft.vue';
import DrawerRight from '@/components/DrawerRight/DrawerRight.vue';
import ComodeAI from '@/views/General/ComodeAI/ComodeAI.vue';
import CoCreateScreen from '@/views/General/CoCreateScreen/CoCreateScreen.vue';
import LiveDesign from '@/views/General/LiveDesignScreen/LiveDesignScreen.vue';
import AddOnFolder from '@/views/General/AddOnFolderScreen/AddOnFolderScreen.vue';
import ShareScreen from '@/views/General/ShareScreen/ShareScreen.vue';
import ChatScreen from '@/views/General/ChatScreen/ChatScreen.vue';
import LiveScreen from '@/views/General/LiveScreen/LiveScreen.vue';
import Settlement from '@/views/ClientApp/SettlementScreen/SettlementScreen.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import MemoScreenMinimum from '../General/MemoScreen/MemoScreenMinimum.vue';
import MemoScreenFull from '../General/MemoScreen/MemoScreenFull.vue';
import CountDown from '@/components/CountDown/CountDown.vue';

import HearingService from '@/services/API/hearings.service';
import EstimateParticularService from '@/services/API/estimate_particular.service';
import ScheduleService from '@/services/API/schedule.service';
import DecisionService from '@/services/API/decision.service';
import DeliveryService from '@/services/API/delivery.service.js';
import ProjectService from '@/services/API/project.service';
import UserService from '@/services/API/user.service';
import ClientService from '@/services/API/client.service';
import ManagementMasterService from '@/services/API/management_master.service';
import socketClient from '@/services/SOCKET';
import CreativeService from '@/services/API/creative.service';
import DecisionDetailService from '@/services/API/decision_detail.service';
import ProposalGeneralService from '@/services/API/proposal_general.service';
import ReProposalGeneralService from '@/services/API/reproposal_general.service';
import TransferService from '@/services/API/transfer.service';
import InvoiceService from '@/services/API/invoice.service';
import AddCostDetailService from '@/services/API/add_cost_details.service';

import DecisionConst from '@/constants/DecisionConst';
import SideBarRightConst from '@/constants/SideBarRight';
import CommonConst from '@/constants/CommonConst';
import clientAppSocketListening from '@/mixin/clientAppSocketListening';
import updateProjectStatusMixin from '@/mixin/updateProjectStatus';

import { mapState } from 'vuex';
import LiveConst from '@/constants/LiveConst';

const HOST_EXIT_SHARING = 'クリエイターがCo-mode Screenを終了しました';
const HOST_EXIT_MEETING = 'クリエイターがCo-modeを終了しました';
const SHARE_TIME_OUT = 'Co-mode Screenを終了します';
const NOT_SHARING = 'クリエイターが共有しておりません\nCo-mode Screenを終了します';
const DUPLICATE_USER =
	'別ウインドウで既にCo-modeが起動している可能性があります\n 他のウインドウを閉じてください';
const ADD_COST_SCREEN_ID = 6;
const REPROPOSAL_SCREEN_ID = 7;
export default {
	name: 'ClientApp',
	mixins: [clientAppSocketListening, updateProjectStatusMixin],
	components: {
		SideBarLeft,
		SideBarRight,
		DrawerLeft,
		DrawerRight,
		ShareScreen,
		LiveScreen,
		// MemoScreen,
		NotificationModal,
		'live-design-screen': LiveDesign,
		'co-create-screen': CoCreateScreen,
		'add-on-folder-screen': AddOnFolder,
		'chat-screen': ChatScreen,
		'comode-ai-screen': ComodeAI,
		'memo-screen-full': MemoScreenFull,
		'settlement-screen': Settlement,
		MemoScreenMinimum,
		CountDown,
	},
	data() {
		return {
			projectId: undefined,
			token: undefined,
			clientAppLink: '',
			projectInfo: {},
			userId: undefined,
			userImage: '',

			liveScreenPlainModalId: 'live-screen-plain-modal-id',
			liveScreenPlainContents: 'ミーティングに参加しました',
			liveScreenSelectiveModalId: 'live-screen-selective-modal-id',
			liveScreenSelectiveContents: 'Co-modeを終了しますか？',

			shareScreenPlainModalId: 'share-screen-plain-modal-id',
			shareScreenPlainContents: 'クリエイターがCo-mode Screenを終了しました。',
			shareScreenSelectiveModalId: 'share-screen-selective-modal-id',
			shareScreenSelectiveContents: 'Co-mode Screenを終了しますか？',

			coCreatePlainModalId: 'co-create-plain-modal-id',
			coCreatePlainContents: 'クリエイターがCo-mode Imageを終了しました',

			comodeMode: '',
			comodeRequestModalId: 'comode-request-modal-id',
			comodeRequestContents: '',

			mobileView: false,
			isLogOutModalShowed: false,
			showQuestionNotification: false,
			showCreativeNotification: false,

			//Show・Hide Drawer Left
			isShowDrawerLeft: false,

			//Show・Hide Drawer Right
			isShowDrawerRight: false,

			isAddOnScreenShow: false,

			//Show・Hide Live Screen
			isLiveScreenMinimum: true,
			isShowLiveScreen: false,
			isLiveScreenSessionJoined: false,

			//Show・Hide memo screen
			isShowMemoScreen: false,
			isMemoScreenMinimum: false,

			// Show・Hide Share Screen
			isShareScreenMinimum: false,
			isShowShareScreen: false,
			isShareScreenExpandMode: false,
			isShareScreenHiding: false,

			isRightSideBarScreenShow: false,

			notiTitle: '',
			notiBody: '',
			notiType: '',
			rightMenuScreen: '',

			onChat: false,
			messageEvent: 0,
			unSeenMessages: false,

			settlementItem: {
				route: `settlement`,
				isConfirm: false,
				disabled: false,
			},
			documentItem: {
				route: `download`,
				name: this.$t('general.sideBarLeftBtn.download'),
				icon: 'Download.svg',
				isConfirm: false,
				disabled: false,
				inProcessing: false,
			},
			screenFinishedItem: {
				route: `finish`,
				disabled: false,
			},
			sideBarLeftItems: [
				// add public route 27/7/2023 HoaDQ
				{
					route: `schedule`,
					name: this.$t('general.sideBarLeftBtn.schedule'),
					icon: 'Schedule.svg',
					isConfirm: false,
					disabled: true,
					inProcessing: false,
					isHidden: false,
				},
				{
					route: `hearing`,
					name: 'キックオフミーティング',
					icon: 'Estimate.svg',
					isConfirm: false,
					disabled: true,
					inProcessing: false,
					isHidden: false,
				},
				// {
				// 	route: `estimate`,
				// 	name: this.$t('general.sideBarLeftBtn.estimate'),
				// 	icon: 'Estimate.svg',
				// 	isConfirm: false,
				// 	disabled: true,
				// 	inProcessing: false,
				// },
				{
					route: `decision`,
					name: 'コンセプトデザイン',
					icon: 'Decision.svg',
					isConfirm: false,
					disabled: true,
					inProcessing: false,
				},
				// {
				// 	route: `creative`,
				// 	name: 'クリエイティブ',
				// 	icon: 'Creative.svg',
				// 	isConfirm: false,
				// 	disabled: true,
				// 	inProcessing: false,
				// },
				// {
				// 	route: `proposal`,
				// 	name: 'ご提案',
				// 	icon: 'Proposal.svg',
				// 	isConfirm: false,
				// 	disabled: true,
				// 	inProcessing: false,
				// },
				// {
				// 	route: `addcost`,
				// 	name: '再提案設定',
				// 	isConfirm: false,
				// 	disabled: true,
				// 	inProcessing: false,
				// 	isSubItem: true,
				// 	isHidden: true,
				// },
				// {
				// 	route: `reproposal`,
				// 	name: '再提案',
				// 	isConfirm: false,
				// 	disabled: true,
				// 	inProcessing: false,
				// 	isSubItem: true,
				// 	isHidden: true,
				// },
				{
					route: `delivery`,
					name: 'コンセプト仕上げ',
					icon: 'Creative.svg',
					isConfirm: false,
					disabled: true,
					inProcessing: false,
					isHidden: false,
				},
			],
			sideBarRightItems: [
				{
					name: SideBarRightConst.CHAT_SCREEN_NAME,
					tooltipText: 'チャット',
					iconName: 'Chat.svg',
					disabled: false,
				},
				{
					name: SideBarRightConst.LIVE_DESIGN_SCREEN_NAME,
					tooltipText: 'ホワイトボード',
					iconName: 'LiveDesign.svg',
					disabled: false,
				},
				{
					name: SideBarRightConst.COMODE_AI_SCREEN_NAME,
					tooltipText: 'AI',
					iconName: 'ComodeAi.svg',
					disabled: false,
				},
				{
					name: SideBarRightConst.CO_CREATE_SCREEN_NAME,
					tooltipText: 'Adobe',
					iconName: 'CoCreate.svg',
				},
				{
					name: SideBarRightConst.MEMO_SCREEN_NAME,
					tooltipText: '自分メモ',
					iconName: 'Memo.svg',
					disabled: false,
				},

				{
					name: SideBarRightConst.ADD_ON_FOLDER_SCREEN_NAME,
					tooltipText: 'ファイル保存',
					iconName: 'FolderAddOn.svg',
					disabled: false,
				},
				{
					name: SideBarRightConst.SETTLEMENT_SCREEN_NAME,
					tooltipText: '御請求書',
					iconName: 'Download.svg',
					disabled: false,
				},
			],
			isShowSettingContainer: true,

			screenDatas: {
				hearing: {},
				schedule: {},
				estimateParticular: {},
				decision: {},
				creative: {},
				proposalGeneral: {},
				addCostDetails: [],
				reproposalGeneral: {},
				deliveries: [],
				settlement: {},
			},

			isSafari: !!window.safari,

			shareScreenMenuBarItems: [],

			counterDate: null,
		};
	},
	async created() {
		let isRegiteredClient = false;

		this.projectId = parseInt(this.$route.params.projectId);
		// Set projectId in store as current ProjectId
		this.$store.commit('setProjectId', this.projectId);

		this.token = this.$route.params.token;
		this.$store.commit('setToken', this.token);

		let projectInfo = await this._getProject(this.projectId);
		this.userId = projectInfo['client_id'];
		this.$store.commit('setProjectInfo', projectInfo);
		this.$store.commit('setUserId', this.userId);
		document.title = this.projectInfo['project_name'] + '｜CoMoDe';
		//Check if client registered account
		let userInfo = await this._getUser(this.userId);

		if (Object.keys(userInfo).length === 0) {
			this.isShowSettingContainer = false;
			isRegiteredClient = false;
		} else {
			this.isShowSettingContainer = true;
			isRegiteredClient = true;
		}

		this.userImage = await this._getPreviewImgUrl(userInfo['picture_key'], '31536000');
		if (!this.userImage) {
			this.userImage = 'ClientAvatar.png';
		}

		let clientInfo = await this._getClient(this.userId);
		this.$store.commit('setClientInfo', clientInfo);
		this.$store.commit(
			'setClientAvatar',
			this.userImage == 'ClientAvatar.png'
				? CommonConst.CLIENT_AVATAR_URL
				: this.userImage
		);

		let creatorInfo = await this._getUser(projectInfo['responsible_user']);
		let creatorImage = await this._getPreviewImgUrl(
			creatorInfo['picture_key'],
			'31536000'
		);
		if (!creatorImage) {
			creatorImage = CommonConst.CLIENT_AVATAR_URL;
		}
		this.$store.commit('setCreatorInfo', creatorInfo);
		this.$store.commit('setCreatorAvatar', creatorImage);

		if (isRegiteredClient) {
			this.$store.commit('setIsSoundEnabled', !!userInfo?.sound_enabled);
		} else {
			this.$store.commit('setIsSoundEnabled', !!clientInfo?.sound_enabled);
		}

		let managementMasterInfo = await this._getManagementMaster(
			CommonConst.MANAGEMENT_MASTER_ID
		);
		this.$store.commit('setManagementMasterInfo', managementMasterInfo);

		let appLogo = await this._getPreviewImgUrl('app-logo.png', '31536000');
		this.$store.commit('setAppLogo', appLogo);

		let appLogoPlain = await this._getPreviewImgUrl('app-logo-plain.png', '31536000');
		this.$store.commit('setAppLogoPlain', appLogoPlain);

		let scheduleInfo = await this._getScheduleInfo(this.projectId);
		this.$store.commit('setSchedule', scheduleInfo);
		this.connectSocket();
	},
	computed: {
		// Get projectId from store
		...mapState([
			'clientInfo',
			'schedule',
			'clientMode',
			'redDotIndexs',
			'isControlScrollDecision',
			'preview',
			'isShareCoCreate',
			'isCoCreateOpen',
		]),

		isLeftSideBarScreenShow() {
			return this.isLiveScreenMinimum || !this.isShowLiveScreen;
		},
		mobileAndTabletCheck() {
			let check = false;
			(function (a) {
				if (
					//eslint-disable-next-line
					/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk|iPhone|iPad|iPod/i.test(
						a
					) ||
					//eslint-disable-next-line
					/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
						a.substr(0, 4)
					)
				)
					check = true;
			})(navigator.userAgent || navigator.vendor || window.opera);
			return check;
		},
	},

	watch: {
		isShareCoCreate(newVal) {
			this.sideBarRightItems[CommonConst.CO_CREATE_INDEX].iconName = newVal
				? 'CoCreate.svg'
				: 'CoCreate.svg';
			this.sideBarRightItems[CommonConst.CO_CREATE_INDEX].disabled = newVal
				? true
				: false;
		},

		// Remove Scroll Badge when change from Decision to another Screen
		$route() {
			this.$store.commit('setIsControlScrollDecision', false);
		},

		isShareScreenHiding(newVal) {
			this.shareScreenMenuBarItems = newVal ? this.$refs.shareScreen.menuBarItems : [];
		},
		'projectInfo.status': {
			handler(newStatus) {
				if (newStatus === 5) {
					this.sideBarRightItems[CommonConst.SETTLEMENT_INDEX].disabled = false;
					this.sideBarRightItems[CommonConst.SETTLEMENT_INDEX].iconName =
						'DownloadActive.svg';
				} else {
					this.sideBarRightItems[CommonConst.SETTLEMENT_INDEX].disabled = true;
				}
			},
		},
	},

	methods: {
		handleView() {
			this.mobileView = window.innerWidth <= 992;
		},

		onOpenDrawerLeft() {
			this.isShowDrawerLeft = true;
		},

		onOpenDrawerRight() {
			this.isShowDrawerRight = true;
		},

		closeDrawer() {
			this.isShowDrawerLeft = false;
			this.isShowDrawerRight = false;
		},

		/**
		 * Switch Live Screen Mode
		 * @param {Boolean} screenMode
		 */
		onSwitchLiveScreenMode(screenMode) {
			this.isLiveScreenMinimum = screenMode;
			this.isAddOnScreenShow = false;
			this.rightMenuScreen = '';
			if (screenMode) {
				this.isRightSideBarScreenShow = false;
				return;
			}
			this.isRightSideBarScreenShow = true;
		},
		onSwitchShareScreenMode() {
			this.isShareScreenExpandMode = !this.isShareScreenExpandMode;
		},
		async onCheckStartInfo(data) {
			console.log('CHECK START INFO', data, this.projectInfo);
			if (data) {
				await this._getProject(this.projectId);
			}
		},

		forceLeaveLiveScreen() {
			this.isShowLiveScreen = false;
			this.isRightSideBarScreenShow = this.rightMenuScreen ? true : false;
			this.isLiveScreenSessionJoined = !this.isLiveScreenSessionJoined;
			this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].disabled = false;
			this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].iconName = 'Live.svg';
			this.shareScreenPlainContents = LiveConst.TIME_OUT;
			this.$bvModal.show(this.shareScreenPlainModalId);
		},

		onCloseLiveScreen({ plainModal, selectiveModal }) {
			if (selectiveModal) {
				this.$bvModal.show(`${this.liveScreenSelectiveModalId}`);
				return;
			}

			if (plainModal) {
				this.$bvModal.show('live-screen-plain-modal-id');
				return;
			}
			this.isRightSideBarScreenShow = false;
			this.isShowLiveScreen = false;
			this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].disabled = false;
			this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].iconName = 'Live.svg';
			this.shareScreenPlainContents = HOST_EXIT_MEETING;
			this.$bvModal.show(this.shareScreenPlainModalId);
		},
		onSeenMessage: async function () {
			this.messageEvent += 1;
		},
		/**
		 * Show Sub-proposal item in sideBarLeft
		 */
		onSideBarLeftSubProposalShow() {
			this.sideBarLeftItems[CommonConst.SCREEN_ID.ADDCOST]['isHidden'] = false;
			this.sideBarLeftItems[CommonConst.SCREEN_ID.REPROPOSAL]['isHidden'] = false;

			// Revert Reproposal Name
			this.sideBarLeftItems[CommonConst.SCREEN_ID.REPROPOSAL]['name'] =
				CommonConst.RE_PROPOSAL_SCREEN_NAME;
		},

		onShowDownloadScreenButton() {
			this.sideBarLeftItems[CommonConst.SCREEN_ID.ESTIMATE]['isConfirm'] = true;
		},
		onOpenSettlement() {
			this.$refs.sideBarRight.toggle(SideBarRightConst.SETTLEMENT_SCREEN_NAME);
		},
		onOpenChatRoom() {
			this.$refs.sideBarRight.toggle(SideBarRightConst.CHAT_SCREEN_NAME);
		},

		onLeftMenuItemClick() {
			if (this.isShowLiveScreen === true) {
				this.isLiveScreenMinimum = true;
			}

			if (this.isShowShareScreen === true) {
				this.isShareScreenHiding = true;
			}

			if (this.rightMenuScreen === 'memo-screen-full') {
				this.isMemoScreenMinimum = true;
				this.isRightSideBarScreenShow = true;
				this.isAddOnScreenShow = false;
				this.rightMenuScreen = '';
			}

			this.$refs.sideBarRight.activeItem = '';
			this.isAddOnScreenShow = false;
			this.isRightSideBarScreenShow = false;
			this.$refs.sideBarRight.toggle(undefined);
		},

		onCloseMemoScreenFull() {
			this.$refs.sideBarRight.activeItem = '';
			this.rightMenuScreen = '';
			this.isAddOnScreenShow = false;
			this.isRightSideBarScreenShow =
				this.isShowShareScreen && !this.isShareScreenHiding ? true : false;
			this.onChat = false;
		},

		onCloseMemoScreenMinimum() {
			this.isMemoScreenMinimum = false;
		},

		onCloseComodeAiScreen() {
			this.$refs.sideBarRight.activeItem = '';
			this.rightMenuScreen = '';
			this.isAddOnScreenShow = false;
			this.isRightSideBarScreenShow =
				this.isShowShareScreen && !this.isShareScreenHiding ? true : false;
		},
		onCloseSettlementScreen() {
			this.$refs.sideBarRight.activeItem = '';
			this.rightMenuScreen = '';
			this.isAddOnScreenShow = false;
			this.isRightSideBarScreenShow =
				this.isShowShareScreen && !this.isShareScreenHiding ? true : false;
		},
		onCloseAddOnFolder() {
			this.$refs.sideBarRight.activeItem = '';
			this.rightMenuScreen = '';
			this.isAddOnScreenShow = false;
			this.isRightSideBarScreenShow =
				this.isShowShareScreen && !this.isShareScreenHiding ? true : false;
		},

		onCloseLiveDesignScreen() {
			this.$refs.sideBarRight.activeItem = '';
			this.rightMenuScreen = '';
			this.isAddOnScreenShow = false;
			this.isRightSideBarScreenShow =
				this.isShowShareScreen && !this.isShareScreenHiding ? true : false;
		},
		onDuplicateUser() {
			this.liveScreenPlainContents = DUPLICATE_USER;
			this.$bvModal.show(`${this.liveScreenPlainModalId}`);

			this.isShowLiveScreen = false;

			this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].disabled = false;
		},
		onDuplicateUserShare() {
			this.liveScreenPlainContents = DUPLICATE_USER;
			this.$bvModal.show(`${this.liveScreenPlainModalId}`);

			this.isShowShareScreen = false;
			this.isRightSideBarScreenShow = false;
			this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].disabled = false;
			this.$store.commit('setIsAppProcessing', false);
		},
		onCloseShareScreen() {
			this.$bvModal.show(this.shareScreenSelectiveModalId);
		},

		onHostCloseShareScreen() {
			this.shareScreenPlainContents = HOST_EXIT_SHARING;
			this.$bvModal.show(this.shareScreenPlainModalId);
		},

		onLeaveShareScreenSeesion() {
			this.isShareScreenHiding = false;
			this.isShareScreenExpandMode = false;
			this.$refs.sideBarRight.activeItem = '';
			this.rightMenuScreen = '';
			this.isShowShareScreen = false;
			this.isRightSideBarScreenShow =
				this.isShowMemoScreen && !this.isMemoScreenMinimum ? true : false;
		},

		onShareScreenTimeOut() {
			this.isShowShareScreen = false;
			this.$refs.sideBarRight.activeItem = '';
			this.rightMenuScreen = '';
			this.isShareScreenExpandMode = false;
			this.isRightSideBarScreenShow = this.isShowMemoScreen ? true : false;
			this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].disabled = false;
			this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].iconName = 'Live.svg';
			this.shareScreenPlainContents = SHARE_TIME_OUT;
			this.$bvModal.show(this.shareScreenPlainModalId);
		},

		onHideShareScreenModal() {
			this.onLeaveShareScreenSeesion();
			this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].disabled = false;
			this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].iconName =
				'ShareScreen.svg';
		},
		onNotSharingScreen() {
			this.shareScreenPlainContents = NOT_SHARING;
			this.$bvModal.show(`${this.shareScreenPlainModalId}`);
		},

		onCloseCoCreateScreen() {
			this.$refs.sideBarRight.activeItem = '';
			this.rightMenuScreen = '';
			this.isAddOnScreenShow = false;
			this.isRightSideBarScreenShow =
				this.isShowShareScreen && !this.isShareScreenHiding ? true : false;
			this.$store.commit('setIsCoCreateOpen', false);
		},

		onCloseChatScreen() {
			this.$refs.sideBarRight.activeItem = '';
			this.rightMenuScreen = '';
			this.isAddOnScreenShow = false;
			this.isRightSideBarScreenShow = false;
			this.onChat = false;
		},

		onToggleRightMenuScreen(screenName) {
			switch (screenName) {
				case SideBarRightConst.LIVE_SCREEN_NAME:
					if (!this.isShowLiveScreen) {
						this.isShowLiveScreen = true;
						this.isLiveScreenMinimum = true;
						this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].disabled = true;
						this.$store.commit('setIsAppProcessing', true);
						this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].iconName =
							'LiveActive.svg';
					}

					return;

				case SideBarRightConst.MEMO_SCREEN_NAME:
					if (this.rightMenuScreen === 'memo-screen-full') {
						this.onCloseMemoScreenFull();
						return;
					}

					if (this.isMemoScreenMinimum) {
						this.isMemoScreenMinimum = false;
						return;
					}

					this.forceLiveScreenToMimimun();

					this.rightMenuScreen = 'memo-screen-full';
					this.isAddOnScreenShow = true;
					this.onChat = true;
					return;

				case SideBarRightConst.CHAT_SCREEN_NAME:
					if (this.rightMenuScreen === 'chat-screen') {
						this.onCloseChatScreen();
						return;
					}

					this.forceLiveScreenToMimimun();

					this.rightMenuScreen = 'chat-screen';
					this.isAddOnScreenShow = true;
					this.onChat = true;
					return;

				case SideBarRightConst.CO_CREATE_SCREEN_NAME:
					if (this.rightMenuScreen === 'co-create-screen') {
						this.onCloseCoCreateScreen();
						return;
					}

					this.$store.commit('setIsCoCreateOpen', true);

					this.forceLiveScreenToMimimun();

					this.rightMenuScreen = 'co-create-screen';
					this.isAddOnScreenShow = true;
					return;

				case SideBarRightConst.LIVE_DESIGN_SCREEN_NAME:
					if (this.rightMenuScreen === 'live-design-screen') {
						this.onCloseLiveDesignScreen();
						return;
					}
					this.forceLiveScreenToMimimun();
					this.rightMenuScreen = 'live-design-screen';
					this.isAddOnScreenShow = true;
					return;

				case SideBarRightConst.ADD_ON_FOLDER_SCREEN_NAME:
					if (this.rightMenuScreen === 'add-on-folder-screen') {
						this.onCloseAddOnFolder();
						return;
					}

					this.forceLiveScreenToMimimun();

					this.rightMenuScreen = 'add-on-folder-screen';
					this.isAddOnScreenShow = true;
					return;

				case SideBarRightConst.COMODE_AI_SCREEN_NAME:
					if (this.rightMenuScreen === 'comode-ai-screen') {
						this.onCloseComodeAiScreen();
						return;
					}
					this.forceLiveScreenToMimimun();

					this.rightMenuScreen = 'comode-ai-screen';
					this.isAddOnScreenShow = true;
					this.onChat = true;
					return;

				case SideBarRightConst.SHARE_SCREEN_NAME:
					if (!this.isShowShareScreen) {
						this.rightMenuScreen = '';
						this.isAddOnScreenShow = false;
						this.isShowMemoScreen = false;
						this.isRightSideBarScreenShow = true;
						this.isShowShareScreen = true;
						this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].disabled = true;
						this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].iconName =
							'ShareScreenActive.svg';
						this.$store.commit('setIsAppProcessing', true);
					}
					return;
				case SideBarRightConst.SETTLEMENT_SCREEN_NAME:
					if (this.rightMenuScreen === 'settlement-screen') {
						this.onCloseSettlementScreen();
						return;
					}
					this.forceLiveScreenToMimimun();
					this.rightMenuScreen = 'settlement-screen';
					this.isAddOnScreenShow = true;
					this.onChat = true;
					return;
			}
		},

		onChangeSessionJoined(newSessionJoined) {
			this.isLiveScreenSessionJoined = newSessionJoined;
		},

		onLeaveLiveScreenSeesion() {
			if (this.isShowLiveScreen && !this.isLiveScreenMinimum) {
				this.isRightSideBarScreenShow = false;
			}

			this.isShowLiveScreen = false;
			this.isLiveScreenSessionJoined = !this.isLiveScreenSessionJoined;
		},

		/**
		 * Handle resize MemoScreen
		 * @param {Object} {isMemoScreenMinimum} : true at minisize, false at full size
		 */
		onSwitchMemoScreenMode({ isMemoScreenMinimum }) {
			this.isMemoScreenMinimum = isMemoScreenMinimum;
			this.isAddOnScreenShow = false;
			if (isMemoScreenMinimum) {
				this.forceLiveScreenToMimimun();
				this.$refs.sideBarRight.activeItem = '';
				this.rightMenuScreen = '';
				this.isAddOnScreenShow = false;
				this.isRightSideBarScreenShow =
					this.isShowShareScreen && !this.isShareScreenHiding ? true : false;
			} else {
				this.forceLiveScreenToMimimun();

				this.rightMenuScreen = 'memo-screen-full';
				this.isAddOnScreenShow = true;
			}
		},

		// Force LiveScreen to minimum whenever open other screens
		forceLiveScreenToMimimun() {
			if (this.isShowLiveScreen && !this.isLiveScreenMinimum) {
				this.isLiveScreenMinimum = true;
				this.isRightSideBarScreenShow = false;
			}
		},

		onUnseenMessages(unSeenMessages) {
			this.unSeenMessages = unSeenMessages;
		},

		/**
		 * Get Confirm Data from child Screen
		 * @param {Object} data
		 * data["screenId"]: child Screen 's Id
		 * data["confirmInfo"]: {booleen}
		 */
		getConfirmInfo(data) {
			if (data.screenId === this.sideBarLeftItems.length - 1) {
				this.sideBarLeftItems[data.screenId]['isConfirm'] = data.confirmInfo;
				this.sideBarLeftItems[data.screenId]['inProcessing'] = false;
				return;
			}
			// Add Cocreate button in sideBarRight when Estimate confimed
			if (data.screenId === CommonConst.SCREEN_ID.HEARING) {
				this.sideBarRightItems[CommonConst.CO_CREATE_INDEX].iconName = 'CoCreate.svg';
				this.$store.commit('setIsShareCoCreate', true);
			} else {
				this.sideBarRightItems[CommonConst.CO_CREATE_INDEX].iconName = 'CoCreate.svg';
				this.$store.commit('setIsShareCoCreate', false);
			}
			// Force direct to delivery screen
			if (data.screenId === CommonConst.SCREEN_ID.PROPOSAL && !data.isReproposal) {
				this.sideBarLeftItems[data.screenId + 3]['inProcessing'] = true;
				this.sideBarLeftItems[data.screenId + 3]['disabled'] = false;
				this.sideBarLeftItems[data.screenId]['inProcessing'] = false;
				this.sideBarLeftItems[data.screenId]['isConfirm'] = true;
			}

			// If client finised but creator dose not create Invoice yet
			if (
				data.screenId === CommonConst.SCREEN_ID.DELIVERY &&
				this.projectInfo.client_finished === 1
			) {
				this.sideBarLeftItems[data.screenId]['disabled'] = false;
				this.sideBarLeftItems[data.screenId]['inProcessing'] = false;
				this.sideBarLeftItems[data.screenId]['isConfirm'] = true;
			}

			// Direct to Reproposal Screen
			else if (data.screenId === CommonConst.SCREEN_ID.ADDCOST && data.isReproposal) {
				this.sideBarLeftItems[data.screenId + 1]['inProcessing'] = false;
				this.sideBarLeftItems[data.screenId]['inProcessing'] = true;
			} else {
				this.sideBarLeftItems[data.screenId]['isConfirm'] = data.confirmInfo;
				this.sideBarLeftItems[data.screenId]['inProcessing'] = false;
				this.sideBarLeftItems[data.screenId + 1]['inProcessing'] = true;
				this.sideBarLeftItems[data.screenId + 1]['disabled'] = false;
			}
		},

		/**
		 * Get requirementScreen's cofirm_flag info
		 * @param {String} projectId
		 */
		async _getHearingData(projectId) {
			let response = await HearingService.get(projectId);
			if (response && response.status === 200) {
				if (response.data['confirm_flag'] === CommonConst.CONFIRMED) {
					this.sideBarLeftItems[CommonConst.SCREEN_ID.HEARING]['isConfirm'] = true;
				}

				this.screenDatas.hearing = response.data;
			} else {
				// TODO: Error notification
				console.log('Get requirement details failed');
			}
		},

		/**
		 * Get estimateScreen's cofirm_flag info
		 * @param {String} projectId
		 */
		async _getEstimateParticular(projectId) {
			let response = await EstimateParticularService.get(projectId);
			if (response && response.status === 200) {
				let estimateParticular = response.data['estimate_particular'];
				let confirmFlag = estimateParticular['confirm_flag'];
				if (confirmFlag === CommonConst.CONFIRMED) {
					this.sideBarLeftItems[CommonConst.SCREEN_ID.ESTIMATE]['isConfirm'] = true;
				}

				this.screenDatas.estimateParticular = estimateParticular;
			} else {
				// TODO: Error notification
				console.log('Get estimate particular failed');
			}
		},
		/**
		 * Get scheduleScreen's cofirm_flag info
		 * @param {Number} projectId
		 */
		async _getScheduleInfo(projectId) {
			try {
				let response = await ScheduleService.get(projectId);
				if (response && response.status === 200) {
					this.screenDatas.schedule = response.data;
					return response.data;
				}
			} catch (error) {
				console.log(`Get schedule failed: ${error}`);
				return {};
			}
		},

		/**
		 * Get Hearing data
		 * @param {Integer} projectId project ID
		 * @returns
		 */
		async _getDecisionData(projectId) {
			let response = await DecisionService.get(projectId);
			if (!response || response.status !== 200) {
				throw 'Get Hearing failed';
			}

			if (response.data['confirm_flag'] === CommonConst.CONFIRMED) {
				this.sideBarLeftItems[CommonConst.SCREEN_ID.DECISION]['isConfirm'] = true;
			}

			this.screenDatas.decision = response.data;
			return response.data;
		},

		/**
		 * Get DeliveryData
		 * @param {Integer} projectId Project ID
		 * @returns
		 */
		async _getDeliveryData(projectId) {
			try {
				let response = await DeliveryService.get(projectId);
				if (!response || response.status !== 200) {
					throw 'Get Delivery failed!';
				}

				let uploadedContents = [...response.data].reverse();

				if (
					uploadedContents &&
					uploadedContents.length > 0 &&
					uploadedContents[0]['is_save'] >= 2
				) {
					this.sideBarLeftItems[CommonConst.SCREEN_ID.DELIVERY]['isConfirm'] = true;
				}

				this.screenDatas.deliveries = uploadedContents;

				console.log('%c Get Delivery successfully!', 'color: green');
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Get Proposal General data
		 * @param {Integer} projectId project ID
		 * @returns
		 */
		async _getProposalGeneralData(projectId) {
			let response = await ProposalGeneralService.get(projectId);

			if (!response || response.status !== 200) {
				throw 'Get Hearing failed';
			}
			if (response.data['screen_mode'] === CommonConst.REVISION_MODE_NUMB) {
				this.sideBarLeftItems[CommonConst.SCREEN_ID.PROPOSAL]['name'] =
					CommonConst.REVISION_SCREEN_NAME;
			} else if (response.data['screen_mode'] === CommonConst.PROPOSAL_MODE_NUMB) {
				this.sideBarLeftItems[CommonConst.SCREEN_ID.PROPOSAL]['name'] =
					CommonConst.PROPOSAL_SCREEN_NAME;
			}
		},

		/**
		 * Get data from Add Cost Details Table
		 * @param {String} quoteId
		 * @returns Add Cost Details 's Data
		 */
		async _getAddCostDetails(quoteId) {
			try {
				let response = await AddCostDetailService.get(quoteId);
				if (response && response.status !== 200) {
					throw 'Get add cost detail failed';
				}
				let addCostDetails = response.data;
				this.screenDatas.addCostDetails = addCostDetails;
			} catch (error) {
				console.log(`Get add_cost detail failed: ${error}`);
			}
		},

		/**
		 * Get ReProposal General data
		 * @param {Integer} projectId project ID
		 * @returns
		 */
		async _getReProposalGeneralData(projectId) {
			try {
				let response = await ReProposalGeneralService.get(projectId);
				if (response.status !== 200) {
					throw 'Get proposal general failed';
				}

				if (response.data['screen_mode'] === CommonConst.REVISION_MODE_NUMB) {
					this.sideBarLeftItems[CommonConst.SCREEN_ID.REPROPOSAL]['name'] =
						CommonConst.RE_REVISION_SCREEN_NAME;
				} else if (response.data['screen_mode'] === CommonConst.PROPOSAL_MODE_NUMB) {
					this.sideBarLeftItems[CommonConst.SCREEN_ID.REPROPOSAL]['name'] =
						CommonConst.RE_PROPOSAL_SCREEN_NAME;
				}
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		onChangeProposalName(newName, screenId) {
			if (screenId === CommonConst.SCREEN_ID.PROPOSAL)
				this.sideBarLeftItems[CommonConst.SCREEN_ID.PROPOSAL]['name'] = newName;
			if (screenId === CommonConst.SCREEN_ID.REPROPOSAL)
				this.sideBarLeftItems[CommonConst.SCREEN_ID.REPROPOSAL]['name'] = newName;
		},

		async _getProject(projectId) {
			try {
				let response = await ProjectService.get(projectId);
				if (!response || response.status !== 200) {
					throw 'Get Project failed!';
				}

				console.log('%c Get Project successfully!', 'color: green');
				let projectInfo = response.data;
				this.projectInfo = projectInfo;
				this.clientAppLink = projectInfo.process_client_url;
				await this._getProcessingScreenInfo(projectInfo);
				return projectInfo;
			} catch (error) {
				console.log(error);
			}
		},

		async _getProcessingScreenInfo(projectInfo) {
			let inProcessScreenIdx = projectInfo.status;
			let showSubMenu = Boolean(projectInfo['sub_menu_flag']);
			let creativeData = await this._getCreativeData(this.projectId);
			if (projectInfo['only_decision'] === 1) {
				this.sideBarLeftItems[CommonConst.SCREEN_ID.SCHEDULE]['isHidden'] = true;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.HEARING]['isHidden'] = true;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.DELIVERY]['isHidden'] = true;
			} else {
				this.sideBarLeftItems[CommonConst.SCREEN_ID.SCHEDULE]['isHidden'] = false;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.HEARING]['disabled'] = true;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.DECISION]['disabled'] = true;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.DECISION]['inProcessing'] = false;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.HEARING]['isHidden'] = false;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.DELIVERY]['isHidden'] = false;
			}
			if (
				inProcessScreenIdx === CommonConst.SCREEN_ID.DECISION ||
				inProcessScreenIdx === CommonConst.SCREEN_ID.ADDCOST
			) {
				this.$store.commit('setIsShareCoCreate', true);
			} else {
				this.$store.commit('setIsShareCoCreate', false);
			}

			if (
				inProcessScreenIdx === CommonConst.SCREEN_ID.ADDCOST ||
				inProcessScreenIdx === CommonConst.SCREEN_ID.REPROPOSAL ||
				showSubMenu
			) {
				this.sideBarLeftItems[CommonConst.SCREEN_ID.ADDCOST]['isHidden'] = false;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.REPROPOSAL]['isHidden'] = false;
				if (inProcessScreenIdx === CommonConst.SCREEN_ID.ADDCOST) {
					this.sideBarLeftItems[CommonConst.SCREEN_ID.REPROPOSAL]['disabled'] = false;
					this.sideBarLeftItems[CommonConst.SCREEN_ID.REPROPOSAL]['isConfirm'] = true;
				}
			}

			let sideBarLeftItems = [...this.sideBarLeftItems];
			for (let i = 0; i < sideBarLeftItems.length; i++) {
				if (i < inProcessScreenIdx) {
					this.sideBarLeftItems[i]['disabled'] = false;
					this.sideBarLeftItems[i]['isConfirm'] = true;
				}

				if (i === inProcessScreenIdx && projectInfo.client_start === 1) {
					this.sideBarLeftItems[i]['disabled'] = false;
					this.sideBarLeftItems[i]['inProcessing'] = true;
				}
			}

			// If creative is counting down, stay in creative screen
			if (
				creativeData &&
				creativeData['status'] === 'onCountingDown' &&
				inProcessScreenIdx === CommonConst.SCREEN_ID.PROPOSAL
			) {
				this.sideBarLeftItems[CommonConst.SCREEN_ID.CREATIVE]['inProcessing'] = true;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.CREATIVE]['disabled'] = false;

				this.sideBarLeftItems[CommonConst.SCREEN_ID.PROPOSAL]['inProcessing'] = false;
				this.sideBarLeftItems[CommonConst.SCREEN_ID.PROPOSAL]['disabled'] = true;
			}

			// If client finised but creator dose not create Invoice yet
			if (
				inProcessScreenIdx === CommonConst.SCREEN_ID.DELIVERY &&
				projectInfo.client_finished === 1
			) {
				this.sideBarLeftItems[inProcessScreenIdx]['disabled'] = false;
				this.sideBarLeftItems[inProcessScreenIdx]['inProcessing'] = false;
				this.sideBarLeftItems[inProcessScreenIdx]['isConfirm'] = true;
			}

			if (inProcessScreenIdx === this.sideBarLeftItems.length) {
				this.sideBarLeftItems[inProcessScreenIdx - 1]['disabled'] = false;
				this.sideBarLeftItems[inProcessScreenIdx - 1]['inProcessing'] = false;
				this.sideBarLeftItems[inProcessScreenIdx - 1]['isConfirm'] = true;
			}
		},

		async _getClient(clientId) {
			try {
				let response = await ClientService.get(clientId);
				if (!response || response.status !== 200) {
					throw 'Get Client failed!';
				}

				console.log('%c Get Client successfully!', 'color: green');
				let clientInfo = response.data;
				return clientInfo;
			} catch (error) {
				console.log(error);
			}
		},

		async _getUser(usertId) {
			try {
				let response = await UserService.get(usertId);
				if (!response || response.status !== 200) {
					throw 'Get User failed!';
				}

				console.log('%c Get User successfully!', 'color: green');
				let userInfo = response.data;
				return userInfo;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Get image preview url
		 * @param {String} imgKey Image key to get image url
		 * @param {String} expiration The lifetime of the url in seconds
		 * @returns image url
		 */
		async _getPreviewImgUrl(imgKey, expiration) {
			try {
				let response = await TransferService.get(imgKey, expiration);
				if (!response || response.status !== 200) {
					throw 'Get image url failed!';
				}

				console.log('%c Get image url successfully!', 'color: green');
				return response.data.link;
			} catch (error) {
				console.log(error);
			}
		},

		async _getManagementMaster(id) {
			try {
				let response = await ManagementMasterService.get(id);
				if (!response || response.status !== 200) {
					throw 'Get User failed!';
				}

				console.log('%c Get Management Master successfully!', 'color: green');
				let managementMasterInfo = response.data;
				return managementMasterInfo;
			} catch (error) {
				console.log(error);
			}
		},
		onUpdateProjectStatus(status) {
			this.projectInfo = { ...this.projectInfo, status: status };
			this.$store.commit('setProjectInfo', this.projectInfo);
		},

		async getUnReplyQuestion() {
			let decisionData = await this._getDecisionData(this.projectId);
			if (!decisionData.id) {
				return [];
			}
			let query = {
				decision_id: decisionData.id,
				answered_flag: 0,
			};
			return DecisionDetailService.search(query)
				.then((response) => {
					return response.data;
				})
				.catch((error) => {
					console.log(error);
					return [];
				});
		},

		async onAnswerDecision() {
			let getUnReplyQuestion = await this.getUnReplyQuestion();
			console.log('onAnswerDecision', getUnReplyQuestion);
			this.showQuestionNotification =
				getUnReplyQuestion.length > 0 ? true : false ?? false;
		},

		async _getCreativeData(projectId) {
			let response = await CreativeService.get(projectId);
			if (response.status !== 200) {
				throw 'Get Creative Data failed!';
			}
			console.log('Get Creative Data success!', response.data);
			let creativeDetails = response.data.creative_details
				? response.data.creative_details
				: [];
			if (creativeDetails.length > 0) {
				this.showCreativeNotification = creativeDetails.some(
					(item) => item.answered_flag === 0
				);
			}
			return response.data;
		},
		async _getCreativeCounterDate(projectId) {
			let response = await CreativeService.get(projectId);
			if (response.status !== 200) {
				throw 'Get Creative Counter Date failed!';
			}
			if (response.data['scheduled_date']) {
				return response.data['scheduled_date'];
			}
			return null;
		},
		async onUpdateCreativeQuestionList(data) {
			console.log('onUpdateCreativeQuestionList', data);
			await this._getCreativeData(this.projectId);
		},
		async onClickMyPage() {
			//direct to MyPage of client
			let routeData = this.$router.resolve({
				path: `/adminApp/clientmenu/${this.userId}/mypage`,
			});
			window.open(routeData.href, '_blank');
		},

		async onClickProjectMenu() {
			let routeData = this.$router.resolve({
				path: `/adminApp/clientmenu/${this.userId}/projectmenu`,
			});
			window.open(routeData.href, '_blank');
		},

		/**
		 * Get Invoice Data
		 * @param {Integer} quoteId EstimateParticular ID
		 * @returns invoiceData
		 */
		async _getInvoice(quoteId) {
			try {
				let response = await InvoiceService.get(quoteId);
				if (!response || response.status !== 200) {
					throw 'Get Invoice failed!';
				}

				console.log('%c Get Invoice successfully!', 'color: green');
				this.screenDatas.settlement = response.data;
			} catch (error) {
				console.log(error);
			}
		},
		async updateProject() {
			let projectInfo = await this._getProject(this.projectId);
			this.projectInfo = projectInfo;
			this.$store.commit('setProjectInfo', projectInfo);
		},
		notifyZoom(comodeMode) {
			this.comodeMode = comodeMode;
			this.comodeRequestContents =
				comodeMode == 'talk' ? LiveConst.REQUEST_TALKING : LiveConst.CREATOR_SHARING;
			if (!this.preview && !this.clientMode) {
				this.$bvModal.show(this.comodeRequestModalId);
			}
			if (comodeMode == 'talk') {
				this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].iconName =
					'LiveActive.svg';
				this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].disabled = true;
			} else {
				this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].iconName =
					'ShareScreenActive.svg';
				this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].disabled = true;
			}
		},
		openComode() {
			this.onToggleRightMenuScreen(
				this.comodeMode == 'talk'
					? SideBarRightConst.LIVE_SCREEN_NAME
					: SideBarRightConst.SHARE_SCREEN_NAME
			);
		},
		onShowShareScreen() {
			this.isAddOnScreenShow = false;
			if (this.rightMenuScreen === 'memo-screen-full') {
				this.isMemoScreenMinimum = true;
			}
			this.rightMenuScreen = '';
			this.isRightSideBarScreenShow = true;
			this.isShareScreenHiding = false;
		},
		onShowShareScreenController() {
			this.$refs.shareScreen.onShowShareScreen();
		},
		onMinimumBarItemClickShareScreenController(id) {
			this.$refs.shareScreen.onMinimumMenuBarItemClick(id);
		},
		async startCreativeCountDown() {
			if (
				this.projectInfo.status == CommonConst.SCREEN_ID.CREATIVE ||
				this.projectInfo.status == CommonConst.SCREEN_ID.PROPOSAL
			) {
				this.counterDate = await this._getCreativeCounterDate(this.projectInfo.id);
			}
		},
		async onHandleTimeOutCreativeCountDown() {
			if (
				this.projectInfo.status === CommonConst.SCREEN_ID.CREATIVE ||
				this.projectInfo.status === CommonConst.SCREEN_ID.PROPOSAL
			) {
				let routeName = this.$router.currentRoute.path.split('/').at(-1);
				if (routeName == 'creative') {
					return;
				}

				await this.updateProjectStatus(
					this.projectId,
					CommonConst.SCREEN_ID.CREATIVE + 1
				);
				let confirmInfo = {
					screenId: CommonConst.SCREEN_ID.CREATIVE,
					confirmInfo: true,
				};
				this.getConfirmInfo(confirmInfo);
				this.$router.push('proposal');
			}
		},
	},

	async mounted() {
		socketClient.listen('new_join', (data) => {
			console.log(data);
		});
		this.handleView();
		window.addEventListener('resize', this.handleView);
		console.log('%c ProjectDetail mounted', 'color: green');
		let getUnReplyQuestion = await this.getUnReplyQuestion();
		console.log('getUnReplyQuestion', getUnReplyQuestion);
		if (getUnReplyQuestion.length > 0) {
			this.showQuestionNotification = true;
		}
		await Promise.allSettled([
			// await this._getCreativeData(this.projectId);
			await this._getHearingData(this.projectId),
			await this._getScheduleInfo(this.projectId),
			await this._getDecisionData(this.projectId),
			await this._getProposalGeneralData(this.projectId),
			await this._getEstimateParticular(this.projectId),
			await this._getReProposalGeneralData(this.projectId),
			await this._getDeliveryData(this.projectId),
		]);
		if (this.screenDatas.estimateParticular?.id) {
			await this._getAddCostDetails(this.screenDatas.estimateParticular?.id);
			await this._getInvoice(this.screenDatas.estimateParticular?.id);
		}
		socketClient.listen('new_data_transfer', async (data) => {
			switch (data?.event_name) {
				case 'zoom_meeting_end':
					this.updateProject();
					break;
				case 'admin_update_project':
					this.updateProject();
					break;
				// case 'is_cocreating':
				// 	this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].iconName =
				// 		data.isShowLiveScreen ? 'LiveActive.svg' : 'Live.svg';
				// 	this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].disabled =
				// 		data.isShowShareScreen;
				// 	this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].iconName =
				// 		data.isShowShareScreen ? 'ShareScreenActive.svg' : 'ShareScreen.svg';
				// 	this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].disabled =
				// 		data.isShowLiveScreen;
				// 	break;

				// This is for redirecting page
				case 'schedule_direct':
					this.$store.commit('setIsAppProcessing', true);

					this.getConfirmInfo({
						screenId: CommonConst.SCREEN_ID.SCHEDULE,
						confirmInfo: true,
					});
					this.$router.push('hearing');
					this.onUpdateProjectStatus(CommonConst.SCREEN_ID.SCHEDULE + 1);

					this.$store.commit('setIsAppProcessing', false);
					break;
				case 'estimate-direct':
					await this.updateProjectStatus(
						this.projectId,
						CommonConst.SCREEN_ID.ESTIMATE + 1
					);
					this.$store.commit('setIsAppProcessing', true);

					this.getConfirmInfo({
						screenId: CommonConst.SCREEN_ID.ESTIMATE,
						confirmInfo: true,
					});
					this.$router.push('decision');
					this.onUpdateProjectStatus(CommonConst.SCREEN_ID.ESTIMATE + 1);

					this.$store.commit('setIsAppProcessing', false);
					break;
				case 'decision_direct':
					this.getConfirmInfo({
						screenId: DecisionConst.DECISION_SCREEN_ID,
						confirmInfo: true,
					});
					this.onUpdateProjectStatus(DecisionConst.DECISION_SCREEN_ID + 1);

					// Remove Scroll Badge when change from Decision to another Screen
					this.$store.commit('setIsControlScrollDecision', false);
					this.$router.push(data?.content);
					break;
				case 'decision_only':
					this.getConfirmInfo({
						screenId: DecisionConst.DECISION_SCREEN_ID,
						confirmInfo: true,
					});
					this.onUpdateProjectStatus(DecisionConst.DECISION_SCREEN_ID + 1);
					await this.$store.commit(
						'setProjectInfo',
						(this.projectInfo = {
							...this.projectInfo,
							client_finished: 1,
							status: 5,
						})
					);
					// Remove Scroll Badge when change from Decision to another Screen
					this.$store.commit('setIsControlScrollDecision', false);
					this.$router.push(data?.content);
					break;
				case 'skip_proposal_deadline': {
					let routeName = this.$router.currentRoute.path.split('/').at(-1);
					if (routeName == 'creative') {
						break;
					}
					this.getConfirmInfo({
						screenId: CommonConst.SCREEN_ID.CREATIVE,
						confirmInfo: true,
					});
					this.onUpdateProjectStatus(CommonConst.SCREEN_ID.CREATIVE + 1);
					this.$router.push('proposal');
					break;
				}

				default:
					break;
			}
		});
		// Socket for proposal + reproposal redirect
		socketClient.listen('new_proposal_creator_direct', async (data) => {
			if (data.currentLink == 'proposal') {
				this.$store.commit('setIsAppProcessing', true);

				let confirmInfo = {
					screenId: CommonConst.SCREEN_ID.PROPOSAL,
					confirmInfo: true,
				};
				this.getConfirmInfo(confirmInfo);
				this.onUpdateProjectStatus(CommonConst.SCREEN_ID.DELIVERY);
				this.onChangeProposalName(
					CommonConst.PROPOSAL_SCREEN_NAME,
					CommonConst.SCREEN_ID.PROPOSAL
				);
				this.$store.commit('setIsAppProcessing', false);
				this.$router.push('delivery');
			} else if (data.currentLink == 'reproposal') {
				this.$store.commit('setIsAppProcessing', true);

				let confirmInfo = {
					screenId: CommonConst.SCREEN_ID.REPROPOSAL,
					confirmInfo: true,
				};
				this.getConfirmInfo(confirmInfo);
				this.onUpdateProjectStatus(CommonConst.SCREEN_ID.DELIVERY);
				this.onChangeProposalName('再提案', CommonConst.SCREEN_ID.REPROPOSAL);

				this.$store.commit('setIsAppProcessing', false);
				this.$router.push('delivery');
			}
		});

		socketClient.listen('new_creator_direct_to_reproposal', async (data) => {
			if (data) {
				this.$store.commit('setIsAppProcessing', true);

				let confirmInfo = {
					screenId: CommonConst.SCREEN_ID.PROPOSAL,
					confirmInfo: true,
					isReproposal: true,
				};
				this.getConfirmInfo(confirmInfo);
				this.onSideBarLeftSubProposalShow();
				this.onUpdateProjectStatus(CommonConst.SCREEN_ID.ADDCOST);
				this.onChangeProposalName(
					CommonConst.PROPOSAL_SCREEN_NAME,
					CommonConst.SCREEN_ID.PROPOSAL
				);

				// Turn on Sharing CoCreate Mode when access to AddCost
				this.$store.commit('setIsShareCoCreate', true);
				this.$store.commit('setIsAppProcessing', false);
				this.$router.push('addcost');
			}
		});
		socketClient.listen('new_proposal_updated', async (data) => {
			if (data.status && data.newName) {
				this.$store.commit('setIsAppProcessing', true);

				if (data.currentLink == 'proposal') {
					this.onChangeProposalName(data.newName, CommonConst.SCREEN_ID.PROPOSAL);
				} else if (data.currentLink == 'reproposal') {
					this.onChangeProposalName(data.newName, CommonConst.SCREEN_ID.REPROPOSAL);
				}
				let routeName = this.$router.currentRoute.path.split('/').at(-1);
				if (routeName != data.currentLink) {
					this.$router.push(data.currentLink);
				}

				this.$store.commit('setIsAppProcessing', false);
			}
		});
		socketClient.listen('addcost_client_direct', async (data) => {
			this.$store.commit('setIsAppProcessing', true);

			let confirmInfo = {
				screenId: ADD_COST_SCREEN_ID,
				confirmInfo: true,
			};
			this.getConfirmInfo(confirmInfo);
			this.onUpdateProjectStatus(REPROPOSAL_SCREEN_ID);
			setTimeout(() => {
				this.$router.push(data);
			}, 500);
			console.log('----------------------------------------');
			this.$store.commit('setIsAppProcessing', false);
		});
		socketClient.listen('new_creator_direct_to_addcost', async (data) => {
			if (data) {
				this.$store.commit('setIsAppProcessing', true);

				let confirmInfo = {
					screenId: CommonConst.SCREEN_ID.ADDCOST,
					confirmInfo: true,
					isReproposal: true,
				};
				this.getConfirmInfo(confirmInfo);
				this.onSideBarLeftSubProposalShow();
				this.onChangeProposalName('再提案', CommonConst.SCREEN_ID.REPROPOSAL);
				this.$router.push('addcost');
				this.onUpdateProjectStatus(CommonConst.SCREEN_ID.ADDCOST);

				// Turn on Sharing CoCreate Mode when access to AddCost
				this.$store.commit('setIsShareCoCreate', true);
				this.$store.commit('setIsAppProcessing', false);
			}
		});
		socketClient.listen('new_creative_created', (data) => {
			this.startCreativeCountDown();
		});
		socketClient.listen('open_co_create_screen', () => {
			if (this.rightMenuScreen !== 'co-create-screen') {
				this.onToggleRightMenuScreen(SideBarRightConst.CO_CREATE_SCREEN_NAME);
				this.$store.commit('setIsCoCreateOpen', true);
			}
		});

		socketClient.listen('close_co_create_screen', () => {
			this.onCloseCoCreateScreen();
			``;
			this.$bvModal.show(this.coCreatePlainModalId);
		});

		socketClient.listen('request_comode', async (data) => {
			if (data.mode == 'check') {
				socketClient.send('data_transfer', {
					room: this.projectId,
					data: {
						event_name: 'is_cocreating',
						isShowLiveScreen: this.isShowLiveScreen,
						isShowShareScreen: this.isShowShareScreen,
					},
				});
			} else if (data.mode != 'close') {
				this.notifyZoom(data.mode);
			} else {
				// this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].iconName = this
				// 	.isShowLiveScreen
				// 	? 'LiveActive.svg'
				// 	: 'Live.svg';
				// this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].iconName = this
				// 	.isShowShareScreen
				// 	? 'ShareScreenActive.svg'
				// 	: 'ShareScreen.svg';
				// this.sideBarRightItems[CommonConst.LIVE_SCREEN_INDEX].disabled = this
				// 	.isShowShareScreen
				// 	? true
				// 	: false;
				// this.sideBarRightItems[CommonConst.SHARE_SCREEN_INDEX].disabled = this
				// 	.isShowLiveScreen
				// 	? true
				// 	: false;
				this.$bvModal.hide(this.comodeRequestModalId);
			}
		});

		let interval = setInterval(async () => {
			if (this.projectInfo) {
				socketClient.send('request_comode', {
					room: this.projectInfo.id,
					data: {
						mode: 'check',
					},
				});
				this.startCreativeCountDown();
				clearInterval(interval);
			}
		}, 1000);
	},
	beforeDestroy: function () {
		window.removeEventListener('resize', this.handleView());
	},
};
