import WhiteBoardMain from '@/components/WhiteBoard/WhiteBoardMain/WhiteBoardMain.vue';
export default {
	name: 'LiveDesign',
	components: {
		WhiteBoardMain,
	},
	methods: {
		onCloseLiveDesign() {
			this.$emit('on-close-live-design');
		},
	},
};
