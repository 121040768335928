import socketClient from '@/services/SOCKET';
import CommonConst from '@/constants/CommonConst';
import emitSoundMixin from '@/mixin/emitSound';

const SAVED_NUMBER = 1;
// const CONFIRMED_NUMBER = 2;
// const FINISH_NUMBER = 3;

export default {
	mixins: [emitSoundMixin],
	watch: {
		'screenDatas.hearing.status'(hearingStatus) {
			this.setHearingRedDotIndex(hearingStatus);
		},
		'screenDatas.schedule.status'(scheduleStatus) {
			this.setScheduleRedDotIndex(scheduleStatus);
		},
		'screenDatas.estimateParticular.status'(estimateStatus) {
			this.setEstimateRedDotIndex(estimateStatus);
		},
		'screenDatas.decision.status'(decisionStatus) {
			this.setDecisionRedDotIndex(decisionStatus);
		},
		'screenDatas.addCostDetails': {
			handler(addCostDetails) {
				const addCostStatus = addCostDetails[addCostDetails.length - 1]?.status;
				this.setAddCostRedDotIndex(addCostStatus);
			},
			deep: true,
		},
		'screenDatas.deliveries': {
			handler(deliveries) {
				const deliveryStatus = deliveries[0]?.is_save;
				this.setDeliveryRedDotIndex(deliveryStatus);
			},
			deep: true,
		},
		'screenDatas.settlement.status'(settlementStatus) {
			this.setSettlementRedDotIndex(settlementStatus);
		},
	},
	methods: {
		/**
		 * Get Listen socket event
		 * */
		connectSocket() {
			socketClient.send('join', {
				userId: this.userId,
				projectId: this.projectId,
			});

			// Join room with AdminApp
			socketClient.send('join', {
				userId: this.projectInfo['responsible_user'],
				room: 'user' + this.userId,
			});

			this.listenUpdatingScreenStatus();

			socketClient.listen('new_question_list_updated', async (data) => {
				console.log('new_question_list_updated', data);
				let getUnReplyQuestion = await this.getUnReplyQuestion();
				console.log('getUnReplyQuestion', getUnReplyQuestion);
				if (getUnReplyQuestion.length > 0) {
					this.showQuestionNotification = true;
				} else {
					this.showQuestionNotification = false;
				}
			});

			socketClient.listen('new_creative_question_list_updated', async (data) => {
				console.log('new_creative_question_list_updated', data);
				await this._getCreativeData(this.projectId);
			});
		},

		listenUpdatingScreenStatus() {
			socketClient.listen('new_data_transfer', async (data) => {
				switch (data?.event_name) {
					case 'hearing_confirm_required':
						this.setHearingRedDotIndex(CommonConst.SCREEN_STATUS.WAITING_CONFIRM);
						break;
					case 'schedule_confirm_required':
						this.setScheduleRedDotIndex(CommonConst.SCREEN_STATUS.WAITING_CONFIRM);
						break;
					case 'estimate_confirm_required':
						this.setEstimateRedDotIndex(CommonConst.SCREEN_STATUS.WAITING_CONFIRM);
						break;
					case 'decision_send_confirm':
						this.setDecisionRedDotIndex(CommonConst.SCREEN_STATUS.WAITING_CONFIRM);
						break;
					case 'delivery_content':
						this.setDeliveryRedDotIndex(SAVED_NUMBER);

						break;
					case 'emit_processing_sound':
						console.log('socket client received sound event');
						this.emitMixinProcessingSound({isSocketIncluded:  false});
						break;
					case 'toggle_client_sound_enabled':
						console.log('toggle_client_sound_enabled');
						this.$store.commit('setIsSoundEnabled', !!data?.content);
						break;
					default:
						break;
				}
			});

			socketClient.listen('received_new_addcost_quotation', async () => {
				console.log('mixin received');
				this.setAddCostRedDotIndex(CommonConst.SCREEN_STATUS.WAITING_CONFIRM);
			});

			//When creator click in Save Button
			//Enable Settlement button
			socketClient.listen('new_settlement_quotation_content', async () => {
				this.setSettlementRedDotIndex(CommonConst.SCREEN_STATUS.WAITING_PAYMENT);
				let projectInfo = await this._getProject(this.projectId);
				this.$store.commit('setProjectInfo', projectInfo);
			});
		},

		/**
		 * Update notification red-dot base on screen status
		 * @param {String} hearingStatus  Screen Status
		 */
		setHearingRedDotIndex(hearingStatus) {
			if (hearingStatus === CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
				this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.HEARING]);
			} else {
				const redDotIndexs = this.removeScreenId(
					this.redDotIndexs,
					CommonConst.SCREEN_ID.HEARING
				);
				this.$store.commit('setRedDotIndexs', redDotIndexs);
			}
		},

		/**
		 * Update notification red-dot base on screen status
		 * @param {String} scheduleStatus  Screen Status
		 */
		setScheduleRedDotIndex(scheduleStatus) {
			if (scheduleStatus === CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
				this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.SCHEDULE]);
			} else {
				const redDotIndexs = this.removeScreenId(
					this.redDotIndexs,
					CommonConst.SCREEN_ID.SCHEDULE
				);
				this.$store.commit('setRedDotIndexs', redDotIndexs);
			}
		},

		/**
		 * Update notification red-dot base on screen status
		 * @param {String} scheduleStatus  Screen Status
		 */
		setEstimateRedDotIndex(scheduleStatus) {
			if (scheduleStatus === CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
				this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.ESTIMATE]);
			} else {
				const redDotIndexs = this.removeScreenId(
					this.redDotIndexs,
					CommonConst.SCREEN_ID.ESTIMATE
				);
				this.$store.commit('setRedDotIndexs', redDotIndexs);
			}
		},

		/**
		 * Update notification red-dot base on screen status
		 * @param {String} decisionStatus  Screen Status
		 */
		setDecisionRedDotIndex(decisionStatus) {
			if (decisionStatus === CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
				this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.DECISION]);
			} else {
				const redDotIndexs = this.removeScreenId(
					this.redDotIndexs,
					CommonConst.SCREEN_ID.DECISION
				);
				this.$store.commit('setRedDotIndexs', redDotIndexs);
			}
		},

		setAddCostRedDotIndex(addCostStatus) {
			// Update notification red-dot base on screen status
			if (addCostStatus === CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
				this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.ADDCOST]);
			} else {
				const redDotIndexs = this.removeScreenId(
					this.redDotIndexs,
					CommonConst.SCREEN_ID.ADDCOST
				);
				this.$store.commit('setRedDotIndexs', redDotIndexs);
			}
		},

		setDeliveryRedDotIndex(deliveryStatus) {
			if (deliveryStatus === SAVED_NUMBER) {
				this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.DELIVERY]);
			} else {
				const redDotIndexs = this.removeScreenId(
					this.redDotIndexs,
					CommonConst.SCREEN_ID.DELIVERY
				);
				this.$store.commit('setRedDotIndexs', redDotIndexs);
			}
		},

		setSettlementRedDotIndex(settlementStatus) {
			if (settlementStatus === CommonConst.SCREEN_STATUS.WAITING_PAYMENT) {
				this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.SETTLEMENT]);
			} else {
				const redDotIndexs = this.removeScreenId(
					this.redDotIndexs,
					CommonConst.SCREEN_ID.SETTLEMENT
				);
				this.$store.commit('setRedDotIndexs', redDotIndexs);
			}
		},

		/**
		 *
		 * @param {Array} redDotIndexs
		 * @param {Integer} removingScreenId
		 * @return {Array} Array after remove screenId
		 */
		removeScreenId(redDotIndexs, removingScreenId) {
			return redDotIndexs.filter((screenId) => screenId !== removingScreenId);
		},
	},
};
