const MENU_BAR_ITEMS = [
	{
		name: 'ExpandShare',
		isActive: false,
		id: 1,
	},
	{
		name: 'Mute-active',
		isActive: false,
		id: 2,
	},
	{
		name: 'ShowVideo-active',
		isActive: false,
		id: 3,
	},
	{
		name: 'Leave',
		isActive: false,
		id: 4,
	},
];

const FULL_SIZE_MENU_BAR_ITEMS = [
	{
		name: 'WhiteDownExpand',
		isActive: false,
		id: 1,
	},
	{
		name: 'WhiteMute',
		isActive: false,
		id: 2,
	},
	{
		name: 'ShowVideo-active',
		isActive: false,
		id: 3,
	},
	{
		name: 'WhiteLeave',
		isActive: false,
		id: 4,
	},
];

export default {
	MENU_BAR_ITEMS,
	FULL_SIZE_MENU_BAR_ITEMS,
};
