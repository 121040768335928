<template>
	<div>
		<b-modal
			id="edit-image-name-modal"
			hide-footer
			hide-header
			centered
			body-class="edit-image-name-modal-body"
			content-class="edit-image-name-modal-content"
			dialog-class="edit-image-name-modal-dialog"
		>
			<div class="edit-image-name-modal-title">
				<label>名称を変更</label>
			</div>
			<InputCustom maxLength="75" class="edit-name-textarea" v-model="imageName" containerInputClass="inputWrapper" inputTitleClass="inputWrapper__MaxLength"/>
			<div class="functional-button-container">
				<button class="no-button" @click="onSelectNoClick">いいえ</button>
				<button class="yes-button" @click="onSelectYesClick">はい</button>
			</div>
		</b-modal>
	</div>
</template>

<script>
import InputCustom from '@/components/InputCustom/InputCustom.vue';

export default {
	components: {
		InputCustom,
	},
	props: {
		editImageName: {
			type: String,
		},
	},
	data() {
		return {
			imageName: '',
		};
	},
	watch: {
		editImageName(newVal) {
			this.imageName = newVal;
		},
	},
	methods: {
		onSelectNoClick() {
			this.$bvModal.hide('edit-image-name-modal');
		},
		onSelectYesClick() {
			this.$emit('on-save-new-name', this.imageName);
			this.$bvModal.hide('edit-image-name-modal');
		},
	},
};
</script>

<style src="./EditImageNameModal.css"></style>
