import { AllowDrag } from '../../directives/HxAllowDrag/allowDrag.directive';

export default {
	name: 'RedirectModal',
	directives: {
		AllowDrag,
	},
	props: {
		isSelective: {
			type: Boolean,
		},
		selectiveModalId: {
			type: String,
		},
		yesButtonTitle: {
			type: String,
			default: 'はい',
		},
		noButtonTitle: {
			type: String,
			default: 'いいえ',
		},
		/**
		 * @binding {String} color theme of button
		 * @value : primary
		 * @value : secondary
		 */
		btnColor: {
			type: String,
			default: 'primary',
		},
		/**
		 * @binding {String} size of button
		 * @value : medium
		 * @value : large
		 */
		btnSize: {
			type: String,
			default: 'medium',
		},
	},
	data() {
		return {
			isConfirmRequire: false,
			modalContent: 'このページから移動しますか？',
			modalSubContent: '※入力した内容が保存されません。',
			inputContent: '',
		};
	},
	methods: {
		onSelectYesClick() {
			this.isConfirmRequire = true;
			this.$emit('on-confirm-require', this.isConfirmRequire);
			this.$bvModal.hide(`${this.selectiveModalId}`);
			if (this.isSelective) {
				this.$bvModal.show(`${this.plainModalId}`);
			}
		},

		onSelectNoClick() {
			this.isConfirmRequire = false;
			this.$emit('on-declined-require', this.isConfirmRequire);
			this.$bvModal.hide(`${this.selectiveModalId}`);
		},
	},
};
