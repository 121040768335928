<template>
	<div class="coCreatePdf" id="pdf-cocreate">
		<div class="coCreatePdf__wrapper">
			<div class="coCreatePdf__container">
				<div class="coCreatePdf__header">
					<div class="coCreatePdf__title">
						<p class="coCreatePdf__mainTitle">{{ file_name }}</p>
						<p class="coCreatePdf__saveTime">{{ day }}</p>
					</div>
					<hr class="coCreatePdf__hr" />
					<div class="coCreatePdf__screenInfo">
						<div class="coCreatePdf__screenIcon">
							<img style="height: 15px; width: 15px" src="./screen_icon.png" />
						</div>
						<div class="coCreatePdf__screenName">参考画像</div>
					</div>
				</div>
				<div class="coCreatePdf__body">
					<div class="coCreatePdf__contentBlock">
						<div class="coCreatePdf__contentContainer">
							<div class="coCreatePdf__content-item">
								<div class="coCreatePdf__content-itemTitle">制作物</div>
								<div class="coCreatePdf__content-itemTitle">
									{{ content.productKeyword }}
								</div>
							</div>
							<div class="coCreatePdf__content-item">
								<div class="coCreatePdf__content-itemTitle">業種</div>
								<div class="coCreatePdf__content-itemTitle">
									{{ content.jobKeyword }}
								</div>
							</div>
						</div>
					</div>

					<div class="coCreatePdf__contentBlock">
						<div class="coCreatePdf__content-item">
							<div class="coCreatePdf__content-itemTitle">キーワード</div>
						</div>
						<div class="coCreatePdf__keyWordList">
							<div
								v-for="item in content.keyWordList"
								:key="item"
								class="coCreatePdf__keyWord"
							>
								{{ item }}
							</div>
						</div>
					</div>

					<div class="coCreatePdf__contentBlock">
						<div class="coCreatePdf__content-item">
							<div class="coCreatePdf__content-itemTitle">内容</div>
						</div>
						<div id="hearing-content" class="coCreatePdf__hearingContent">
							{{ content.coCreationContent }}
						</div>
					</div>
					<div class="coCreatePdf__contentBlock">
						<div class="coCreatePdf__imageList-icon">〇</div>
						<div class="coCreatePdf__imageList">
							<div
								v-for="(item, index) in content.roundImages"
								:key="index"
								class="coCreatePdf__imageList-item"
							>
								<div class="coCreatePdf__imageList-frame">
									<img
										:src="item['url']"
										:class="{
											__empty: !item['url'],
										}"
									/>
								</div>
								<div class="coCreatePdf__imageList-comment">
									{{ item.comment }}
								</div>
							</div>
						</div>
					</div>
					<div class="coCreatePdf__contentBlock">
						<div class="coCreatePdf__imageList-icon">✕</div>
						<div class="coCreatePdf__imageList">
							<div
								v-for="(item, index) in content.crossImages"
								:key="index"
								class="coCreatePdf__imageList-item"
							>
								<div class="coCreatePdf__imageList-frame">
									<img
										:src="item['url']"
										:class="{
											__empty: !item['url'],
										}"
									/>
								</div>
								<div class="coCreatePdf__imageList-comment">
									{{ item.comment }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="coCreatePdf__appLogo">
					<img
						style="height: 8px; width: 45px; display: none"
						id="comode-logo"
						src="./ComodeIcon.png"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as dayjs from 'dayjs';
import html2pdf from 'html2pdf.js';

const OPT = {
	filename: 'メモ.pdf',
	pagebreak: { mode: 'avoid-all' },
	image: { type: 'jpeg', quality: 2 },
	html2canvas: { scale: 2 },
	jsPDF: {
		unit: 'in',
		format: 'a4',
		orientation: 'portrait',
	},
};
export default {
	name: 'CoCreatePdfExporter',
	props: {
		content: {
			type: Object,
			default: () => ({}),
		},
		file_name: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			day: '',
		};
	},

	methods: {
		checkEmpty(object) {
			if (Object.keys(object).length === 0) {
				return true;
			}
			return false;
		},

		async getRenderedPDFTemplate() {
			this.day = dayjs().format('YYYY/MM/DD HH:mm');
			let coCreateContent = document.getElementById('hearing-content');
			coCreateContent.innerHTML =
				this.content.coCreationContent != ('' && undefined)
					? this.content.coCreationContent.replace('\n', '<br>')
					: '';
			let pdf = document.getElementById('pdf-cocreate');
			let comodeLogo = document.getElementById('comode-logo');
			let pdfURI = await html2pdf()
				.from(pdf)
				.set(OPT)
				.toPdf()
				.get('pdf')
				.then((pdf) => {
					let total_page = pdf.internal.getNumberOfPages();
					try {
						for (let i = 0; i < total_page; i++) {
							pdf.setPage(i);
							pdf.addImage(comodeLogo.src, 'PNG', 7, 11.3, 0.6, 0.1);
						}
					} catch (exception) {
						console.log(exception);
					}
				})
				.outputPdf('datauristring');
			return pdfURI;
		},
	},
};
</script>

<style lang="scss" scoped src="./CoCreatePdfExporter.scss"></style>
