<template>
	<b-modal
		id="share-screen-alert-modal"
		hide-footer
		hide-header
		body-class="shareScreenAlert__body"
		content-class="shareScreenAlert__content"
		dialog-class="shareScreenAlert__dialog"
	>
		<div class="shareScreenAlert__bodyBlock">
			<div class="shareScreenAlert__alertIcon">
				<img />
			</div>
			<div class="shareScreenAlert__textBlock">
				<span>
					{{ alertContents }}
				</span>
			</div>
			<div class="shareScreenAlert__buttonBlock">
				<button class="shareScreenAlert__buttonBlock--stop" @click="onStopShareScreen">
					画面共有を停止
				</button>
				<button class="shareScreenAlert__buttonBlock--ignore" @click="onIgnoreAlert">
					無視
				</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
export default {
	data() {
		return {
			alertContents:
				'「画面全体」または「ブラウザウィンドウ」は共有しないでください。\n共有すると、その画面またはウィンドウが合わせ鏡のように無限に表示されてしまいます。\n「1つのタブ」のみ共有するか「別のウィンドウ」を共有してください。',
		};
	},
	methods: {
		onStopShareScreen() {
			this.$emit('on-stop');
		},
		onIgnoreAlert() {
			this.$emit('on-ignore');
			this.$bvModal.hide('share-screen-alert-modal');
		},
	},
};
</script>

<style lang="scss" src="./ShareScreenAlert.scss"></style>
