import Message from '../../../components/Message/Message.vue';
import MessageInput from '../../../components/MessageInput/MessageInput.vue';
import socketClient from '../../../services/SOCKET';
import MessageService from '../../../services/API/message_service';
import { mapState } from 'vuex';
import TransferService from '../../../services/API/transfer.service';
import { isImage, getIcon } from '../../../store/modules/Image.js';

const EXPIRATION = 31536000;
const LIMIT_MESSAGES = 50;
const FIST_ITEM_INDEX = 0;
const DELAY_SCROLL_TIME = 200;
const EDIT_MODE = 'edit';
export default {
	name: 'ChatScreen',
	components: {
		Message,
		MessageInput,
	},
	props: {
		onChat: Boolean,
		mobileView: Boolean,
	},
	data: () => {
		return {
			screenName: 'Co-mode Talk',
			messageKey: 0,
			messages: [],
			limitMessages: LIMIT_MESSAGES,
			lastMessageIndex: 0,
			isTargetScrollMessage: false,
			isLoadOldMessage: false,
			quoteMessage: {},
			editMessage: {},
			isEdit: false,
			mode: 'normal',
			isEditMessageDone: true,
			mailContent: '',
			isDragging: false,
		};
	},
	watch: {
		messages: function () {
			this.forceRerenderMessage();
		},
		onChat: function (value) {
			if (value) {
				this.onSeenMessage();
				this.updateScroll();
			}
		},
	},
	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'managementMasterInfo',
			'schedule',
			'preview',
		]),
		destinations() {
			return [this.clientInfo['email_address'], this.creatorInfo['email_address']];
		},
		mailTemplate() {
			return {
				destinations: this.destinations,
				sender: this.managementMasterInfo['sender_email_address'],
				subject:
					'【COMODE】' +
					this.projectInfo['project_name'] +
					'案件_' +
					this.screenName +
					'のお知らせ',
				template: 'processmail',
				template_params: {
					projectName: this.projectInfo['project_name'],
					screenName: this.screenName,
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					emailContent: this.mailContent,
					managerCompanyName: this.managementMasterInfo['company_name'],
					managerMail: this.managementMasterInfo['footer_email_address'],
				},
			};
		},
	},
	methods: {
		onCloseChatScreen() {
			this.$emit('on-close-chat-screen');
		},

		onSendMessage: function (message) {
			if (this.preview) {
				return;
			}
			// Quote message
			if (message.quote) {
				this.messages.push(message);
				this.isLoadOldMessage = false;
				socketClient.send('new_message', {
					message: message.message,
					attachments: message.attachments,
					user_id: message.user_id,
					project_id: message.project_id,
					reactions: message.reactions,
					quote: message.quote,
				});

				this.sortMessages(this.messages);
				return;
			}

			if (message.attachments.length > 0) {
				//2 message
				let idx = this.messages.at(-1) ? this.messages.at(-1).id + 1 : 0;
				//shallow copy
				let messageContent = JSON.parse(
					JSON.stringify({ ...message, attachments: [], id: idx })
				);

				this.isLoadOldMessage = false;

				if (messageContent.message.length > 0) {
					this.messages.push(messageContent);
					socketClient.send('new_message', {
						message: messageContent.message,
						attachments: messageContent.attachments,
						user_id: messageContent.user_id,
						project_id: messageContent.project_id,
						reactions: messageContent.reactions,
						quote: messageContent.quote,
					});
				}

				let attachmentGroupByTypes = this.classifyAttachmentFileType(
					message.attachments
				);

				attachmentGroupByTypes.map((attachmentGroupByType, index) => {
					let uploadContents = attachmentGroupByType.map((attachment) => {
						let { data, ...uploadContent } = attachment;
						console.log(data);
						return uploadContent;
					});
					let messageAttachments = JSON.parse(
						JSON.stringify({
							...message,
							message: '',
							attachments: uploadContents,
							id: idx + index + 1,
						})
					);
					this.messages.push({
						...messageAttachments,
						attachments: attachmentGroupByType,
					});

					socketClient.send('new_message', {
						message: messageAttachments.message,
						attachments: messageAttachments.attachments,
						user_id: messageAttachments.user_id,
						project_id: messageAttachments.project_id,
						reactions: messageAttachments.reactions,
						quote: messageAttachments.quote,
					});
				});

				this.sortMessages(this.messages);
			} else {
				let idx = this.messages.at(-1) ? this.messages.at(-1).id + 1 : 0;
				this.messages.push({ ...message, id: idx });
				this.isLoadOldMessage = false;
				this.sortMessages(this.messages);
				socketClient.send('new_message', {
					message: message.message,
					attachments: message.attachments,
					user_id: message.user_id,
					project_id: message.project_id,
					reactions: message.reactions,
					quote: message.quote,
				});
			}
			this.mailTemplate.template_params.emailContent = 'メッセージが届きました';
		},

		/**
		 * Classify Attachment file type to image and other types
		 * @param {Array} attachmentMessages
		 * @returns Array of classified Attachment File Type
		 */
		classifyAttachmentFileType(rawAttachmentMessages) {
			let attachmentGroupByTypes = [];
			let attachmentMessages = [];
			rawAttachmentMessages.map((attachmentMessage, idx, _attachments) => {
				attachmentMessages.push(attachmentMessage);

				if (
					!isImage(attachmentMessage['file_type']) ||
					(_attachments[idx + 1] && !isImage(_attachments[idx + 1]['file_type']))
				) {
					attachmentGroupByTypes.push(attachmentMessages);
					attachmentMessages = [];
				}
			});
			if (attachmentMessages.length > 0)
				attachmentGroupByTypes.push(attachmentMessages);
			return attachmentGroupByTypes;
		},
		/**
		 * Scroll to newest message
		 */
		updateScroll: () => {
			let chatBoxElement = document.getElementById('chatBox');
			chatBoxElement.scrollTo(0, chatBoxElement.scrollHeight);
		},
		sortByID: function (array) {
			if (array.length === 0) return;

			return array.sort((a, b) => {
				return a.id - b.id;
			});
		},
		onEdit: function (message) {
			this.editMessage = message;
			this.mode = EDIT_MODE;
			this.isEditMessageDone = false;
			this.isEdit = true;
		},
		onEditMessageDone: function (newMessage, mode) {
			newMessage.status = 'edited';
			newMessage.date_modified = new Date();

			this.mode = mode;
			this.editMessage = newMessage;
			this.isEditMessageDone = true;

			setTimeout(() => {
				this.isEdit = false;
			}, DELAY_SCROLL_TIME);

			let oldMessage = this.findMessageById(newMessage.id);
			oldMessage = Object.assign(oldMessage, newMessage);

			// Check message edited has quote in other
			let quoteMessageIndex = this.findMessageByQuoteId(newMessage.id, this.messages);
			if (quoteMessageIndex >= 0)
				this.messages[quoteMessageIndex].quote.message = newMessage.message;

			let msg = {
				id: oldMessage?.id,
				message: oldMessage?.message,
				attachments: oldMessage?.attachments,
				user_id: oldMessage?.user_id,
				project_id: oldMessage?.project_id,
				reactions: oldMessage?.reactions,
				date_modified: oldMessage?.date_modified,
				quote_message: this.messages[quoteMessageIndex]?.quote,
				quote_message_id: this.messages[quoteMessageIndex]?.id,
			};

			socketClient.send('edit_message', msg);
		},

		onEditMessageCancel(mode) {
			this.mode = mode;
			this.isEditMessageDone = true;
		},
		onDelete: function (message) {
			this.$set(this.messages[message.baseIdx], 'delete_flag', 1);

			socketClient.send('delete_message', {
				id: message.id,
				baseIdx: message.baseIdx,
				message: message.message,
				user_id: message.user_id,
				project_id: message.project_id,
			});
		},
		findMessageById: function (id) {
			return this.messages.find((element) => element.id == id);
		},
		findMessageByQuoteId: function (quoteId, messages) {
			return messages.findIndex((element) => element.quote?.id === quoteId);
		},
		updateMessages: function (newMessage) {
			let currentMessage = this.findMessageById(newMessage.id);
			//Note Object.assign() triggers setters, whereas spread syntax doesn't.
			Object.assign(currentMessage, newMessage);

			// Check message edited has quote in other
			if (newMessage.quote_message_id) {
				let quoteMessageIndex = this.messages.findIndex(
					(message) => message.id === newMessage.quote_message_id
				);
				this.messages[quoteMessageIndex].quote.message = newMessage.message;
			}
		},
		forceRerenderMessage() {
			this.updateMessagesAttachment();
			// setTimeout(() => {
			// 	this.messageKey += 1;
			// }, time || DELAY_RENDER_TIME);
		},
		getImage: async function (key) {
			let linkPreview = await TransferService.get(key, EXPIRATION)
				.then((result) => result.data.link)
				.catch(console.log);
			return linkPreview;
		},
		updateMessagesAttachment: function () {
			if (!this.messages || this.messages instanceof Array == false) {
				return;
			}
			this.messages.map((message) => {
				if (message.attachments.length > 0) {
					message.attachments.forEach(async (attachment) => {
						if (!attachment.url) {
							let attachmentUrl = await this.getImage(attachment.key);
							this.$set(attachment, 'url', attachmentUrl);
							if (!isImage(attachment.file_type)) {
								attachment.icon = getIcon(
									attachment.file_type,
									attachment.file_name,
									true
								);
							}
						}
					});
				}
			});
		},
		onReactionsPick(data) {
			let reaction = {
				userId: this.userId,
				emoji: data.content,
				userName:
					this.userId === this.creatorInfo['id']
						? this.creatorInfo['register_name']
						: this.clientInfo['client_name'],
			};

			let reactions = [...this.messages[data.idx].reactions];

			// 1 person only pick reaction 1 time
			let matchIdx = reactions.findIndex((reaction) => reaction.userId === this.userId);

			if (matchIdx !== -1 && reactions[matchIdx].emoji === data.content) {
				reactions.splice(matchIdx, 1);
			} else if (matchIdx !== -1 && reactions[matchIdx].emoji !== data.content) {
				reactions[matchIdx].emoji = data.content;
			} else reactions.push(reaction);

			this.messages[data.idx].reactions = reactions;

			socketClient.send('edit_message', {
				id: this.messages[data.idx].id,
				reactions: reactions,
				project_id: this.messages[data.idx].project_id,
			});
		},

		sortMessages: function (listMessages) {
			if (listMessages.length === 0) return;

			if (!listMessages || listMessages instanceof Array == false) {
				return;
			}
			this.messages = this.sortByID(
				listMessages.map((message) => {
					return {
						...message,
						active: false,
						isEdit: false,
						optionOpen: false,
						showAvatar: false,
						reactionOpen: false,
						showMessageDate: false,
						isTargetScrollMessage: false,
					};
				})
			);

			this.messages.map((message, index, _messages) => {
				if (_messages[index + 1]) {
					if (message.user_id != _messages[index + 1].user_id) {
						message.showAvatar = true;
					} else {
						message.showAvatar = false;
					}
				} else {
					message.showAvatar = true;
				}
			});
		},

		onQuoteButton(message) {
			this.quoteMessage = message;
			this.$refs.messageInput.onShowQuote();
		},

		hightlight(e) {
			e.preventDefault();
			e.stopPropagation();
			this.enterTarget = e.target;
			this.isDragging = true;
		},
		unhightlight(e) {
			e.preventDefault();
			e.stopPropagation();
			let rect = document.getElementById('dropzone').getBoundingClientRect();
			if (
				e.clientY < rect.top ||
				e.clientY >= rect.bottom ||
				e.clientX < rect.left ||
				e.clientX >= rect.right
			) {
				this.isDragging = false;
			}
		},
		onDrop(e) {
			e.preventDefault();
			e.stopPropagation();
			this.isDragging = false;
			this.$refs.messageInput.dragFiles(e);
		},
		/**
		 * Prevent Scroll to top when load old messages
		 */
		_preventTopScroll() {
			const container = document.getElementsByClassName('chatBox')[0];
			const prevScrollHeight = container.scrollHeight;

			const observer = new ResizeObserver(() => {
				if (container.scrollHeight !== prevScrollHeight) {
					if (document.getElementsByClassName('chatBox')[0]) {
						document.getElementsByClassName('chatBox')[0].scrollTo({
							top: container.scrollHeight - prevScrollHeight,
						});
						observer.disconnect();
					}
				}
			});

			for (const element of container.children) {
				observer.observe(element);
			}
		},

		/**
		 * Load old message when scroll to top
		 */
		_scrollToTop() {
			if (!this.$refs.chatBox) return;

			this.$refs.chatBox.addEventListener('scroll', () => {
				if (this.$refs.chatBox && this.$refs.chatBox.scrollTop === 0) {
					// Prevent auto scroll to botton
					this.isLoadOldMessage = true;

					setTimeout(async () => {
						if (this.lastMessageIndex === 0) return;

						let listMessages = await this._getMessages(
							this.projectId,
							this.limitMessages,
							this.lastMessageIndex
						);

						if (listMessages.length < 1) return;

						listMessages = listMessages.map((message) => {
							return {
								...message,
								active: false,
								isEdit: false,
								optionOpen: false,
								reactionOpen: false,
								showMessageDate: false,
								isTargetScrollMessage: false,
							};
						});

						this.$set(this.messages[FIST_ITEM_INDEX], 'showMessageDate', false);

						listMessages.map((message) => {
							this.messages.unshift(message);
						});

						this.lastMessageIndex = this.messages[0].id;
						this.sortMessages(this.messages);
						this._preventTopScroll();
						this.onSeenMessage();
					}, 500);
				}
			});
		},

		showMessageDate() {
			this.$refs.chatBox.addEventListener('scroll', () => {
				let messageContainers = document.getElementsByClassName('messageContainer');
				let chatBox = document.getElementById('chatBox');
				let chaBoxTop = chatBox.getBoundingClientRect().top;

				for (const child of messageContainers) {
					console.log(child.getBoundingClientRect().top - chaBoxTop);
				}
			});
		},

		getMessages: async function () {
			this.$store.commit('setIsAppProcessing', true);

			let listMessages = await MessageService.getMessage(
				this.projectId,
				this.limitMessages,
				this.lastMessageIndex
			)
				.then((res) => {
					return res.data;
				})
				.catch(console.log);

			if (listMessages.length === 0) {
				this.$store.commit('setIsAppProcessing', false);
				return;
			}

			this.sortMessages(listMessages);
			this.lastMessageIndex = this.messages[0].id;
			this.$store.commit('setIsAppProcessing', false);
		},

		async _getMessages(projectId, limitMessages, lastMessageIndex) {
			try {
				let response = await MessageService.getMessage(
					projectId,
					limitMessages,
					lastMessageIndex
				);
				if (!response || response.status !== 200) {
					throw 'Get Messages failed';
				}
				console.log('%c Get Messages successfully', 'color: green');
				return response.data;
			} catch (error) {
				console.log(`%c Get Messages failed: ${error}`, 'color: red');
			}
		},

		newMessageSent: function (data) {
			console.log('new_message_sent in Chat');
			if (!data) return;

			this.messages.push({
				...data,
				isEdit: false,
				active: false,
				optionOpen: false,
				reactionOpen: false,
				showAvatar: false,
			});
			this.sortMessages(this.messages);

			this.isLoadOldMessage = false;
			// this.lastMessageIndex = this.messages[0].id
			if (!this.onChat) {
				return;
			}

			socketClient.send('message_seen', {
				id: data.id,
				message: data.message,
				attachments: data.attachments,
				user_id: data.user_id,
				project_id: data.project_id,
				reactions: data.reactions,
				quote: data.quote,
			});
		},

		connectSocket() {
			socketClient.listen('new_message_sent', this.newMessageSent);

			socketClient.listen('on_message_seen', (data) => {
				let newMessageIdx = this.messages.length - 1;

				this.$set(this.messages[newMessageIdx], 'id', data.id);
				this.$set(this.messages[newMessageIdx], 'status', data.status);
			});

			socketClient.listen('on_edited_message', (data) => {
				this.updateMessages(data);
			});

			socketClient.listen('on_message_deleted', (data) => {
				let deleteMessage = this._findMessageById(this.messages, data.id);

				if (!deleteMessage) return;
				this.$set(this.messages[deleteMessage.baseIdx], 'delete_flag', 1);
			});
		},

		_findMessageById(messages, messageId) {
			return messages.find((message) => message.id === messageId);
		},

		onSeenMessage: function () {
			let isHaveUnseenMessages = false;
			this.messages.map((message) => {
				// Update message status to "seen"
				if (
					message.status != 'seen' &&
					message.status != 'edited' &&
					message.user_id != this.userId
				) {
					isHaveUnseenMessages = true;
					socketClient.send('message_seen', {
						id: message.id,
						message: message.message,
						attachments: message.attachments,
						user_id: message.user_id,
						project_id: message.project_id,
						reactions: message.reactions,
						quote: message.quote,
					});
				}
			});

			setTimeout(() => {
				if (isHaveUnseenMessages) this.$emit('on-seen-message');
			}, 200);
		},
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		// console.log('Chat mouted');
		this.connectSocket();

		await this.getMessages();

		this.onSeenMessage();

		const DELAY_MOUNTED = 500;
		this.forceRerenderMessage(DELAY_MOUNTED);

		this._scrollToTop();

		this.$store.commit('setIsAppProcessing', false);
	},

	updated() {
		// Prevent auto scroll
		if (this.isLoadOldMessage || this.isEdit) return;
		this.updateScroll();
	},

	destroyed() {
		socketClient.removeListener('new_message_sent', this.newMessageSent);
	},
};
