import ApiService from './api.service';

const serviceURL = 'memo';
const MemoService = {
	/**
	 * Get memo by query string
	 * @param {Integer} param query object
	 * @returns {Object} memo object
	 */
	search(param) {
		return ApiService.query(`${serviceURL}/search`, param);
	},

	/**
	 * Create new Memo record
	 * @param {Object} body {doc_content: {Memo Object}}
	 * @returns {Object} include success message and record ID
	 */
	post(body) {
		return ApiService.post(`${serviceURL}/`, body);
	},

	/**
	 * Update Memo content from datePicker
	 * @param {Integer} id: Memo ID
	 * @param {Object} content: update content
	 * @returns
	 */
	update(id, body) {
		return ApiService.put(`${serviceURL}/${id}`, body);
	},
};

export default MemoService;
